import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BlockStack, Box, Button, Card, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { formFields, initialValues } from "@/Assets/Mocks/UserLogin.mock";
import CommonForm from "@/Components/Common/CommonForm";
import { useAuth } from "@/Context/AuthContext";
import { UserContext } from "@/Context/UserContext";
import { setLocalStorageItem } from "@/Utils/Index";

export const Login = () => {
  const { updateProfileInfo } = useContext(UserContext);
  const { login } = useAuth();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    const res = await fetch.post("/login", e);
    if (res.data) {
      updateProfileInfo(res.data);
      setLocalStorageItem("authorization", res.data.token);
      login();
    }
  };

  const navidateSignup = () => {
    navigate(`/signup`);
  };

  return (
    <Page fullWidth={true}>
      <BlockStack gap="200" inlineAlign="center">
        <Box className="public-page" width="35%">
          <Card>
            <BlockStack gap="300">
              <Text alignment="center" variant="headingXl" color="subdued" fontWeight="bold">
                Log In
              </Text>
              <Card>
                <BlockStack gap="200">
                  <CommonForm
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    formFields={formFields}
                    isSave={true}
                    label="Login"
                  />
                  <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                    Forgot Password?
                  </Link>
                </BlockStack>
              </Card>
              <Card>
                <Text alignment="center" color="subdued" fontWeight="semibold">
                  New User?{" "}
                  <Button
                    variant="primary"
                    onClick={() => {
                      navidateSignup();
                    }}
                  >
                    Sign Up
                  </Button>
                </Text>
              </Card>
            </BlockStack>
          </Card>
        </Box>
      </BlockStack>
    </Page>
  );
};
