import { BlockStack, Page, Text } from "@shopify/polaris";

export default function PrivacyPolicy() {
  return (
    <div className="Privacy_Policy">
      <Page title="Recruitments.AI Privacy Policy" divider>
        <BlockStack gap="500">
          <Text>
            Webrex: Hiring process with Recruitment.AI – Experience a game-changing hiring solution with our
            software at Recruitment.AI. Tailor interviews effortlessly to job descriptions, create custom
            questions, and leverage AI-generated queries for precise candidate assessments. Our platform empowers
            you to conduct interviews with exact relevance, providing a seamless and innovative approach to hiring
            for specific roles. Elevate your recruitment process with unparalleled efficiency and accuracy using
            Recruitment.AI.
          </Text>
          <Text variant="headingXl" as="h4">
            Personal Information the App Collects
          </Text>
          <Text>
            We collect the following types of personal information from you and/or your candidates: job details,
            candidate details and assessments details about you and others who may access the software on behalf of
            you, such as your name, address, email address, phone number, and billing information.
          </Text>
          <Text variant="headingXl" as="h4">
            Usage of Personal Information
          </Text>
          <Text>
            We use the personal information gathered from you and your candidates is utilized solely for delivering
            our service and maintaining software operations. Additionally, we may share this information to adhere
            to pertinent laws and regulations, respond to legal requests such as subpoenas or search warrants, or
            safeguard our rights. Rest assured, your data is handled with the utmost care and in strict compliance
            with legal standards.
          </Text>
          <Text variant="headingXl" as="h4">
            Data Retention
          </Text>
          <Text>
            when interviews are conducted through our software, we retain the related information for our records.
            This data will be securely maintained unless and until you request its deletion. Your privacy and
            control over your information are of utmost importance to us, and we are committed to ensuring that
            your data is handled responsibly and in accordance with your preferences.
          </Text>
          <Text variant="headingXl" as="h4">
            Changes to Privacy Policy
          </Text>
          <Text>
            We may update this privacy policy from time to time to reflect changes to our practices or for other
            operational, legal, or regulatory reasons.
          </Text>
          <Text variant="headingXl" as="h4">
            Contact Us
          </Text>
          <Text>
            For more information about our privacy practices, if you have questions, or if you would like to make a
            complaint, please contact us by e-mail at hello@webrexstudio.com or by mail using the details provided
            below:
          </Text>
          <Text>209, Saumya Square near Goverdhan party plot, Thaltej 380052, Ahmedabad, India.</Text>
          <Text></Text>
        </BlockStack>
      </Page>
    </div>
  );
}
