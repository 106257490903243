import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Banner, BlockStack, Button, InlineStack, Layout } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { assessmentTimerValues, assessmentTimerformFields } from "../../../Assets/Mocks/Settings.mock";
import { UserContext } from "../../../Context/UserContext";

export const CommonReminder = ({ status, id, isSetReminder, jobData }) => {
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const { userProfileData } = useContext(UserContext);
  const [reminderFormValues, setReminderFormValues] = useState(assessmentTimerValues);
  const { showToast } = useContext(ToastContext);
  const [rows, setRows] = useState();
  const [reminder, setReminder] = useState(assessmentTimerValues);

  useEffect(() => {
    getOrganizationData();
  }, []);

  const getOrganizationData = useCallback(async () => {
    if (status === "common") {
      let organization = await fetch.get(`/organization/${userProfileData?.currentOrganization?.orgId}`);
      if (organization?.data?.reminder) {
        setReminder(organization?.data?.reminder);
      }
    } else {
      const job = await fetch.get(`/job/${id}`);
      if (job?.data?.reminder) {
        setReminder(job?.data?.reminder);
      }
    }
  }, [rows]);

  const assessmentReminder = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleReminder = useCallback(() => {
    setReminderFormValues(reminder);
    isSetReminder(false);
    setIsReminderModalOpen(true);
  }, [reminder]);

  const handleSubmit = async (values) => {
    let trueValueObjects = Object.fromEntries(
      Object.entries(values)
        .map(([key, item]) => {
          item.Weekday = item.Weekday || "1";
          item.Weekend = item.Weekend || "1";
          return [key, item];
        })
        .filter(
          ([_, item]) =>
            item.sameDays ||
            item.nextDays ||
            item.thirdDays ||
            item.fourthDays ||
            item.fifthDays ||
            item.sixthDays ||
            item.seventhDays
        )
        .sort(([aKey, aItem], [bKey, bItem]) => {
          const priority = [
            "seventhDays",
            "sixthDays",
            "fifthDays",
            "fourthDays",
            "thirdDays",
            "nextDays",
            "sameDays",
          ];
          return priority.findIndex((p) => bItem[p]) - priority.findIndex((p) => aItem[p]);
        })
    );
    let data = {
      reminder: trueValueObjects,
      _id: status === "common" ? userProfileData?.currentOrganization?.orgId : id,
    };
    if (status === "common") {
      const res = await fetch.post("/organization", data);
    } else {
      await fetch.post("/job", data);
    }
    isSetReminder(true);
    getOrganizationData();
    setIsReminderModalOpen(false);
    setReminderFormValues({ ...assessmentTimerValues });
  };

  const handleReminderModalClose = () => {
    setIsReminderModalOpen(false);
    setReminderFormValues({ ...assessmentTimerValues });
  };

  return (
    <Layout sectioned>
      <Layout.Section>
        <BlockStack gap="300">
          <InlineStack align="end" gap="200">
            <Button disabled={!jobData?.isActive && status === "joblevel"} onClick={() => handleReminder()}>
              Set Reminder
            </Button>
          </InlineStack>
          <BannerModal
            open={isReminderModalOpen}
            onClose={handleReminderModalClose}
            title={"Set Reminder"}
            primaryAction={{
              content: "Save",
              onAction: assessmentReminder,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: handleReminderModalClose,
              },
            ]}
            children={
              <BlockStack gap={200}>
                <Banner
                  tone="info"
                  title={`Choose a specific time of day when you would like to send the reminder.`}
                ></Banner>
                <CommonForm
                  onSubmit={handleSubmit}
                  formRef={formRef}
                  initialValues={reminderFormValues}
                  formFields={assessmentTimerformFields}
                  title="Invite Teammate"
                  isSave={false}
                />
              </BlockStack>
            }
          />
        </BlockStack>
      </Layout.Section>
    </Layout>
  );
};
