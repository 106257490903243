import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineGrid,
  InlineStack,
  Layout,
  Modal,
  SkeletonBodyText,
  Text,
} from "@shopify/polaris";
import { EmailMajor, IdentityCardMajor, MobileMajor, PasskeyMajor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { profileField } from "../../../Assets/Mocks/Settings.mock";
import { UserContext } from "../../../Context/UserContext";

export const Profile = () => {
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const formRef = useRef();
  const [currentOrganization, setCurrentOrganization] = useState({});
  const fetch = useAuthenticatedFetch();
  const [formValues, setFormValues] = useState();
  const [formModalStatus, setformModalStatus] = useState(false);
  const { showToast } = useContext(ToastContext);

  const handleClose = useCallback(() => setformModalStatus(!formModalStatus), [formModalStatus]);

  useEffect(() => {
    if (userProfileData && userProfileData.organizations && userProfileData.organizations.length === 0) {
      navigate(`/organization`);
    }
  }, [userProfileData]);

  const iconMap = {
    firstName: IdentityCardMajor,
    lastName: IdentityCardMajor,
    mobile: MobileMajor,
    email: EmailMajor,
    role: PasskeyMajor,
  };

  const getIcon = (iconName) => {
    const IconComponent = iconMap[iconName];
    if (IconComponent) {
      return <Icon source={IconComponent} color="white" />;
    }
    return null;
  };

  const submitForm = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    if (values.setPassword) {
      values.password = values.setPassword;
    }
    let res = await fetch.put("user", values);
    res.data["currentOrganization"] = currentOrganization;
    updateProfileInfo(res.data);
    setformModalStatus(false);
    showToast("Updated successfully");
    infoCard();
  }, []);

  const handleFormModal = useCallback(async () => {
    setCurrentOrganization(userProfileData.currentOrganization);
    setFormValues(userProfileData);
    setformModalStatus(!formModalStatus);
  }, [formModalStatus]);

  useEffect(() => {
    infoCard();
  }, [userProfileData]);

  const infoCard = useCallback(() => {
    const keyToLabel = {
      firstName: "First Name",
      lastName: "Last Name",
      mobile: "Mobile",
      email: "Email",
      role: "Role",
    };
    if (!userProfileData) {
      return <SkeletonBodyText />;
    }
    if (Object.keys(userProfileData.currentOrganization).length === 0) {
      userProfileData.currentOrganization = currentOrganization;
    }
    if (
      userProfileData &&
      userProfileData.organizations &&
      userProfileData?.currentOrganization &&
      userProfileData.organizations.length > 0
    ) {
      const filteredUserProfileData = Object.keys(keyToLabel).reduce((filteredData, key) => {
        filteredData[key] = key === "role" ? userProfileData.currentOrganization[key] : userProfileData[key];
        return filteredData;
      }, {});

      return (
        filteredUserProfileData &&
        Object.keys(filteredUserProfileData).map((key, index) => (
          <InlineGrid key={index}>
            <InlineStack wrap={false} align="start" blockAlign="center" gap="200">
              <Box
                minWidth="40px"
                minHeight="40px"
                background="bg-surface-info"
                paddingBlockStart="200"
                borderRadius="full"
              >
                {getIcon(key)}
              </Box>
              <BlockStack>
                <Text variant="bodyXs" fontWeight="bold">
                  {keyToLabel[key] || key}: {filteredUserProfileData[key] || "-"}
                </Text>
              </BlockStack>
            </InlineStack>
          </InlineGrid>
        ))
      );
    }
  }, [userProfileData]);

  return (
    <div>
      <Layout sectioned>
        <Layout.Section>
          <BlockStack gap="300">
            <InlineStack align="end">
              <Button onClick={handleFormModal}>Edit Profile</Button>
            </InlineStack>
            <Card>
              <InlineGrid
                gap={{ xs: "200", sm: "400", md: "500", lg: "500", xl: "500" }}
                columns={{ xs: "1", sm: "1", md: "2", lg: "2", xl: "2" }}
              >
                {infoCard()}
              </InlineGrid>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
      <Modal
        open={formModalStatus}
        title="Edit Profile Data"
        onClose={handleClose}
        primaryAction={{
          content: "Save",
          onAction: submitForm,
        }}
      >
        <Modal.Section>
          <CommonForm
            formFields={profileField}
            isSave={false}
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={formValues}
          />
        </Modal.Section>
      </Modal>
    </div>
  );
};
