import { useCallback, useEffect } from "react";
import { VoiceVisualizer, useVoiceVisualizer } from "react-voice-visualizer";
import { Button } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { uploadFileToServer } from "@/Utils/Utils";

const VoiceRecorder = (props) => {
  const fetch = useAuthenticatedFetch();
  // Initialize the recorder controls using the hook
  const recorderControls = useVoiceVisualizer();

  const {
    // ... (Extracted controls and states, if necessary)
    recordedBlob,
    error,
    audioRef,
    setPreloadedAudioBlob,
  } = recorderControls;

  // Get the recorded audio blob
  useEffect(() => {
    if (!recordedBlob || recordedBlob.size === 0) return;
    // uploadAudioToServer(recordedBlob, "audio.wav");
  }, [recordedBlob, error]);

  useEffect(() => {
    // setInitialAudioBlob();
  }, [fetch]);

  const setInitialAudioBlob = useCallback(async () => {
    const audioBlob = await fetch.get(`https://localhost:4200/uploads/1712639749350.webm`);
    if (audioBlob) {
      setPreloadedAudioBlob(audioBlob);
    }
  }, [fetch]);

  // Get the error when it occurs
  useEffect(() => {
    if (!error) return;
    console.error(error);
  }, [error]);

  // Handle the stop recording event
  // const handleStopRecording = useCallback(async () => {
  //   if (recordedBlob) {
  //     const url = URL.createObjectURL(recordedBlob);
  //     console.log("url", url);
  //     console.log("recordedBlob", recordedBlob);

  //     // await uploadAudioToServer(recordedBlob, `${Date.now()}`);
  //   }
  // }, [fetch, recordedBlob, AudioBuffer]);
  const handleStopRecording = useCallback(async () => {
    if (recordedBlob) {
      // Upload the WAV file to the server
      const data = {
        file: recordedBlob,
        fileName: `audio.webm`,
      };

      // Upload the file to the server
      await uploadFileToServer(fetch, data);
    }
  }, [fetch, recordedBlob]);

  return (
    <>
      <VoiceVisualizer ref={audioRef} controls={recorderControls} {...props} />
      <Button onClick={handleStopRecording}>handleStopRecording</Button>
    </>
  );
};

export default VoiceRecorder;
