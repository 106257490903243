import { createContext, useEffect, useState } from "react";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { logger } from "@/Services/Logger/Index";

export const UserContext = createContext();
export const UserContextProvider = ({ children }) => {
  const [userProfileData, setUserProfileData] = useState();
  const fetch = useAuthenticatedFetch();

  const getProfileInfo = async () => {
    const res = await fetch.get("/user");
    if (res?.data) {
      logger.identifyUser({
        id: res?.data._id,
        email: res?.data.email || "email",
        orgId: res?.data.currentOrganization.orgId,
      });
      logger.identifyUserScope(res?.data);
    }
    setUserProfileData(res.data);
  };

  const updateProfileInfo = async (data) => {
    setUserProfileData(data);
  };
  return (
    <UserContext.Provider value={{ userProfileData, getProfileInfo, updateProfileInfo }}>
      {children}
    </UserContext.Provider>
  );
};
