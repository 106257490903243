export const MockData = {
  profile: [
    {
      id: "1",
      icon: "StoreMajor",
      title: "Store Name",
      valueKey: "storeName",
    },
    {
      id: "2",
      icon: "LinkMinor",
      title: "Store URL",
      valueKey: "shopUrl",
    },
    {
      id: "3",
      icon: "EmailMajor",
      title: "Email ID",
      valueKey: "email",
    },
    {
      id: "4",
      icon: "PhoneMajor",
      title: "Phone Number",
      valueKey: "phone",
    },
    {
      id: "5",
      icon: "FlagMajor",
      title: "Country",
      valueKey: "country_name",
    },
    {
      id: "6",
      icon: "FinancesMajor",
      title: "Currency",
      valueKey: "currency",
    },
  ],
  subscription: [
    {
      id: "1",
      icon: "PlanMajor",
      title: "Subscription Name",
      valueKey: "recurringPlanName",
    },
  ],
};

export const profileField = [
  {
    id: "firstName",
    name: "firstName",
    label: "First Name",
    validated: true,
    type: "text",
    placeholder: "Add first name",
    errormsg: "First Name is required",
  },
  {
    id: "lastName",
    name: "lastName",
    label: "Last Name",
    validated: true,
    type: "text",
    placeholder: "Add last name",
    errormsg: "Last Name is required",
  },
  {
    id: "mobile",
    name: "mobile",
    label: "Mobile",
    type: "number",
    validated: true,
    placeholder: "Add mobile number",
    errormsg: "Mobile number is required",
  },
  {
    id: "setPassword",
    name: "setPassword",
    label: "Password",
    type: "text",
    validated: false,
    placeholder: "set new password",
  },
];

const reminderTime = [
  { label: "1 AM", value: "1" },
  { label: "2 AM", value: "2" },
  { label: "3 AM", value: "3" },
  { label: "4 AM", value: "4" },
  { label: "5 AM", value: "5" },
  { label: "6 AM", value: "6" },
  { label: "7 AM", value: "7" },
  { label: "8 AM", value: "8" },
  { label: "9 AM", value: "9" },
  { label: "10 AM", value: "10" },
  { label: "11 AM", value: "11" },
  { label: "12 AM", value: "12" },
  { label: "1 PM", value: "13" },
  { label: "2 PM", value: "14" },
  { label: "3 PM", value: "15" },
  { label: "4 PM", value: "16" },
  { label: "5 PM", value: "17" },
  { label: "6 PM", value: "18" },
  { label: "7 PM", value: "19" },
  { label: "8 PM", value: "20" },
  { label: "9 PM", value: "21" },
  { label: "10 PM", value: "22" },
  { label: "11 PM", value: "23" },
  { label: "12 PM", value: "24" },
];

export const assessmentTimerformFields = [
  {
    id: "sameDay",
    name: "sameDay",
    nested: "object",
    groupSize: 6,
    section: true,
    subfields: [
      {
        id: "sameDays",
        name: "sameDays",
        label: "Same Day",
        type: "checkbox",
      },
      {
        id: "Weekday",
        name: "Weekday",
        label: "Weekday Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "sameDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
      {
        id: "Weekend",
        name: "Weekend",
        label: "Weekend Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "sameDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
    ],
  },
  {
    id: "nextDay",
    name: "nextDay",
    nested: "object",
    groupSize: 5,
    section: true,
    subfields: [
      {
        id: "nextDays",
        name: "nextDays",
        label: "Next Day",
        type: "checkbox",
      },
      {
        id: "Weekday",
        name: "Weekday",
        label: "Weekday Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "nextDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
      {
        id: "Weekend",
        name: "Weekend",
        label: "Weekend Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "nextDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
    ],
  },
  {
    id: "thirdDay",
    name: "thirdDay",
    nested: "object",
    groupSize: 5,
    section: true,
    subfields: [
      {
        id: "thirdDays",
        name: "thirdDays",
        label: "Third Day",
        type: "checkbox",
      },
      {
        id: "Weekday",
        name: "Weekday",
        label: "Weekday Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "thirdDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
      {
        id: "Weekend",
        name: "Weekend",
        label: "Weekend Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "thirdDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
    ],
  },
  {
    id: "fourthDay",
    name: "fourthDay",
    nested: "object",
    groupSize: 5,
    section: true,
    subfields: [
      {
        id: "fourthDays",
        name: "fourthDays",
        label: "Fourth Day",
        type: "checkbox",
      },
      {
        id: "Weekday",
        name: "Weekday",
        label: "Weekday Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "fourthDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },

      {
        id: "Weekend",
        name: "Weekend",
        label: "Weekend Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "fourthDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
    ],
  },
  {
    id: "fifthDay",
    name: "fifthDay",
    nested: "object",
    groupSize: 5,
    section: true,
    subfields: [
      {
        id: "fifthDays",
        name: "fifthDays",
        label: "Fifth Day",
        type: "checkbox",
      },
      {
        id: "Weekday",
        name: "Weekday",
        label: "Weekday Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "fifthDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
      {
        id: "Weekend",
        name: "Weekend",
        label: "Weekend Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "fifthDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
    ],
  },
  {
    id: "sixthDay",
    name: "sixthDay",
    nested: "object",
    groupSize: 5,
    section: true,
    subfields: [
      {
        id: "sixthDays",
        name: "sixthDays",
        label: "Sixth Day",
        type: "checkbox",
      },
      {
        id: "Weekday",
        name: "Weekday",
        label: "Weekday Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "sixthDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
      {
        id: "Weekend",
        name: "Weekend",
        label: "Weekend Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "sixthDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
    ],
  },
  {
    id: "seventhDay",
    name: "seventhDay",
    nested: "object",
    groupSize: 5,
    section: true,
    subfields: [
      {
        id: "seventhDays",
        name: "seventhDays",
        label: "Seventh Day",
        type: "checkbox",
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
      },
      {
        id: "Weekday",
        name: "Weekday",
        label: "Weekday Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "seventhDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },

      {
        id: "Weekend",
        name: "Weekend",
        label: "Weekend Time Period",
        type: "select",
        options: reminderTime,
        dependOn: {
          name: "seventhDays",
          value: true,
          isArray: true,
          type: "hidden",
        },
      },
    ],
  },
];

export const assessmentTimerValues = {
  sameDay: {
    sameDays: false,
    Weekday: "1",
    Weekend: "1",
  },
  nextDay: {
    nextDays: false,
    Weekday: "1",
    Weekend: "1",
  },
  thirdDay: {
    thirdDays: false,
    Weekday: "1",
    Weekend: "1",
  },
  fourthDay: {
    fourthDays: false,
    Weekday: "1",
    Weekend: "1",
  },
  fifthDay: {
    fifthDays: false,
    Weekday: "1",
    Weekend: "1",
  },
  sixthDay: {
    sixthDays: false,
    Weekday: "1",
    Weekend: "1",
  },
  seventhDay: {
    seventhDays: false,
    Weekday: "1",
    Weekend: "1",
  },
};
