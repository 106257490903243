import React, { useCallback, useEffect, useState } from "react";
import { BlockStack, Button, IndexTable, InlineStack, Link, Modal, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { getTemplateFields, templateInitialValues } from "@/Assets/Mocks/Template.mock";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";

export function Template(props) {
  const { templates } = props;
  const { isChooseTemplateModal, handleModalClose, handleEditStage, handleAddStage, setShowTemplateSaveButton } =
    props;
  const [templateFields, setTemplateFields] = useState([]);
  const [templateFormValues, setTemplateFormValues] = useState(templateInitialValues);

  const templateFormChange = useCallback((value) => {
    setTemplateFormValues((prev) => ({ ...prev, ...value }));
  }, []);

  const handleTemplateModalClose = () => {
    setTemplateFormValues(templateInitialValues);
    handleModalClose();
  };

  const templateFormSubmit = useCallback(() => {
    const template = templates.find((template) => template.value === templateFormValues.template);
    if (templateFormValues.template === "none") {
      handleTemplateModalClose();
      handleAddStage();
    } else {
      handleTemplateModalClose();
      handleEditStage(template);
    }
    setShowTemplateSaveButton(true);
  }, [templates, templateFormValues]);

  useEffect(() => {
    setTemplateFields(getTemplateFields(templates));
  }, [templates]);

  return (
    <>
      <BannerModal
        open={isChooseTemplateModal}
        title="Choose a template or start from scratch."
        onClose={handleTemplateModalClose}
        primaryAction={{
          content: "Next",
          onAction: templateFormSubmit,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleTemplateModalClose,
          },
        ]}
        children={
          <BlockStack gap="300">
            <CommonForm
              initialValues={templateFormValues}
              formFields={templateFields}
              onFormChange={templateFormChange}
              isSave={false}
            />
          </BlockStack>
        }
        size={"small"}
      />
    </>
  );
}
