import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Card, EmptyState, IndexTable, ResourceList, Tabs, Text, useIndexResourceState } from "@shopify/polaris";
import { t } from "i18next";
import { debounce } from "lodash";
import { useAuthenticatedFetch } from "@/Api/Axios";
import * as Images from "@/Assets/Index";
import { getSessionStorageItem, isEmptyArray, objectToQueryParams, setSessionStorageItem } from "@/Utils/Index";
import CommonFilter from "./CommonFilter";
import CommonPagination from "./CommonPagination";

const CommonTable = forwardRef((props, ref) => {
  const {
    isAdd = true,
    isFilterVisible = false,
    isPaginationVisible = true,
    tableData,
    promotedBulkActions,
    setParentFilters,
    parentFilters = [],
    setCount,
    setInitialData,
    localStorageKey,
    handleSubmit,
    showTab = false,
    tabOption = [],
    setParentSelectedTab,
    secondaryTabAction,
    handleParentSelectionChange = false,
    patentSelectedRows = [],
    parentAllRowsSelected = false,
    CustomiseComponent,
  } = props;

  let localStorageItem;
  if (localStorageKey) localStorageItem = getSessionStorageItem(localStorageKey);
  const currentTabFilter = localStorageItem?.filter?.find?.((e) => e?.tabQuery);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allDataSelectId, setAllDataSelectId] = useState([]);
  const [advanceFilter, setAdvanceFilter] = useState(localStorageItem?.filter || parentFilters);
  const [paginationAction, setPaginationAction] = useState();
  const [itemCount, setItemCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(currentTabFilter?.tabNumber || 0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [hasInitialData, setHasInitialData] = useState(false);
  const fetch = useAuthenticatedFetch();
  const [pagination, setPagination] = useState(localStorageItem?.pagination || { page: 1, pageSize: 10 });
  const [data, setData] = useState({
    count: 0,
    rows: [],
  });

  const getInitialFilter = (pagination, advanceFilter) => {
    if (!pagination && !advanceFilter) {
      let localStorageItem;
      if (localStorageKey) localStorageItem = getSessionStorageItem(localStorageKey);
      pagination = localStorageItem?.pagination || { page: 1, pageSize: 10 };
      advanceFilter = localStorageItem?.filter || [];
    }
    setPagination(pagination);
    setAdvanceFilter(advanceFilter);
    return { pagination, advanceFilter };
  };

  useImperativeHandle(ref, () => ({
    fetchData: async (initialPagination, initialAdvanceFilter) => {
      const { pagination, advanceFilter } = getInitialFilter(initialPagination, initialAdvanceFilter);
      const queryParams = objectToQueryParams({ ...pagination });
      let response;
      if (isFilterVisible || props.fetchType === "post") {
        response = await fetch.post(`${props.url}${queryParams}`, { advanceFilter, ...props.queryParam });
      } else {
        response = await fetch.get(`${props.url}${queryParams}`);
      }
      if (response.code == 200) {
        setCount && setCount(response.data.count);
        setInitialData && setInitialData({ ...response.data });
        setData({ ...response.data });
      }
    },
  }));

  useEffect(() => {
    if (tableData) {
      setData(tableData);
    }
  }, [tableData]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(
    data.rows
  );

  useEffect(() => {
    if (handleParentSelectionChange) {
      setSelectedRows(patentSelectedRows);
      setAllRowsSelected(parentAllRowsSelected);
    } else {
      setSelectedRows(selectedResources);
      setAllRowsSelected(allResourcesSelected);
    }
  }, [patentSelectedRows, parentAllRowsSelected, selectedResources, allResourcesSelected]);

  useEffect(() => {
    props.clearSelection && clearSelection();
  }, [props.clearSelection]);

  useEffect(() => {
    if (allRowsSelected) {
      setAllDataSelectId(data.rows.map((item) => item.id));
    }
  }, [data.rows, allRowsSelected]);

  useEffect(() => {
    if (handleSubmit) handleSubmit(data.rows, selectedRows, allRowsSelected);
  }, [data.rows, selectedRows, allRowsSelected]);

  const rows = useMemo(() => {
    return props.rowsData(data.rows, allRowsSelected ? allDataSelectId : selectedRows);
  }, [data.rows, selectedRows, allDataSelectId, allRowsSelected]);

  useEffect(() => {
    ref.current.fetchData(pagination, advanceFilter);
    setParentFilters && setParentFilters(advanceFilter);
    localStorageKey && setSessionStorageItem(localStorageKey, { pagination, filter: advanceFilter });
  }, [advanceFilter, pagination]);

  const itemCountHandler = useCallback(
    debounce((data, selectedData, pagination, pageAction, itemCount) => {
      const parcialDataSelectId = data?.filter((item) => selectedData.includes(item.id)).map((item) => item.id);
      if (parcialDataSelectId?.length === data?.length) {
        setItemCount(selectedData?.length);
      } else {
        if (pageAction === "next" || parcialDataSelectId?.length === selectedData?.length) {
          setItemCount(data?.length * pagination.page);
        }
        // if (pageAction === "previous" || parcialDataSelectId.length != selectedData.length) {
        //   setItemCount(itemCount + data.length);
        // }
      }
    }, 500),
    [setItemCount]
  );

  useEffect(() => {
    itemCountHandler(data?.rows || [], selectedRows, pagination, paginationAction, itemCount); // wait for api call
  }, [data.rows, selectedRows, pagination, paginationAction]);

  const commonProps = useMemo(() => {
    return {
      resourceName: props.resourceName,
      itemCount: data?.rows?.length,
      items: data.rows,
      hasMoreItems: pagination.page * pagination.pageSize < data.count,
      renderItem: (item) => props.rowsData(item),
    };
  }, [data, pagination, props]);

  const resourceListWithSelectable = useMemo(
    () => (
      <ResourceList
        {...commonProps}
        selectedItemsCount={data.count === selectedItems?.length ? "All" : selectedItems?.length}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        bulkActions={props.bulkActions}
        promotedBulkActions={props.promotedBulkActions?.map((e) => ({
          content: e.content,
          onAction: () => e.onAction(data, selectedItems),
        }))}
        selectable
      />
    ),
    [commonProps, data, selectedItems, props]
  );

  const resourceListWithoutSelectable = useMemo(() => <ResourceList {...commonProps} />, [commonProps]);

  const handleTabChange = useCallback(
    async (tab) => {
      clearSelection();
      setAllRowsSelected(false);
      setSelectedRows([]);
      setData({ count: 0, rows: [] });
      setSelectedTab(tab);
      setParentSelectedTab && setParentSelectedTab(tab);
      const currentTabQuery = tabOption?.[tab]?.searchQuery;
      currentTabQuery ? setAdvanceFilter([{ ...currentTabQuery, tabNumber: tab }]) : setAdvanceFilter([]);
      setPagination({ page: 1, pageSize: 10 });
    },
    [advanceFilter]
  );

  useEffect(() => {
    setHasInitialData(!(advanceFilter?.length === 0 && (data.count === 0 || !data?.count)));
  }, [advanceFilter, data]);
  return (
    <Card padding="0">
      {showTab && <Tabs tabs={tabOption} selected={parseInt(selectedTab)} onSelect={handleTabChange} />}
      {secondaryTabAction}
      {isFilterVisible && hasInitialData && (
        <CommonFilter
          {...props}
          filters={advanceFilter}
          setPagination={setPagination}
          setFilters={setAdvanceFilter}
          currentTabData={tabOption[selectedTab]}
        />
      )}
      {!hasInitialData && CustomiseComponent ? (
        CustomiseComponent
      ) : isEmptyArray(data.rows || rows) ? (
        <EmptyState
          heading={` ${t("common.CommonTable.No")} ${props.title} ${t("common.CommonTable.Found")}`}
          action={
            isAdd && {
              content: ` ${t("common.CommonTable.Add")} ${props.title}`,
              onAction: props.handleAddClick,
            }
          }
          image={Images.EmptyReuslt}
        />
      ) : (
        <React.Fragment>
          {props.type === "resource" ? (
            props.selectable ? (
              resourceListWithSelectable
            ) : (
              resourceListWithoutSelectable
            )
          ) : (
            <IndexTable
              {...props}
              itemCount={itemCount || data?.rows?.length}
              selectedItemsCount={allRowsSelected ? "All" : selectedRows?.length}
              onSelectionChange={handleParentSelectionChange || handleSelectionChange}
              hasMoreItems={pagination.page * pagination.pageSize < data.count}
              promotedBulkActions={
                promotedBulkActions &&
                promotedBulkActions.map((e) => {
                  return {
                    content: e.content,
                    onAction: () => e.onAction !== null && e.onAction(data.rows, selectedRows, allRowsSelected),
                  };
                })
              }
            >
              {rows}
            </IndexTable>
          )}
          <br />
          {isPaginationVisible && (
            <CommonPagination
              {...props}
              filter={pagination}
              setFilter={setPagination}
              data={data}
              setPaginationAction={setPaginationAction}
            />
          )}
          <br />
        </React.Fragment>
      )}
    </Card>
  );
});

export default CommonTable;
