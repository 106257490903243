import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { BlockStack, Button, Card, InlineStack, Page, Text } from "@shopify/polaris";
import VideoPlayer from "../../../../Components/Common/VideoPlayer";

export default function QuestionSummary({ currentQuestion, setShowSummary }) {
  const [value, setValue] = useState(currentQuestion);

  useEffect(() => {
    setValue(currentQuestion);
  }, [currentQuestion]);

  const handleNext = async () => {
    setShowSummary(false);
  };

  return (
    <div className="assessmnet-Page" style={{ minHeight: "520px" }}>
      <Page>
        <Card>
          <BlockStack align="center" gap="200">
            <InlineStack gap="100" align="center">
              {/* <Image width={50} source={WrongIcon} /> */}
            </InlineStack>
            <div className="assessment-complate">
              <BlockStack align="center" gap="400">
                <InlineStack gap="200" align="center">
                  <Text variant="headingXl" as="h4" align="center">
                    Upcoming Question Summary
                  </Text>
                </InlineStack>
                <InlineStack gap="200" align="center">
                  <Text variant="bodyLg" as="p">
                    Question Type :
                  </Text>
                  <Text variant="headingMd" as="h6">
                    {value?.crtQuation?.aiGenerated
                      ? "Video"
                      : value?.crtQuation?.answerType?.charAt(0).toUpperCase() +
                        value?.crtQuation?.answerType?.slice(1)}
                  </Text>
                </InlineStack>
                <InlineStack gap="200" align="center">
                  <Text variant="bodyLg" as="p">
                    Thinking Time for Question:
                  </Text>
                  {value?.crtQuation?.questionTime || value?.crtQuation?.questionTime == 0 ? (
                    <Text variant="headingMd" as="h6">
                      {" "}
                      {value?.crtQuation?.questionTime} seconds
                    </Text>
                  ) : (
                    <Text variant="headingMd" as="h6">
                      {" "}
                      Unlimited
                    </Text>
                  )}
                </InlineStack>

                <InlineStack gap="200" align="center">
                  <Text variant="bodyLg" as="p">
                    Answer Time Limit :
                  </Text>
                  {value?.crtQuation?.answerTime ? (
                    <Text variant="headingMd" as="h6">
                      {" "}
                      {value?.crtQuation?.answerTime} seconds
                    </Text>
                  ) : (
                    <Text variant="headingMd" as="h6">
                      {" "}
                      Unlimited
                    </Text>
                  )}
                </InlineStack>

                <InlineStack align="center">
                  <Button variant="primary" size="large" onClick={handleNext}>
                    Go to Question
                  </Button>
                </InlineStack>
              </BlockStack>
            </div>
          </BlockStack>
        </Card>
      </Page>
    </div>
  );
}
