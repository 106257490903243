import React, { useRef, useState } from "react";
import { BlockStack, Layout, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { invitesumoAppCodeValues, sumoAppCodeField } from "@/Assets/Mocks/sumoApp.mock";
import CommonForm from "@/Components/Common/CommonForm";
import { getPlanData } from "../../Assets/Mocks/CommonPlan.mock";

export const SumoApp = () => {
  const formRef = useRef();
  const planData = getPlanData();
  const fetch = useAuthenticatedFetch();
  const [sumocodeDatas, setSumocodeDatas] = useState(false);
  const [sumocodesDatas, setSumocodesDatas] = useState(false);
  const [sumocodesData, setSumocodesData] = useState(false);

  const handleSubmit = async (value) => {
    const response = await fetch.get(`appSumo?sumoCode=${value.sumoCode}`, false);
    if (response?.data == true) {
      planData["code"] = value.sumoCode;
      const plan = getPlanData()[1];
      plan["code"] = value.sumoCode;
      await fetch.post(`postPlan`, plan);
      setSumoCodeStates(false, true, false);
    } else if (response?.data == false) {
      setSumoCodeStates(true, false, false);
    } else {
      setSumoCodeStates(false, false, true);
    }
  };
  const setSumoCodeStates = (sumocodeDatas, sumocodesDatas, sumocodesData) => {
    setSumocodeDatas(sumocodeDatas);
    setSumocodesDatas(sumocodesDatas);
    setSumocodesData(sumocodesData);
  };
  return (
    <Layout sectioned>
      <Layout.Section>
        <BlockStack gap="300">
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={invitesumoAppCodeValues}
            formFields={sumoAppCodeField}
            title="Submit"
            isSave={true}
            label="submit"
          />
          <div>
            {sumocodeDatas && (
              <Text as="p" tone="critical">
                Please enter valid code.
              </Text>
            )}
            {sumocodesDatas && (
              <Text as="p" tone="success">
                code have been matched successfully.
              </Text>
            )}
            {sumocodesData && (
              <Text as="p" tone="critical">
                This sumoapp code is already used.
              </Text>
            )}
          </div>
        </BlockStack>
      </Layout.Section>
    </Layout>
  );
};
