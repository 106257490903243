import React, { useCallback, useContext, useEffect, useState } from "react";
import { BlockStack, Card, DropZone, Icon, InlineError, InlineStack, Text, Thumbnail } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { getValueFromNestedObject } from "@/Utils/Index";
import { UserContext } from "../../Context/UserContext";

export default function ImagePicker(props) {
  const fetch = useAuthenticatedFetch();
  const {
    form: { values, setFieldValue, errors, validateForm },
    field: { name, label },
  } = props;
  const { type, ...restFieldProps } = props.field;
  const value = getValueFromNestedObject(values, name);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userProfileData } = useContext(UserContext);

  useEffect(() => {
    fetchData();
  }, [userProfileData]);

  const fetchData = useCallback(async () => {
    const res = await fetch.get(`/organizations`);
    let currentOrganization = res.data.find((org) => org._id === userProfileData.currentOrganization.orgId);
    const url = currentOrganization?.assessmentPreview?.logo?.url;
    if (url) {
      const path = new URL(url).pathname;
      currentOrganization.assessmentPreview.logo.url = `${process.env.APP_URL + path}`;
    }
    if (currentOrganization?.assessmentPreview?.logo) {
      setFiles([
        {
          url: currentOrganization.assessmentPreview.logo.url,
        },
      ]);
      setFieldValue(name, currentOrganization.assessmentPreview.logo);
      validateForm(values);
    }
  }, []);

  useEffect(() => {
    if (!props.field.allowMultiple) {
      if (files.length > 0) {
        setFieldValue(name, files[0]);
      } else {
        setFieldValue(name, files);
      }
    } else {
      setFieldValue(name, files);
    }

    validateForm(values);
  }, [files]);

  const handleDrop = useCallback(
    async (_dropFiles, acceptedFiles, _rejectedFiles) => {
      setLoading(true);
      const formData = new FormData();

      acceptedFiles.forEach((value) => {
        formData.append("files", value, `${value.lastModified}.png`);
      });
      const response = await fetch.post("/upload-to-shopify", formData, true, {
        "Content-Type": " multipart/form-data",
      });
      const newFiles = [...files, ...response.data].filter((file) => file.path && file.path !== "");
      const imageUrl = `${process.env.APP_URL}/uploads/${newFiles[0].filename}`;
      setFiles([
        {
          url: imageUrl,
        },
      ]);
      setLoading(false);
    },
    [files]
  );

  const handleRemoveFile = async (file, index) => {
    //Remove file from shopify
    // console.log("Removing file :", file);
    const removeImage = await fetch.post("/remove-image", file, {
      "Content-Type": " multipart/form-data",
    });

    //Remove file from state
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <BlockStack gap="200">
      <Text as="p">{label}</Text>
      <InlineStack gap="400" blockAlign="center" align="center">
        {files.map((file, index) => (
          <Card padding="200" key={index}>
            <div className="file-upload-preview">
              <div className="remove-image" onClick={() => handleRemoveFile(file, index)}>
                <Icon source={CircleCancelMinor} tone="base" />
              </div>
              <Thumbnail size="large" source={file.url} />
            </div>
          </Card>
        ))}
        {props.field.allowMultiple ? (
          <DropZone {...restFieldProps} type={restFieldProps.fileType} label="" onDrop={handleDrop}>
            <DropZone.FileUpload type={restFieldProps.fileType} />
          </DropZone>
        ) : (
          files.length === 0 && (
            <DropZone {...restFieldProps} label="" type={restFieldProps.fileType} onDrop={handleDrop}>
              <DropZone.FileUpload />
            </DropZone>
          )
        )}
      </InlineStack>
      <InlineError message={errors[name]} />
      {props.helpText && props.helpText.length > 0 && (
        <Text as="span" variant="bodySm" tone="subdued">
          {props.helpText}
        </Text>
      )}
    </BlockStack>
  );
}
