import React from "react";
import { useLocation } from "react-router-dom";
import { BlockStack, Button, Card, Divider, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";

export default function SinglePlan(props) {
  const { plan } = props;
  const location = useLocation();
  const updateFetures = plan.features.map((obj) => {
    if (!plan.selected) {
      if (obj.text.match(/Credit - \d+/)) {
        return { ...obj, text: "" };
      }
    } else {
      if (obj.text.match(/Job Credit - \d+/) && plan?.jobCredit) {
        return { ...obj, text: obj.text.replace(/\d+/, plan?.jobCredit) };
      } else if (obj.text.match(/Job Credit - \d+/)) {
        return { ...obj, text: obj.text.replace(/\d+/, "Unlimited") };
      } else if (obj.text.match(/Member Credit - \d+/) && plan?.userCredit) {
        return { ...obj, text: obj.text.replace(/\d+/, plan?.userCredit) };
      } else if (obj.text.match(/Member Credit - \d+/)) {
        return { ...obj, text: obj.text.replace(/\d+/, "Unlimited") };
      } else if (obj.text.match(/Job Credit - \d+/) || obj.text.match(/Member Credit - \d+/)) {
        return { ...obj, text: "" };
      }
    }

    if (obj.text === "Unlimited Job") {
      return { ...obj, text: `${plan.Job} Job` };
    }
    if (obj.text === "Unlimited Candidates") {
      return { ...obj, text: `${plan.candidate} Candidates` };
    }
    if (obj.text === "Invite Unlimited members") {
      return { ...obj, text: `Invite ${plan.members} Members` };
    }
    if (obj.text === "30 day video back up") {
      return { ...obj, text: `${plan.video} Days Video Backup` };
    }
    return obj;
  });

  const handleContactClick = () => {
    window.open("https://app.apollo.io/#/meet/sales-recruitments.ai/30-min", "_blank");
  };

  return (
    <div className="pricing-card">
      <Card padding="400" background={plan.selected ? "bg-fill-success-secondary" : "bg-surface"}>
        <BlockStack gap="400">
          <InlineStack blockAlign="center" align="space-between">
            <Text variant="headingLg" as="h2">
              {plan.name}
            </Text>
            {plan.selected && <Icon source={CircleTickMajor} color="success" />}
          </InlineStack>
          {plan.type === "recurring" && !plan.selected && (
            <Button variant="primary" tone="success" onClick={handleContactClick}>
              Contact Us
            </Button>
          )}
          <Divider></Divider>
          <BlockStack gap="400">
            {updateFetures.map(
              (feature, index) =>
                feature.text.trim() !== "" && (
                  <InlineStack gap="200" key={index} blockAlign="center" wrap={false}>
                    <Icon size="large" source={CircleTickMajor} tone="success" className="custom-icon" />
                    <Text wrap as="p">
                      {feature.text}
                    </Text>
                  </InlineStack>
                )
            )}
          </BlockStack>
        </BlockStack>
      </Card>
    </div>
  );
}
