import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { BlockStack, Box, Button, ButtonGroup, Card, Image, InlineStack, Popover, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BlankStar, FilledStar } from "@/Assets/Index";

export default function CandidateReview({ candidate, width }) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [rate, setRate] = useState(urlParams.get("rate") || 0);
  const fetch = useAuthenticatedFetch();
  const [popoverActive, setPopoverActive] = useState(false);

  useEffect(() => {
    candidateData();
  }, []);

  const candidateData = useCallback(async () => {
    if (candidate?.isStar) setRate(1);
  }, [rate]);

  const handelSetStar = useCallback(async () => {
    rate == 1 ? setRate(0) : setRate(1);
    await fetch.post("/candidate", { isStar: rate == 1 ? false : true, _id: candidate?._id }, false);
    setPopoverActive(false);
  }, [rate]);

  const no = (e) => setPopoverActive(false);

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((popoverActive) => {
      if (rate === 0) {
        handelSetStar();
        return false;
      }
      return !popoverActive;
    });
  }, [rate]);
  const activator = (
    <InlineStack gap={100}>
      {[...Array(1)].map((_, index) => (
        <Button key={index} variant="plain" onClick={togglePopoverActive}>
          <Image source={index + 1 > rate ? BlankStar : FilledStar} width={width ? width : 30} />
        </Button>
      ))}
    </InlineStack>
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
      >
        <Box padding={200}>
          <BlockStack gap={200}>
            {rate == 1 ? (
              <div>Are you sure you want to remove star for this Candidate?</div>
            ) : (
              <div>Are you sure you want to star this Candidate?</div>
            )}
            <InlineStack align="center">
              <ButtonGroup>
                <Button onClick={handelSetStar}>Yes</Button>
                <Button onClick={no}>No</Button>
              </ButtonGroup>
            </InlineStack>
          </BlockStack>
        </Box>
      </Popover>
    </div>
  );
}
