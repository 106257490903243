import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Badge, BlockStack, Button, Icon, InlineStack, Text } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import parse from "html-react-parser";
import { CandidateForm } from "../../StageAssessment/CandidateForm";
import { ContectPerson } from "../../StageAssessment/ContectPerson";
import { ShareLink } from "../../StageAssessment/ShareLink";
import { AssessmentConformation } from "./AssessmentConformation.jsx";

export const PublicLinkJobPreview = (props) => {
  const { setStep, step, jobData, organization, assessmentPreviews, stage, candidateData } = props;
  const [imgUrl, setImgUrl] = useState();
  const [asssessmentConformPage, setasssessmentConformPage] = useState(false);
  const [assessmentStart, setAsssessmentStart] = useState(false);
  const location = useLocation();
  const [id, setId] = useState();
  const candidateFormRef = useRef(null); // Ref for CandidateForm section
  const urlParams = new URLSearchParams(location.search);
  const [candidate, setCandidate] = useState();

  useEffect(() => {
    if (assessmentPreviews?.logo?.length == 0) {
      setImgUrl();
    } else if (assessmentPreviews?.logo?.url) {
      const url = assessmentPreviews?.logo?.url;
      const path = new URL(url).pathname;
      let imageUrl = `${process.env.APP_URL + path}`;
      setImgUrl(imageUrl);
    } else if (organization?.assessmentPreview?.logo?.url) {
      const url = organization?.assessmentPreview?.logo?.url;
      const path = new URL(url).pathname;
      let imageUrl = `${process.env.APP_URL + path}`;
      setImgUrl(imageUrl);
    }
  }, [assessmentPreviews, imgUrl, organization]);
  useEffect(() => {
    if (assessmentStart) {
      setStep(step + 1);
    }
  }, [assessmentStart]);
  useEffect(() => {
    const candiateParam = urlParams.get("candidateId");
    const shareParam = urlParams.get("assessmentId");
    if (shareParam) {
      setId(shareParam);
    }
    if (!candidateData?.assessmentConformation && candiateParam) {
      setasssessmentConformPage(true);
    } else if (candidateData?.assessmentConformation) {
      setStep(step + 1);
    }
  }, [candidateData, id]);

  const handleApplyNowClick = () => {
    candidateFormRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to the CandidateForm section
  };

  function cleanHtml(html) {
    return html.replace(/(<p>\s*<\/p>\s*)+$/, (match) => {
      const matchLines = match.split(/(?:\r\n|\r|\n)+/);
      if (matchLines.length > 1) {
        return "<p></p>";
      }
      return "";
    });
  }
  return (
    <>
      {jobData?.jobDescription && (
        <div
          className="publicJobPage"
          style={{ display: "flex", padding: asssessmentConformPage ? "0px 10px" : "0px 150px" }}
        >
          {asssessmentConformPage ? (
            <AssessmentConformation
              candidateData={candidateData ? candidateData : candidate}
              setAsssessmentStart={setAsssessmentStart}
              stage={stage}
              id={id}
            ></AssessmentConformation>
          ) : (
            <>
              {" "}
              <div className="jobDescription" style={{ width: "70%", paddingRight: "20px" }}>
                <BlockStack gap="200">
                  {jobData?.jobDescription && (
                    <BlockStack gap="400">
                      <InlineStack align="space-between">
                        <InlineStack gap={100} blockAlign="center">
                          <Icon color="subdued" source={ClipboardMinor} tone="base" />
                          <Text variant="headingMd" color="subdued">
                            Job Description
                          </Text>
                        </InlineStack>
                        <div className="applyNow" style={{ display: "none" }}>
                          <Button variant="primary" size="large" onClick={handleApplyNowClick}>
                            Apply now
                          </Button>
                        </div>
                      </InlineStack>
                      <BlockStack inlineAlign="start">
                        <InlineStack align="start" gap="100">
                          {jobData?.keySkills?.split(",").map((skill, index) => (
                            <Badge key={index}>{skill ? skill : "React Js"}</Badge>
                          ))}
                        </InlineStack>
                        {jobData && jobData.jobDescription && (
                          <div className="tinyPreview jobDescription">
                            {" "}
                            {parse(cleanHtml(jobData?.jobDescription))}
                          </div>
                        )}
                      </BlockStack>
                    </BlockStack>
                  )}
                </BlockStack>
              </div>
              <div className="candiateDetails" style={{ width: "30%" }} ref={candidateFormRef}>
                {jobData?.jobDescription && (
                  <BlockStack gap="400">
                    <CandidateForm
                      jobData={jobData}
                      stage={stage}
                      setasssessmentConformPage={setasssessmentConformPage}
                      setCandidate={setCandidate}
                    ></CandidateForm>
                    <div className="contectPerson">
                      <ContectPerson jobData={jobData} stage={stage}></ContectPerson>
                    </div>
                    <div className="contectPerson">
                      <ShareLink jobData={jobData} stage={stage}></ShareLink>
                    </div>
                  </BlockStack>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
