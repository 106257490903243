import React, { useCallback, useContext, useEffect, useState } from "react";
import { Banner, BlockStack, InlineStack, LegacyCard, Link, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { featureList, getPlanData } from "../../Assets/Mocks/CommonPlan.mock";
import { UserContext } from "../../Context/UserContext";
import SinglePlan from "./SinglePlan";

export default function Pricing(config) {
  const planData = getPlanData();
  const { userProfileData } = useContext(UserContext);
  const [pricingData, setPricingData] = useState(planData);
  const fetch = useAuthenticatedFetch();
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [isStatusActive, setStatusActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(2);
  const [timeLeft, setTimeLeft] = useState();
  const fetchPlanData = useCallback(async () => {
    setLoading(true);
    const res = await fetch.get("getPlan");
    if (res?.data) {
      setSelectedPlan(res?.data);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchPlanData();
  }, []);
  useEffect(() => {
    let flag = false;
    if (userProfileData && selectedPlan && isStatusActive) {
      const data = pricingData.map((plan) => {
        if (plan.name === selectedPlan.planName) {
          plan["selected"] = true;
          plan["intervalLable"] = selectedPlan.intervalLable;
          flag = true;
          plan["price"] = selectedPlan.appSumoPrice;
          plan["originalPrice"] = selectedPlan?.originalPrice;
          // plan["credit"] = selectedPlan.featureLimitation.interview;
          plan["jobCredit"] = selectedPlan.featureLimitation.jobCredit;
          plan["userCredit"] = selectedPlan.featureLimitation.userCredit;
        } else {
          plan["selected"] = false;
        }
        return plan;
      });
      if (!flag) {
        let feature = featureList[selectedPlan.planId] ? featureList[selectedPlan.planId] : featureList["Premium"];
        let plan = {
          type: selectedPlan.is_recurring ? "recurring" : "onetime",
          intervalLable: selectedPlan.intervalLable,
          name: selectedPlan.planName,
          isHidden: false,
          price: selectedPlan.appSumoPrice,
          originalPrice: selectedPlan.originalPrice,
          features: [...feature],
          selected: true,
          // credit: selectedPlan.featureLimitation.interview,
          jobCredit: selectedPlan.featureLimitation.jobCredit,
          userCredit: selectedPlan.featureLimitation.userCredit,
          finalPrice: selectedPlan?.discountedPrice ? selectedPlan.discountedPrice : selectedPlan.appSumoPrice,
        };
        data.push(plan);
      }
      setPricingData(data);
      setStatusActive(false);
    }
  }, [userProfileData, selectedPlan]);

  useEffect(() => {
    const thirtyDays = 30 * 24 * 60 * 60 * 1000;
    const updatedTime = new Date(userProfileData?.pricingPlan?.planStartDate).getTime();
    const now = Date.now();
    const timeElapsed = now - updatedTime;
    const timeLeft = thirtyDays - timeElapsed;
    const timeLeftInSeconds = Math.ceil(timeLeft / 1000);
    setCountdown(timeLeftInSeconds);
  }, [userProfileData]);

  function minTwoDigits(n) {
    return String(n).padStart(2, "0");
  }

  useEffect(() => {
    let interval;

    if (countdown > 0) {
      interval = setInterval(() => {
        if (countdown > 0) {
          const days = minTwoDigits(Math.floor(countdown / (24 * 60 * 60)));
          const hours = minTwoDigits(Math.floor((countdown % (24 * 60 * 60)) / (60 * 60)));
          const minutes = minTwoDigits(Math.floor((countdown % (60 * 60)) / 60));
          const seconds = minTwoDigits(Math.floor(countdown % 60));
          setTimeLeft(`${days}:${hours}:${minutes}:${seconds}`);
          setCountdown(countdown - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  const filteredPlans = pricingData.filter((plan) => {
    const selectedFreePlan = pricingData.find((plan) => plan.name === "Free" && plan.selected);
    if (pricingData.length > 2) {
      if (plan.name === "Premium") {
        if (selectedFreePlan) {
          plan["isHidden"] = false;
        }
      }
      // if (plan.name === "App Sumo" && plan.selected) {
      //   plan["isHidden"] = false;
      // }
    }
    if (config?.plans?.length > 0 && !config.plans.includes(plan.id)) {
      return false;
    } else if (typeof plan.isHidden === "string") {
      return !eval(plan.isHidden);
    } else {
      return !plan.isHidden;
    }
  });

  if (loading) {
    return <></>;
  }

  const renderPlan = () => (
    <BlockStack gap="500" inlineAlign="center">
      <InlineStack
        gap="400"
        columns={{
          xs: "1",
          sm: "2",
          md: filteredPlans.length > 3 ? 3 : filteredPlans.length,
          lg: filteredPlans.length > 3 ? 3 : filteredPlans.length,
          xl: filteredPlans.length > 3 ? 3 : filteredPlans.length,
        }}
      >
        {filteredPlans.map((plan, index) => (
          <SinglePlan key={index} plan={plan} profileData={userProfileData} />
        ))}
      </InlineStack>
      <Text></Text>
    </BlockStack>
  );

  return (
    <div className="pricing-plan">
      <Page>
        <BlockStack gap="500">
          {userProfileData?.pricingPlan?.isTrial &&
            userProfileData?.pricingPlan?.planId === "Free" &&
            countdown > 0 && (
              <LegacyCard>
                <Banner>
                  <BlockStack>
                    <Text>Your trail period ends in {timeLeft}.</Text>
                    <Text>
                      To continue enjoying the premium features, please select a plan that best suits your needs.
                    </Text>
                    <Text>
                      If you choose not to upgrade, your team will be automatically downgraded to the free basic
                      plan, allowing you to continue using Recruitment AI without the advanced features of the
                      trial.
                    </Text>
                    <Text>
                      <Link url="https://app.apollo.io/#/meet/sales-recruitments.ai/30-min" target="_blank">
                        Learn more about our plans
                      </Link>
                    </Text>
                  </BlockStack>
                </Banner>
              </LegacyCard>
            )}
          {userProfileData?.pricingPlan?.isTrial == false && (
            <Banner
              tone="warning"
              title={`Your trial period has finished. Please upgrade your plan to access all features.`}
            ></Banner>
          )}
          <BlockStack gap="600">{renderPlan()}</BlockStack>{" "}
        </BlockStack>
      </Page>
    </div>
  );
}
