import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./Template1.scss";

export function Template1({ resumeData }) {
  function addEducation(value) {
    return (
      <>
        <div className="jobPosition">
          <h3>
            {value?.graduationDate} {value?.institutionName}
          </h3>
          <h3 className="location">{value?.location}</h3>
        </div>
        {value?.fieldOfStudy && <p>Field of Study: {value?.fieldOfStudy}</p>}
        {value?.degreeEarned && <p>Degree Earned: {value?.degreeEarned}</p>}
      </>
    );
  }
  function addWorkExpireance(value) {
    const dateRange =
      value?.endDate === "Present" ? `${value?.startDate} - Present` : `${value?.startDate} - ${value?.endDate}`;
    return (
      <>
        <div style={{ display: "flex" }}>
          <h3>
            {dateRange} &nbsp; &nbsp;{" "}
            <span>
              <strong>{value?.companyName}</strong>
            </span>
            {value?.jobTitle}
          </h3>
        </div>
        <ul>
          {value?.responsibilities &&
            value?.responsibilities.map((responsibility, index) => <li key={index}>{responsibility}</li>)}
        </ul>
      </>
    );
  }

  function projectExpireance(value) {
    const dateRange =
      value.endDate === "Present" ? `${value?.startDate} - Present` : `${value?.startDate} - ${value?.endDate}`;

    return (
      <>
        <h3>
          <span>{dateRange} &nbsp; &nbsp;</span>
          <span>
            <strong>{value.projectName}</strong>
          </span>
        </h3>
        <a href={value?.projectLink}>{value?.projectLink}</a>
        {value?.projectDescription && <p>Description: {value?.projectDescription}</p>}
        {value?.roleResponsibility && <p>Responsibility:{value?.roleResponsibility}</p>}

        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {value?.technologiesUsed &&
            value?.technologiesUsed.map((technology, index) => (
              <li style={{ width: "150px" }} className="technology" key={index}>
                {technology}
              </li>
            ))}
        </ul>
      </>
    );
  }
  return (
    <div>
      <div id="resumeContent">
        <header className=" container-fluid">
          <table>
            <tbody>
              <tr id="personalInformation">
                <td className="info-middle">
                  <h2 className="sec-label">
                    {resumeData?.PersonalInformation?.firstName} {resumeData?.PersonalInformation?.lastName}
                  </h2>
                </td>
                <td className="info-small text-right info-middle">
                  <p>{resumeData?.PersonalInformation?.mailingAddress}</p>

                  <p>
                    {resumeData?.PersonalInformation?.phoneNumber && (
                      <span>Ph: {resumeData?.PersonalInformation?.phoneNumber} </span>
                    )}
                    {resumeData?.PersonalInformation?.emailAddress && (
                      <span>
                        {" "}
                        | Email:
                        {resumeData?.PersonalInformation?.emailAddress}
                      </span>
                    )}
                  </p>
                  <p>
                    {resumeData?.PersonalInformation?.githubProfile && (
                      <span>Github Profile: {resumeData?.PersonalInformation?.githubProfile} </span>
                    )}
                    {resumeData?.PersonalInformation?.linkedInProfile && (
                      <span>
                        {" "}
                        | LinkedIn Profile:
                        {resumeData?.PersonalInformation?.linkedInProfile}
                      </span>
                    )}
                  </p>
                  <p>
                    {resumeData?.PersonalInformation?.personalWebsite && (
                      <span>Personal Website: {resumeData?.PersonalInformation?.personalWebsite} </span>
                    )}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </header>
        <div className="container">
          {resumeData?.Skills?.length > 0 && (
            <div id="skill">
              <table className="printable-content">
                <tbody>
                  <tr>
                    <td className="heading" style={{ width: "150px" }}>
                      <h2 className="sec-label">Technical Skills</h2>
                    </td>
                    <td>
                      <ul style={{ display: "flex", flexWrap: "wrap" }}>
                        {resumeData?.Skills.map((skill, index) => (
                          <li className="skills" key={index}>
                            {skill}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr className="section-break" />
            </div>
          )}
          {resumeData?.ProfessionalSummary?.summary && (
            <div id="summary">
              <table className="printable-content">
                <tbody>
                  <tr>
                    <td className="heading">
                      <h2 className="sec-label">Objective</h2>
                    </td>
                    <td className="content">
                      <p>{resumeData?.ProfessionalSummary?.summary}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr className="section-break" />
            </div>
          )}
          {resumeData?.Education?.length > 0 && (
            <div id="education">
              <table className="printable-content">
                <tbody>
                  <tr className="printable-content">
                    <td className="edu heading" style={{ width: "150px" }}>
                      <h2 className="sec-label">Education</h2>
                    </td>
                    <td>
                      <div style={{ paddingTop: "10px" }}>
                        {resumeData?.Education.map((e, index) => (
                          <div style={{ marginBottom: "10px" }} className="printable-content" key={index}>
                            {addEducation(e)}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr className="section-break" />
            </div>
          )}
          {resumeData?.WorkExperience?.length > 0 && (
            <div id="workExperience">
              <table>
                <tbody>
                  <tr>
                    <td className="heading" style={{ width: "150px" }}>
                      <h2 className="sec-label">Work Experience</h2>
                    </td>
                    <td>
                      <div id="workExperienceData" style={{ paddingTop: "10px" }}>
                        {resumeData.WorkExperience.map((e, index) => (
                          <div style={{ marginBottom: "10px" }} className="printable-content" key={index}>
                            {addWorkExpireance(e)}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {(resumeData?.Project?.length > 0 ||
                resumeData?.Certification?.length > 0 ||
                resumeData?.Award?.length > 0 ||
                resumeData?.Hobby?.length > 0 ||
                resumeData?.Language?.length > 0) && <hr className="section-break" />}
            </div>
          )}
          {resumeData?.Project?.length > 0 && (
            <div id="project">
              <table>
                <tbody>
                  <tr>
                    <td className="heading">
                      <h2 className="sec-label">Project</h2>
                    </td>
                    <div id="projectData" style={{ paddingLeft: "60px", paddingTop: "10px" }}>
                      {resumeData?.Project.map((e, index) => (
                        <div style={{ marginBottom: "10px" }} className="printable-content" key={index}>
                          {projectExpireance(e)}
                        </div>
                      ))}
                    </div>
                  </tr>
                </tbody>
              </table>
              {(resumeData?.Certification?.length > 0 ||
                resumeData?.Award?.length > 0 ||
                resumeData?.Hobby?.length > 0 ||
                resumeData?.Language?.length > 0) && <hr className="section-break" />}
            </div>
          )}
          {resumeData?.Certification?.length > 0 && (
            <div id="certification">
              <table>
                <tbody>
                  <tr className="printable-content">
                    <td className="heading" style={{ width: "150px" }}>
                      <h2 className="sec-label">Certification</h2>
                    </td>
                    <td>
                      <ul>
                        {resumeData?.Certification.map((certification, index) => (
                          <li key={index} style={{ padding: "5px" }}>
                            {certification?.certificationName}-{certification?.issuingOrganization}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              {(resumeData?.Award?.length > 0 ||
                resumeData?.Hobby?.length > 0 ||
                resumeData?.Language?.length > 0) && <hr className="section-break" />}
            </div>
          )}
          {resumeData?.Award?.length > 0 && (
            <div id="award">
              <table>
                <tbody>
                  <tr className="printable-content">
                    <td className="heading" style={{ width: "150px" }}>
                      <h2 className="sec-label">Award</h2>
                    </td>
                    <td>
                      <ul>
                        {resumeData?.Award.map((award, index) => (
                          <>
                            <h3>
                              <span>{award?.dateReceived} </span>
                              <span>{award?.awardingOrganization} </span>
                            </h3>
                            <li key={index} style={{ padding: "5px" }}>
                              {award?.awardName}
                            </li>
                          </>
                        ))}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              {(resumeData?.Hobby?.length > 0 || resumeData?.Language?.length > 0) && (
                <hr className="section-break" />
              )}
            </div>
          )}
          {resumeData?.Hobby?.length > 0 && (
            <div id="hobby">
              <table>
                <tbody>
                  <tr className="printable-content">
                    <td className="heading" style={{ width: "150px" }}>
                      <h2 className="sec-label">Hobby</h2>
                    </td>
                    <td>
                      <ul style={{ display: "flex", flexWrap: "wrap" }}>
                        {resumeData?.Hobby.map((hobby, index) => (
                          <li className="skills" key={index}>
                            {hobby}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              {resumeData?.Language?.length > 0 && <hr className="section-break" />}
            </div>
          )}
          {resumeData?.Language?.length > 0 && (
            <div id="language">
              <table>
                <tbody>
                  <tr className="printable-content">
                    <td className="heading" style={{ width: "150px" }}>
                      <h2 className="sec-label">Language</h2>
                    </td>
                    <td>
                      <ul style={{ display: "flex", flexWrap: "wrap" }}>
                        {resumeData?.Language.map((language, index) => (
                          <li className="skills" key={index}>
                            {language?.languageName}-{language?.proficiencyLevel}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
