export const inviteTeamformFields = [
  {
    id: "email",
    name: "email",
    label: "Email",
    validated: true,
    type: "email",
    errormsg: "Email is required",
  },
  {
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        id: "isAdministartor",
        name: "isAdministartor",
        label: "Administrator",
        type: "checkbox",
        onChange: (value, form) => {
          form.setFieldValue(`viewJobs`, value);
          form.setFieldValue(`manageJobs`, value);
          form.setFieldValue(`isAdministartor`, value);
          form.setFieldValue(`viewCandidate`, value);
          form.setFieldValue(`manageCandidate`, value);
          form.setFieldValue(`planAccess`, value);
          form.setFieldValue(`brandingAccess`, value);
          form.setFieldValue(`settingAccess`, value);

          if (value === false) {
            form.setFieldValue(`viewJobs`, true);
            form.setFieldValue(`viewCandidate`, true);
          }
        },
      },
      {
        id: "limitedAccess",
        name: "limitedAccess",
        label: "Limited Access",
        type: "checkbox",
      },
    ],
  },
  {
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        id: "viewJobs",
        name: "viewJobs",
        label: "View Jobs",
        type: "checkbox",
        onChange: (value, form) => {
          form.setFieldValue(`viewJobs`, value);
          form.setFieldValue(`viewCandidate`, value);
        },
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
      },
      {
        id: "manageJobs",
        name: "manageJobs",
        label: "Manage Jobs",
        type: "checkbox",
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
      },
    ],
  },
  {
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        id: "viewCandidate",
        name: "viewCandidate",
        label: "View Candidate",
        type: "checkbox",
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
        dependOn: {
          name: "manageJobs",
          value: true,
          type: "disabled",
        },
        dependOn: {
          name: "viewJobs",
          value: true,
          type: "disabled",
        },
      },
      {
        id: "manageCandidate",
        name: "manageCandidate",
        label: "Manage Candidate",
        type: "checkbox",
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
        dependOn: {
          name: "manageJobs",
          value: true,
          type: "disabled",
        },
      },
    ],
  },
  {
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        id: "planAccess",
        name: "planAccess",
        label: "Plan Access",
        type: "checkbox",
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
      },
      {
        id: "brandingAccess",
        name: "brandingAccess",
        label: "Branding Access",
        type: "checkbox",
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
      },
    ],
  },
  {
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        id: "settingAccess",
        name: "settingAccess",
        label: "Setting Access",
        type: "checkbox",
        dependOn: {
          name: "isAdministartor",
          value: true,
          type: "disabled",
        },
      },
    ],
  },
];

export const inviteTeaminitialValues = {
  email: "",
  viewJobs: false,
  manageJobs: false,
  viewCandidate: false,
  manageCandidate: false,
  isAdministartor: false,
  limitedAccess: false,
  planAccess: false,
  brandingAccess: false,
  settingAccess: false,
};
