import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { BlockStack, Box, Card, InlineStack, Page, Text } from "@shopify/polaris";
import { navigate } from "raviger";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { setLocalStorageItem } from "@/Utils/Index";
import { organizationFields, organizationInitialValues } from "../../../Assets/Mocks/Organization.mock";
import { UserContext } from "../../../Context/UserContext";

export const FirstOrganization = () => {
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const { updateProfileInfo } = useContext(UserContext);
  const { userProfileData } = useContext(UserContext);

  const location = useLocation();
  const { organization, isEdit } = location.state || {};

  const handleSubmit = useCallback(
    async (e) => {
      const res = await fetch.post("/organization", e);
      if (res.data) {
        setLocalStorageItem("authorization", res.data.token);
        updateProfileInfo(res.data);
        setTimeout(() => {
          navigate(`/jobs`);
        }, 1000);
      }
    },
    [userProfileData]
  );

  return (
    <Page fullWidth={true} narrowWidth={true}>
      <Card roundedAbove="sm">
        <BlockStack gap="600">
          <Text alignment="center" variant="headingLg">
            Add Organization
          </Text>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={isEdit ? organization : organizationInitialValues}
            formFields={organizationFields}
            label={"Add"}
          />
        </BlockStack>
      </Card>
    </Page>
  );
};
