import React, { useCallback, useEffect, useState } from "react";
import { BlockStack, Button, Card, Icon, Image, InlineStack, Text, Tooltip } from "@shopify/polaris";
import { EmailMajor, PhoneMajor } from "@shopify/polaris-icons";
import { Whatsapp } from "@/Assets/Index";

export const ContectPerson = (props) => {
  const { jobData, stage } = props;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 767);

  const sendWhatsapp = () => {
    const whatsappUrl = `https://web.whatsapp.com/send?phone=${jobData?.userPhone}`;
    window.open(whatsappUrl, "_blank");
  };

  const askQuestion = useCallback(() => {
    window.location.href = `mailto:${jobData?.userEmail}`;
  }, [jobData]);
  const directCall = useCallback(() => {
    window.location.href = `tel:${jobData?.userPhone}`;
  }, [jobData]);

  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth > 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Card>
        <BlockStack gap={100}>
          <Text variant="headingLg">Contact Recruiter</Text>
          <InlineStack blockAlign="center">
            <div className="circle">
              <p className="circle-inner">{jobData?.userFirstName.slice(0, 2).toUpperCase()}</p>
            </div>
            <BlockStack blockAlign="center" gap={100}>
              <Text variant="headingMd">
                {jobData?.userFirstName} {jobData?.userLastName}
              </Text>
              <Text>Resource Manager</Text>
            </BlockStack>
          </InlineStack>
          <InlineStack gap={600} blockAlign="center">
            <a className="shareLink" style={{ cursor: "pointer" }} onClick={() => askQuestion()}>
              <Icon color="subdued" source={EmailMajor} tone="base" />
            </a>
            {stage?.recruiterContect?.whatsappAccess && (
              <a className="shareLink" style={{ cursor: "pointer" }} onClick={() => sendWhatsapp()}>
                <Image width={16} source={Whatsapp} />
              </a>
            )}
            {stage?.recruiterContect?.phoneAccess && (
              <>
                {isWideScreen ? (
                  <Tooltip content={jobData?.userPhone}>
                    <Icon color="subdued" source={PhoneMajor} tone="base" />
                  </Tooltip>
                ) : (
                  <a className="shareLink" style={{ cursor: "pointer" }} onClick={() => directCall()}>
                    <Icon color="subdued" source={PhoneMajor} tone="base" />
                  </a>
                )}
              </>
            )}
          </InlineStack>
        </BlockStack>
      </Card>
    </>
  );
};
