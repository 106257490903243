import React, { useCallback, useContext, useRef, useState } from "react";
import { Badge, BlockStack, Button, InlineStack, Layout, Modal, Text } from "@shopify/polaris";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { inviteTeamformFields, inviteTeaminitialValues } from "@/Assets/Mocks/InviteTeam.mock";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable";
import { ToastContext } from "@/Context/ToastContext";
import { UserContext } from "../../../Context/UserContext";

export const TeamMates = () => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const childRef = useRef();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [actionType, setActionType] = useState("");
  const [inviteTeamFormValues, setInviteTeamFormValues] = useState(inviteTeaminitialValues);
  const { showToast } = useContext(ToastContext);

  const inviteTeamMate = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleInvite = () => {
    setActionType("add");
    setIsInviteModalOpen(true);
  };

  const handleSubmit = async (e) => {
    if (actionType === "add") {
      await fetch.post("/invite", {
        fromEmail: userProfileData?.email,
        organizations: userProfileData?.organizations,
        ...e,
      });
      showToast("invite teammate successfully");
    }

    if (actionType === "update") {
      await fetch.post("/invite", e);
      showToast("Update teammate successfully");
    }
    childRef.current.fetchData();
    if (userProfileData?.pricingPlan?.featureLimitation?.userCredit) {
      await cutCredit();
    }
    setIsInviteModalOpen(false);
    setInviteTeamFormValues({ ...inviteTeaminitialValues });
  };

  const cutCredit = async () => {
    userProfileData.pricingPlan.featureLimitation.userCredit -= 1;
    const updatedPricingPlan = {
      ...userProfileData.pricingPlan,
    };

    const pricingPlanRes = await fetch.put("/putPlan", updatedPricingPlan);
    const updatedUserProfileData = {
      ...userProfileData,
      pricingPlan: pricingPlanRes.data,
    };

    updateProfileInfo(updatedUserProfileData);
    childRef.current.fetchData();
  };

  const addCredit = async () => {
    userProfileData.pricingPlan.featureLimitation.userCredit += 1;
    const updatedPricingPlan = {
      ...userProfileData.pricingPlan,
    };

    const pricingPlanRes = await fetch.put("/putPlan", updatedPricingPlan);
    const updatedUserProfileData = {
      ...userProfileData,
      pricingPlan: pricingPlanRes.data,
    };

    updateProfileInfo(updatedUserProfileData);
    childRef.current.fetchData();
  };

  const handleInviteModalClose = () => {
    setIsInviteModalOpen(false);
    setInviteTeamFormValues({ ...inviteTeaminitialValues });
  };

  const handleDelete = useCallback((row) => {
    setMemberToDelete(row);
    setIsDeleteModalOpen(true);
  }, []);

  const handleDeleteConfirmed = async () => {
    if (memberToDelete) {
      await fetch.delete(`/teammate/${memberToDelete._id}`);
      setIsDeleteModalOpen(false);
      childRef.current.fetchData();
      if (userProfileData?.pricingPlan?.featureLimitation?.userCredit) {
        await addCredit();
      }
      showToast("Delete teammate successfully");
    }
  };

  const handleDeleteCanceled = () => {
    setMemberToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleEdit = (row) => {
    const matchingOrganization = row.organizations.find(
      (organization) => organization.orgId === userProfileData.currentOrganization.orgId
    );
    if (matchingOrganization) {
      const isAdministartor = matchingOrganization.role === "administrator";
      const permissions = matchingOrganization.permission
        ? matchingOrganization.permission.map((perm) => perm.name)
        : [];

      const manageJobs = permissions.includes("manageJobs");
      const viewJobs = permissions.includes("viewJobs");
      const viewCandidate = permissions.includes("viewCandidate");
      const manageCandidate = permissions.includes("manageCandidate");
      const limitedAccess = permissions.includes("limitedAccess");
      const planAccess = permissions.includes("planAccess");
      const brandingAccess = permissions.includes("brandingAccess");
      const settingAccess = permissions.includes("settingAccess");

      setActionType("update");

      setInviteTeamFormValues({
        ...inviteTeaminitialValues,
        ...row,
        isAdministartor,
        manageJobs,
        viewJobs,
        viewCandidate,
        manageCandidate,
        limitedAccess,
        planAccess,
        brandingAccess,
        settingAccess,
      });

      setIsInviteModalOpen(true);
    }
  };

  const createRowsData = useCallback(
    (rows) => {
      return rows.map((row) => {
        const currentOrganization = row?.organizations.find(
          (organization) => organization?.orgId === userProfileData?.currentOrganization?.orgId
        );

        return [
          <InlineStack gap="200">
            {row.email}
            {currentOrganization?.owner && (
              <Badge size="small" tone="info">
                Owner
              </Badge>
            )}
          </InlineStack>,
          currentOrganization?.role,
          row.status,
          userProfileData?.currentOrganization.owner && !currentOrganization?.owner ? (
            <InlineStack gap="100">
              <Button
                plain
                monochrome
                icon={EditMinor}
                onClick={() => {
                  handleEdit(row);
                }}
              />

              <Button
                plain
                monochrome
                icon={DeleteMinor}
                onClick={() => {
                  handleDelete(row);
                }}
              />
            </InlineStack>
          ) : (
            "N/A"
          ),
        ];
      });
    },
    [childRef]
  );

  return (
    <Layout sectioned>
      <Layout.Section>
        <BlockStack gap="300">
          {userProfileData?.currentOrganization?.owner && (
            <InlineStack align="end" gap="200">
              {(userProfileData?.pricingPlan?.featureLimitation?.userCredit ||
                userProfileData?.pricingPlan?.featureLimitation?.userCredit == 0) && (
                <Badge
                  fullWidth
                  tone={userProfileData?.pricingPlan?.featureLimitation?.userCredit == 0 ? "critical" : "success"}
                >
                  <Text variant="headingSm" align="center">
                    {`Maximum Members`} : {userProfileData?.pricingPlan?.featureLimitation?.userCredit}
                  </Text>
                </Badge>
              )}
              <Button
                disabled={userProfileData?.pricingPlan?.featureLimitation?.userCredit == 0}
                onClick={() => handleInvite()}
              >
                Invite Teammates
              </Button>
            </InlineStack>
          )}
          <BannerModal
            open={isInviteModalOpen}
            onClose={handleInviteModalClose}
            title={"Invite Teammate"}
            primaryAction={{
              content: actionType === "add" ? "Invite" : "Update Invitation",
              onAction: inviteTeamMate,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: handleInviteModalClose,
              },
            ]}
            children={
              <CommonForm
                onSubmit={handleSubmit}
                formRef={formRef}
                initialValues={inviteTeamFormValues}
                formFields={inviteTeamformFields}
                title="Invite Teammate"
                isSave={false}
              />
            }
          />
          {userProfileData && userProfileData?.organizations.length > 0 && (
            <CommonTable
              title="Team Members"
              url={`/users`}
              rowsData={createRowsData}
              headings={["Email", "Role", "Status", "Action"]}
              ref={childRef}
              columnContentTypes={["string", "string", "string", "Action"]}
              isAdd={false}
              isSearchVisible={false}
            />
          )}
          <Modal
            open={isDeleteModalOpen}
            onClose={handleDeleteCanceled}
            title="Confirm Delete"
            primaryAction={{
              content: "Delete",
              onAction: handleDeleteConfirmed,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: handleDeleteCanceled,
              },
            ]}
          >
            <Modal.Section>
              <Text>Are you sure you want to delete this member?</Text>
            </Modal.Section>
          </Modal>
        </BlockStack>
      </Layout.Section>
    </Layout>
  );
};
