import React, { useCallback, useContext, useRef, useState } from "react";
import { Modal } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { logger } from "@/Services/Logger/Index";
import { setLocalStorageFilter } from "@/Utils/AppUtils";
import {
  candidateFeedbackFormFields,
  candidateFeedbackFormitialValues,
} from "../../../Assets/Mocks/Candidate.mock";
import { UserContext } from "../../../Context/UserContext";

export default function CandidateFeedback({
  candidate,
  jobid,
  parentSelectedTab,
  childRef,
  filter,
  isStatus,
  setShowFeedbackCandidate,
}) {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(true);
  const fetch = useAuthenticatedFetch();
  const formRef = useRef();
  const { userProfileData } = useContext(UserContext);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        let feedback = {
          feedback: e?.candidateFeedback,
          userName: userProfileData?.firstName,
          userId: userProfileData?._id,
          FeedbackDate: new Date(),
        };
        if (!candidate.candidateFeedback) {
          candidate.candidateFeedback = [];
        }
        candidate.candidateFeedback.push(feedback);
        await fetch.post("/candidate", { candidateFeedback: candidate.candidateFeedback, _id: candidate._id });
        filter ? setLocalStorageFilter(true, parentSelectedTab, childRef, "candidateList") : isStatus(true);
        setShowFeedbackCandidate(false);
        setIsFeedbackModalOpen(false);
      } catch (error) {
        logger.error(error);
      }
    },
    [parentSelectedTab, childRef]
  );

  const handleFeedbackCanceled = useCallback(() => {
    setIsFeedbackModalOpen(false);
  }, []);

  const handleFeedbackConfirmed = useCallback(async () => {
    formRef.current.handleSubmit();
  }, []);

  return (
    <>
      <Modal
        open={isFeedbackModalOpen}
        onClose={handleFeedbackCanceled}
        title="Feedback Candidate"
        primaryAction={{
          content: "Save",
          onAction: handleFeedbackConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleFeedbackCanceled,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={candidateFeedbackFormitialValues}
            formFields={candidateFeedbackFormFields}
            isSave={false}
            noValueChanged={false}
          />
        </Modal.Section>
      </Modal>
    </>
  );
}
