import { getSessionStorageItem, setSessionStorageItem } from "./Index";

export const setLocalStorageFilter = (removeAllFilter, parentSelectedTab, childRef, key) => {
  if (key) {
    const item = getSessionStorageItem(key);
    let { pagination, filter } = item || {};
    filter = !removeAllFilter ? filter?.filter((e) => e?.tabQuery == true) : filter;
    pagination = removeAllFilter ? { page: 1, pageSize: 10 } : pagination;
    if (parentSelectedTab == 0) {
      setSessionStorageItem(key, { pagination });
    } else {
      setSessionStorageItem(key, { pagination, filter });
    }
    childRef?.current && childRef?.current?.fetchData(pagination, filter);
  }
};
