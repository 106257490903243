import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { BlockStack, DataTable, EmptyState, Layout, LegacyCard, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import * as Images from "@/Assets/Index";
import { UserContext } from "../../../Context/UserContext";
import { CommonReminder } from "./CommonReminder";

export const AssessmentReminder = ({ status, id }) => {
  const fetch = useAuthenticatedFetch();
  const { userProfileData } = useContext(UserContext);
  const [rows, setRows] = useState();
  const [setReminder, isSetReminder] = useState(false);
  const [jobData, setJobData] = useState();

  useEffect(() => {
    getOrganizationData();
  }, [setReminder]);

  const getOrganizationData = useCallback(async () => {
    let reminderData;
    if (status) {
      let job = await fetch.get(`/job/${id}`);
      reminderData = job?.data;
      setJobData(job?.data);
    } else {
      let organization = await fetch.get(`/organization/${userProfileData?.currentOrganization?.orgId}`);
      reminderData = organization?.data;
    }
    const reminderKeys = reminderData?.reminder ? Object.keys(reminderData.reminder) : [];
    const formatKey = (key) =>
      key
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel case words
        .replace(/^./, (str) => str.toUpperCase());
    const weekdayValues = reminderKeys.map((key) => reminderData?.reminder[key].Weekday);
    const weekendValues = reminderKeys.map((key) => reminderData?.reminder[key].Weekend);
    const combinedRows = reminderKeys.map((key, index) => [
      formatKey(key),
      `${weekdayValues[index] < 13 ? weekdayValues[index] : weekdayValues[index] - 12} ${
        weekdayValues[index] < 13 ? "AM" : "PM"
      }`,
      `${weekendValues[index] < 13 ? weekendValues[index] : weekendValues[index] - 12} ${
        weekendValues[index] < 13 ? "AM" : "PM"
      }`,
    ]);

    setRows(combinedRows);
  }, [rows]);

  return (
    <Layout sectioned>
      <Layout.Section>
        <BlockStack gap="300">
          <CommonReminder
            status={status ? status : "common"}
            id={id}
            isSetReminder={isSetReminder}
            jobData={jobData}
          ></CommonReminder>
          {rows?.length > 0 ? (
            <LegacyCard>
              <DataTable
                columnContentTypes={["text", "text", "text"]}
                headings={[
                  <Text variant="headingSm" as="h6">
                    Days
                  </Text>,
                  <Text variant="headingSm" as="h6">
                    Weekday
                  </Text>,
                  <Text variant="headingSm" as="h6">
                    Weekend
                  </Text>,
                ]}
                rows={rows?.map((row) => [
                  <div className="feedback-column">{row[0]}</div>,
                  <div className="username-column">{row[1]}</div>,
                  <div className="feedback-date-column">{row[2]}</div>,
                ])}
              />
            </LegacyCard>
          ) : (
            <LegacyCard>
              <EmptyState heading="No Assessment Reminder list Found" image={Images.EmptyReuslt}></EmptyState>
            </LegacyCard>
          )}
        </BlockStack>
      </Layout.Section>
    </Layout>
  );
};
