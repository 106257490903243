import { Link } from "react-router-dom";
import { BlockStack, Divider, Image, InlineStack, Text, TopBar } from "@shopify/polaris";
import { RecruitmentLogo } from "@/Assets/Index";

export function PublicFooter() {
  return (
    <div className="public-footer" style={{ background: "#F6F8FF", color: "fff", padding: "30px" }}>
      <div className="footer page-container">
        <BlockStack gap="200">
          <BlockStack>
            <InlineStack align="space-between">
              <BlockStack gap="200">
                <div className="logo">
                  <Image width={230} height={50} source={RecruitmentLogo}></Image>
                </div>
                <Text as="p" alignment="center" fontWeight="regular">
                  Transforming Talent Search with Smart AI Interviews
                </Text>
              </BlockStack>
              <div style={{ width: "250px" }}>
                <InlineStack gap="500">
                  {/* <BlockStack gap="200">
                    <Text tone="text-inverse" as="p" alignment="center" fontWeight="regular">
                      Contact
                    </Text>
                    <Divider borderColor="border" />

                    <Text tone="text-inverse" as="p" alignment="center" fontWeight="regular">
                      209,Saumya Square near Govardhan Party plot, Thaltej, Ahmedabad
                    </Text>
                    <div className="contect-us" style={{ textAlign: "center", color: "#030750" }}>
                      <Link alignment="center">Contact Us</Link>
                    </div>
                  </BlockStack> */}
                </InlineStack>
              </div>
            </InlineStack>
          </BlockStack>

          <Divider borderColor="border" />

          <BlockStack>
            <InlineStack align="space-between">
              <div style={{ color: "#8484B1" }}>
                <Text as="p" alignment="center" fontWeight="regular">
                  © 2022 Webrex. All rights reserved.
                </Text>
              </div>
              <InlineStack gap="400">
                <Link to="/privacy-policy" style={{ textDecoration: "none", color: "#8484B1" }}>
                  Privacy Policy
                </Link>
                <Link to="/terms-of-service" style={{ textDecoration: "none", color: "#8484B1" }}>
                  Terms of Service
                </Link>
              </InlineStack>
            </InlineStack>
          </BlockStack>
        </BlockStack>
      </div>
    </div>
  );
}
