import React, { useState } from "react";
import { BlockStack, Box, Card, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { forgotPasswordFormFields, forgotPasswordInitialValues } from "@/Assets/Mocks/ForgotPassword.mock";
import CommonForm from "@/Components/Common/CommonForm";

export const ForgotPassword = () => {
  const fetch = useAuthenticatedFetch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    const res = await fetch.post("/reset", e);
    if (res.data) {
      setIsSubmitted(true);
    }
  };

  if (isSubmitted) {
    return (
      <Page fullWidth={true}>
        <BlockStack gap={2} inlineAlign="center">
          <Box className="public-page" width="35%">
            <Card>
              <Text>Please check your email for further instructions.</Text>
            </Card>
          </Box>
        </BlockStack>
      </Page>
    );
  }

  return (
    <Page fullWidth={true}>
      <BlockStack gap="200" inlineAlign="center">
        <Box className="public-page" width="35%">
          <Card>
            <BlockStack gap="300">
              <Text alignment="center" variant="headingXl" color="subdued" fontWeight="bold">
                Account Recovery
              </Text>
              <Card>
                <CommonForm
                  onSubmit={handleSubmit}
                  initialValues={forgotPasswordInitialValues}
                  formFields={forgotPasswordFormFields}
                  isSave={true}
                  label="Submit"
                />
              </Card>
            </BlockStack>
          </Card>
        </Box>
      </BlockStack>
    </Page>
  );
};
