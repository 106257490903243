import { Link } from "react-router-dom";
import { BlockStack, Image, InlineStack, Page, Text } from "@shopify/polaris";
import { RecruitmentLogo } from "@/Assets/Index";

export const HomePageHeader = () => {
  return (
    <div className="home-page home-page-header">
      <Page fullWidth={true}>
        <div
          className="flex-div"
          style={{
            background: "#F1F4FF",
            alignItems: "flex-start",
            display: "block",
            padding: "0px",
          }}
        >
          <div
            className="page-container"
            style={{
              padding: "19px 30px",
              borderBottom: "1px solid #22465F",
              margin: "0px",
              alignItems: "normal",
            }}
          >
            <InlineStack align="space-between">
              <div className="logo">
                <a href="/">
                  <Image width={230} height={50} source={RecruitmentLogo}></Image>
                </a>
              </div>
              <InlineStack gap="300" align="end">
                <div className="login">
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    Login
                  </Link>
                </div>
                <div className="register">
                  <Link to="/signup" style={{ textDecoration: "none" }}>
                    Register
                  </Link>
                </div>
              </InlineStack>
            </InlineStack>
          </div>
        </div>
      </Page>
    </div>
  );
};
