import React, { useCallback, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BlockStack, Card, Icon, Image, InlineStack, Text } from "@shopify/polaris";
import { EmailMajor, LinkMinor } from "@shopify/polaris-icons";
import { LinkdIn, Whatsapp } from "@/Assets/Index";
import { ToastContext } from "@/Context/ToastContext";

export const ShareLink = (props) => {
  const { jobData, stage } = props;
  const location = useLocation();
  const { showToast } = useContext(ToastContext);

  let url = `${process.env.APP_URL + location.pathname}`;
  const sendWhatsapp = () => {
    const whatsappUrl = `https://web.whatsapp.com/send?text=${url}`;
    window.open(whatsappUrl, "_blank");
  };
  const handleClick = () => {
    const linkedinShareUrl = `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${encodeURIComponent(
      url
    )}`;

    window.open(linkedinShareUrl, "_blank");
  };

  const copyUrl = async () => {
    await navigator.clipboard.writeText(url);
    showToast("Link copied to clipboard.");
  };
  const emailSend = async () => {
    const subject = encodeURIComponent(`${jobData?.orgName} : ${jobData?.jobTitle}`);
    const body = encodeURIComponent(`${jobData?.orgName} 
${jobData?.jobTitle}

Check out this link: ${url}`);
    const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = mailtoUrl;
  };
  return (
    <>
      <Card>
        <BlockStack gap={200}>
          <Text variant="headingMd">Share this job:</Text>
          <InlineStack align="start" gap={600} blockAlign="center">
            <a className="shareLink" style={{ cursor: "pointer" }} onClick={() => copyUrl()}>
              <Icon color="subdued" source={LinkMinor} tone="base" />
            </a>
            <a className="shareLink" style={{ cursor: "pointer" }} onClick={() => sendWhatsapp()}>
              <Image width={16} source={Whatsapp} />
            </a>
            <a className="shareLink" style={{ cursor: "pointer" }} onClick={() => handleClick()}>
              <Image width={16} source={LinkdIn} />
            </a>
            <a className="shareLink" style={{ cursor: "pointer" }} onClick={() => emailSend()}>
              <Icon color="subdued" source={EmailMajor} tone="base" />
            </a>
          </InlineStack>
        </BlockStack>
      </Card>
    </>
  );
};
