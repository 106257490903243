import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  IndexTable,
  InlineStack,
  List,
  Modal,
  Text,
} from "@shopify/polaris";
import { ClipboardMinor, DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { get, set } from "mongoose";
import { useAuthenticatedFetch } from "@/Api/Axios";
import {
  aiScreeningInitialValues,
  getAiScreeningFields,
  normalScreeningFields,
  normalScreeningInitialValues,
  stageFields,
  stageInitialValues,
} from "@/Assets/Mocks/StageDetails.mock";
import { getSaveTemplateFields, templateInitialValues } from "@/Assets/Mocks/Template.mock";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import {
  candidatePublicUrlFields,
  candidatePublicUrlInitialValues,
} from "../../../Assets/Mocks/StagePublicUrl.mock";
import { AccessPermission, PageAccessPermission } from "../../../Components/Common/AccessPermission";
import { Template } from "./templates/Template";

export function Stage(props) {
  const [saveTemplateFields, setSaveTemplateFields] = useState([]);
  const fetch = useAuthenticatedFetch();
  const [isStageModal, setIsStageModal] = useState(false);
  const [isChooseTemplateModal, setIsChooseTemplateModal] = useState(false);
  const [isSaveTemplateModal, setIsSaveTemplateModal] = useState(false);
  const [stageFormValues, setStageFormValues] = useState(stageInitialValues);
  const [normalScreeningFormValues, setNormalScreeningFormValues] = useState(normalScreeningInitialValues);
  const [aiScreeningFormFields, setAiScreeningFormFields] = useState(getAiScreeningFields());
  const [normalScreeningFormFields, setNormalScreeningFormFields] = useState(normalScreeningFields);
  const [aiScreeningFormValues, setAiScreeningFormValues] = useState(aiScreeningInitialValues);
  const [saveTemplateFormValues, setSaveTemplateFormValues] = useState(templateInitialValues);
  const [stageToDelete, setStageToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState({});
  const [actionType, setActionType] = useState("");
  const [screeningFormFields, setScreeningFormFields] = useState(normalScreeningFormFields);
  const [screeningFormValues, setScreeningFormValues] = useState(normalScreeningInitialValues);
  const [template, setTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [showTemplateSaveButton, setShowTemplateSaveButton] = useState(true);
  const stageFormRef = useRef();
  const screeningFormRef = useRef();
  const saveTemplateFormRef = useRef();
  const publicUrlFormRef = useRef();
  const childRef = useRef();
  const [isPublicModel, setIsPublicModelOpen] = useState(false);
  const [publicUrlFormValues, SetPublicUrlFormValues] = useState(candidatePublicUrlInitialValues);
  const { showToast } = useContext(ToastContext);
  const [isPublicEdit, setIsPublicEdit] = useState(false);
  const { id } = useParams();
  const [jobData, setJobData] = useState();
  const [backUpStageValue, setBackUpStageValue] = useState({});
  const [noQuestion, setNoQuestion] = useState(false);
  const [saveAllTemplateFields, setSaveAllTemplateFields] = useState([]);
  const [assessmentLevel, setAssessmentLevel] = useState("low");
  const [publicUrl, setPublicUrl] = useState(false);

  const navigate = useNavigate();

  const handleAddStageButtonClick = () => {
    setIsChooseTemplateModal(true);
    setBackUpStageValue({});
  };
  const handleAddStage = useCallback(() => {
    setActionType("add");
    setStageFormValues(stageInitialValues);
    setScreeningFormFields(normalScreeningFields);
    setScreeningFormValues(normalScreeningInitialValues);
    setIsStageModal(true);
  }, [actionType]);

  const handleModalClose = () => {
    setActionType("");
    setSelectedStage({});
    setIsStageModal(false);
    setNoQuestion(false);
    setStageFormValues(stageInitialValues);
    setScreeningFormFields(normalScreeningFields);
    setScreeningFormValues(normalScreeningInitialValues);
  };

  const handleSubmit = async () => {
    if (noQuestion) {
      const isStageValid = stageFormRef.current.isValid;
      if (!isStageValid) return;
      const { values: stageValues } = stageFormRef.current;
      const data = {
        stageDetails: {
          ...stageValues,
        },
        jobId: props.jobId,
      };
      await fetch.post("/stage", data);
    } else {
      const isStageValid = stageFormRef.current.isValid;
      const isScreeningValid = screeningFormRef.current.isValid;
      if (!isStageValid || !isScreeningValid) return;
      const { values: stageValues } = stageFormRef.current;
      const { values: screeningValues } = screeningFormRef.current;
      if (stageValues.stagePreset === "normalScreening" || stageValues.stagePreset === "bothScreening") {
        screeningValues.questionList.forEach((question) => {
          question.questionTime =
            !question?.questionTime || question.questionTime === "" ? 60 : Number(question.questionTime);
          question.answerTime =
            !question?.answerTime || question.answerTime === "" ? 300 : Number(question.answerTime);
        });
      }
      const data = {
        stageDetails: {
          ...stageValues,
          ...(stageValues.stagePreset === "aiScreening" || stageValues.stagePreset === "bothScreening"
            ? {
                totalQuestions: screeningValues.totalQuestions,
                aiAnswerType: screeningValues.aiAnswerType,
                answerTime: screeningValues?.answerTime ? Number(screeningValues?.answerTime) : 300,
                questionTime: screeningValues?.questionTime ? Number(screeningValues?.questionTime) : 60,
                aiQuestionPosition: screeningValues.aiQuestionPosition || "before",
              }
            : {}),
          ...(stageValues.stagePreset === "normalScreening" || stageValues.stagePreset === "bothScreening"
            ? { questionList: screeningValues.questionList }
            : {}),
        },
        jobId: props.jobId,
        ...(actionType === "update" && selectedStage?._id ? { _id: selectedStage._id } : {}),
      };

      const url = template ? "/stageTemplate" : "/stage";
      if (template) {
        if (saveTemplateFormRef?.current?.values?.template == "none") {
          data["stageDetails"]["label"] = saveTemplateFormRef?.current?.values?.label;
          delete data?.jobId;
          delete data?._id;
        } else {
          data["_id"] = saveTemplateFormRef?.current?.values?.template;
        }
      }

      await fetch.post(url, data);
      if (template) {
        showToast(
          saveTemplateFormRef?.current?.values?.template == "none"
            ? "Interview Round Template Created successfully"
            : "Interview Round Template Updated successfully"
        );
        setIsSaveTemplateModal(false);
        getTemplates();
        setTemplate(false);
        setSaveTemplateFormValues(templateInitialValues);
      } else {
        showToast(
          actionType === "add" ? "Interview Round Created successfully" : "Interview Round Updated successfully"
        );
      }
    }
    if (!template) {
      handleModalClose();
    }
    setNoQuestion(false);
    childRef.current.fetchData();
  };

  const onSaveTemplateFormChange = (value) => {
    if (value.template !== "none") {
      let template = saveTemplateFields.filter((item) => item.label !== "Template Title");
      setSaveTemplateFields(template);
    } else {
      setSaveTemplateFields(saveAllTemplateFields);
    }
    setSaveTemplateFormValues((prev) => ({ ...prev, ...value }));
  };

  const onpublicUrlField = (value) => {
    if (value?.publicUrlField?.resume) {
      setPublicUrl(true);
    }
  };

  const saveTemplate = async (template = false) => {
    setTemplate(template);
    await stageFormRef.current.handleSubmit();
    await screeningFormRef.current.handleSubmit();
    await stageFormRef.current.validateForm();
    await screeningFormRef.current.validateForm();
    const isStageValid = stageFormRef.current.isValid;
    const isScreeningValid = screeningFormRef.current.isValid;
    if (!isStageValid || !isScreeningValid) return;
    setIsSaveTemplateModal(true);
  };

  const saveStage = async (template = false) => {
    await stageFormRef.current.handleSubmit();
    await stageFormRef.current.validateForm();
    if (!noQuestion) {
      await screeningFormRef.current.handleSubmit();
      await screeningFormRef.current.validateForm();
    }

    handleSubmit();
  };

  const getTemplates = useCallback(async () => {
    const response = await fetch.get("/stageTemplates");
    const res = await fetch.get(`/job/${id}`);
    setJobData(res.data);
    createRowsData();
    if (response?.data) {
      setTemplates(response.data);
      setSaveTemplateFields(getSaveTemplateFields(response.data));
      setSaveAllTemplateFields(getSaveTemplateFields(response.data));
    }
  }, [jobData, id]);

  useEffect(() => {
    getTemplates();
  }, []);

  const handleRowClick = (row) => {
    navigate(`/stage/${row._id}`);
  };

  const [pagination, setParentPagination] = useState(null);

  const copyToClipboard = async (row) => {
    const url = `${process.env.APP_URL + "/stage/share/" + row._id}`;
    await navigator.clipboard.writeText(url);
    showToast("Link copied to clipboard.");
  };

  const handlePublicUrl = async (row) => {
    if (row?.publicUrlField?.resume) {
      setPublicUrl(true);
    }
    SetPublicUrlFormValues({
      ...candidatePublicUrlInitialValues,
      ...row,
    });
    setIsPublicEdit(row?.publicUrlField ? true : false);
    setIsPublicModelOpen(true);
  };

  const generatePublcUrl = useCallback(async () => {
    if (publicUrlFormRef.current) {
      publicUrlFormRef.current.handleSubmit();
      let publicUrlData = publicUrlFormRef.current.values;
      await generatePublicLink(publicUrlData);
    }
  }, [publicUrlFormRef]);

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const generatePublicLink = async (e) => {
    const Url = `${process.env.APP_URL + "/stage/share/" + e._id}`;
    e["isPublic"] = true;
    e["publicUrl"] = Url;
    await fetch.post("/stage", e);
    childRef.current.fetchData();
    showToast("Sucessfully Generate Public Url");
    setIsPublicModelOpen(false);
    SetPublicUrlFormValues({ ...candidatePublicUrlInitialValues });
  };

  const createRowsData = useCallback(
    (rows) => {
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;
      if (rows?.length <= 0) return [];
      return rows?.map((row, index) => {
        row["type"] = "update";
        return (
          <IndexTable.Row id={row._id} key={row._id} position={index}>
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell>
              {row?.stageDetails?.stageTitle}
              {/* <Link onClick={() => handleRowClick(row)} removeUnderline monochrome>
                {row?.stageDetails?.stageTitle}
              </Link> */}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {row?.stageDetails?.stagePreset === "normalScreening" && "Normal Screening"}
              {row?.stageDetails?.stagePreset === "aiScreening" && "Ai Screening"}
              {row?.stageDetails?.stagePreset === "bothScreening" && "Ai + Normal Screening"}
              {row?.stageDetails?.stagePreset === "noQuestion" && "No Questions"}
            </IndexTable.Cell>
            {/* <IndexTable.Cell>{row?.stageDetails?.questionList?.length}</IndexTable.Cell> */}
            <IndexTable.Cell>{formatTimeAgo(row?.created)}</IndexTable.Cell>
            <IndexTable.Cell>{row?.userdetail[0]?.firstName}</IndexTable.Cell>

            <IndexTable.Cell>
              {row?.isPublic || row?.publicUrl ? (
                <InlineStack gap={200}>
                  <Badge tone="success">Public</Badge>
                  {jobData && jobData?.isActive === true && (
                    <Button
                      plain
                      monochrome
                      icon={EditMinor}
                      onClick={() => {
                        handlePublicUrl(row);
                      }}
                    />
                  )}
                  <a
                    style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                    onClick={() => copyToClipboard(row)}
                  >
                    <Icon source={ClipboardMinor} />
                  </a>
                </InlineStack>
              ) : (
                <Button
                  variant="primary"
                  disabled={!jobData?.isActive}
                  onClick={() => {
                    handlePublicUrl(row);
                  }}
                >
                  Public
                </Button>
              )}
            </IndexTable.Cell>

            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  <AccessPermission access={["manageJobs", "manageCandidate"]}>
                    {jobData?.isActive && (
                      <Button
                        plain
                        monochrome
                        icon={EditMinor}
                        onClick={() => {
                          handleEditStage(row);
                          setShowTemplateSaveButton(true);
                        }}
                      />
                    )}
                    <Button
                      plain
                      monochrome
                      icon={DeleteMinor}
                      onClick={() => {
                        handleDelete(row);
                      }}
                    />
                  </AccessPermission>
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [childRef, pagination, jobData]
  );

  const onStageFormChange = (value) => {
    setAssessmentLevel(value?.assessmentLevel);
    setBackUpStageValue((prev) => {
      return { ...prev, [stageFormValues.stagePreset]: screeningFormValues };
    });
    setNoQuestion(value?.stagePreset === "noQuestion" ? true : false);
    setStageFormValues((prev) => ({ ...prev, ...value }));
    // Check if stagePreset is changed
    if (value.stagePreset !== stageFormValues.stagePreset) {
      if (value.stagePreset === "aiScreening") {
        setScreeningFormFields(getAiScreeningFields());
        setScreeningFormValues({ ...aiScreeningFormValues, ...backUpStageValue.aiScreening });
      } else if (value.stagePreset === "normalScreening") {
        setScreeningFormFields(normalScreeningFormFields);
        setScreeningFormValues({ ...normalScreeningFormValues, ...backUpStageValue.normalScreening });
      } else if (value.stagePreset === "bothScreening") {
        setScreeningFormFields([...getAiScreeningFields(true), ...normalScreeningFormFields]);
        setScreeningFormValues({
          ...aiScreeningFormValues,
          ...normalScreeningFormValues,
          ...backUpStageValue.bothScreening,
        });
      }
    }
  };

  const onScreeningFormChange = (value) => {
    if (stageFormValues.stagePreset != "aiScreening") {
      value.questionList.map((item) => {
        if (
          item.answerType === "number" ||
          item.questionType === "video" ||
          item.answerType === "checkbox" ||
          item.answerType === "radio"
        ) {
          item["aiEvaluation"] = false;
        }
        return item;
      });
    }
    setScreeningFormValues((prev) => ({ ...prev, ...value }));
  };

  const handleEditStage = useCallback(
    async (row) => {
      setActionType(row.type);
      if (row?._id) setSelectedStage(row);
      const data = { ...stageFormValues, ...screeningFormValues, ...row?.stageDetails };
      onStageFormChange(data);
      setStageFormValues(data);
      setScreeningFormValues(data);
      setIsStageModal(true);
    },
    [stageFormValues, screeningFormValues]
  );

  const handleDelete = async (row) => {
    setStageToDelete(row);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteCanceled = () => {
    setStageToDelete(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteConfirmed = useCallback(async () => {
    try {
      if (stageToDelete) {
        await fetch.delete(`/stage/${stageToDelete._id}`);
        childRef.current.fetchData();
        showToast("Interview Round Delete successfully");
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  }, [stageToDelete, childRef]);

  const handleFormSubmit = useCallback((value) => {}, []);
  const onStageFormSubmit = useCallback((value) => {}, []);
  const handleSaveTemplateCancle = () => {
    setTemplate(false);
    setIsSaveTemplateModal(false);
  };

  const handlePublicUrlCancle = () => {
    setIsPublicModelOpen(false);
    setPublicUrl(false);
  };

  return (
    <PageAccessPermission access={["manageJobs", "viewJobs"]}>
      <BlockStack gap="200">
        <InlineStack align="flex-end" blockAlign="center">
          <AccessPermission access={["manageJobs"]}>
            {/* <Button size="large" onClick={handleAddStage}> */}
            <Button size="large" disabled={!jobData?.isActive} onClick={handleAddStageButtonClick}>
              Add Interview Round
            </Button>
          </AccessPermission>
        </InlineStack>
        {jobData && (
          <CommonTable
            title="Job Interview Interview Rounds"
            selectable={false}
            url={`/stages/${props.jobId}`}
            rowsData={createRowsData}
            headings={[
              { title: "No" },
              { title: "Title" },
              { title: "Type" },
              { title: "Created At" },
              { title: "Created By" },
              { title: "Public" },
              // { title: "Questions" },
              { title: "Action" },
            ]}
            ref={childRef}
            columnContentTypes={["string", "Action"]}
            isAdd={false}
            setParentPagination={setParentPagination}
          />
        )}
      </BlockStack>
      <BannerModal
        instant={true}
        open={isStageModal}
        // onClose={false}
        title={actionType === "update" ? "Edit Interview Round" : "Add Interview Round"}
        primaryAction={{
          content: "Save",
          onAction: saveStage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalClose,
          },
          showTemplateSaveButton && !noQuestion
            ? {
                content: "Save template",
                onAction: () => saveTemplate(true),
              }
            : null,
        ].filter((item) => item !== null)}
        children={
          <BlockStack gap={400}>
            <style
              dangerouslySetInnerHTML={{
                __html: `.Polaris-Button--iconOnly:is(.Polaris-Button--variantTertiary, .Polaris-Button--variantPlain, .Polaris-Button--variantMonochromePlain):not(.Polaris-Button--toneCritical){
                  display:none;
                }`,
              }}
            />
            <CommonForm
              formRef={stageFormRef}
              initialValues={stageFormValues}
              formFields={stageFields(assessmentLevel)}
              onFormChange={onStageFormChange}
              isSave={false}
              onSubmit={handleFormSubmit}
              noValueChanged={false}
              enableReinitialize={true}
            />
            {!noQuestion ? (
              <CommonForm
                formRef={screeningFormRef}
                initialValues={screeningFormValues}
                formFields={screeningFormFields}
                isSave={false}
                onFormChange={onScreeningFormChange}
                onSubmit={handleFormSubmit}
                noValueChanged={false}
                enableReinitialize={true}
              />
            ) : (
              <Card>
                <BlockStack gap={200}>
                  <Text variant="bodyLg" fontWeight="bold">
                    This will help when you want to take candidate normal information. don’t want to send any
                    assessment or question.{" "}
                  </Text>
                  <div className="instruction">
                    <List>
                      <List.Item>Create No Question Type.</List.Item>

                      <List.Item>Make it public and select information you want.</List.Item>
                      <List.Item>Add public link to your job post.</List.Item>
                      <List.Item>You will start getting responses automatically.</List.Item>
                    </List>
                  </div>
                </BlockStack>
              </Card>
            )}
          </BlockStack>
        }
        size={"large"}
      />
      <Modal
        open={isDeleteModalOpen}
        onClose={handleDeleteCanceled}
        title="Confirm Delete"
        primaryAction={{
          content: "Delete",
          onAction: handleDeleteConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleDeleteCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to delete this Interview Round?</Text>
        </Modal.Section>
      </Modal>

      <Modal
        open={isSaveTemplateModal}
        onClose={handleSaveTemplateCancle}
        title="Save Template"
        primaryAction={{
          content: "Save",
          onAction: saveStage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleSaveTemplateCancle,
          },
        ]}
      >
        <Box padding="300">
          <CommonForm
            formRef={saveTemplateFormRef}
            onFormChange={onSaveTemplateFormChange}
            initialValues={saveTemplateFormValues}
            formFields={saveTemplateFields}
            isSave={false}
          />
        </Box>
      </Modal>

      <Modal
        open={isPublicModel}
        onClose={handlePublicUrlCancle}
        title="Create Public Url"
        primaryAction={{
          content: isPublicEdit ? "Update" : "Save",
          onAction: generatePublcUrl,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handlePublicUrlCancle,
          },
        ]}
      >
        <Box padding="300">
          <div className="publicUrl">
            <CommonForm
              formRef={publicUrlFormRef}
              initialValues={publicUrlFormValues}
              onSubmit={generatePublicLink}
              formFields={candidatePublicUrlFields(publicUrl)}
              onFormChange={onpublicUrlField}
              isSave={false}
            />
          </div>
        </Box>
      </Modal>

      <Template
        isChooseTemplateModal={isChooseTemplateModal}
        handleModalClose={() => {
          setIsChooseTemplateModal(false), setTemplate(false);
        }}
        handleEditStage={handleEditStage}
        handleAddStage={handleAddStage}
        templates={templates}
        setShowTemplateSaveButton={setShowTemplateSaveButton}
      />
    </PageAccessPermission>
  );
}
