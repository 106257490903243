import React, { useCallback, useState } from "react";
import { Modal, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { setLocalStorageFilter } from "@/Utils/AppUtils";

export default function ShortListCndidate({
  candidate,
  jobid,
  parentSelectedTab,
  childRef,
  filter,
  isStatus,
  setShowShortListCandidate,
}) {
  const [isShortlistModalOpen, setIsShortlistModalOpen] = useState(true);
  const fetch = useAuthenticatedFetch();

  const handleShortlistConfirmed = useCallback(async () => {
    let data = {
      _id: candidate?._id,
      isShortList: true,
      reject: false,
      email: candidate?.email,
      jobId: candidate?.jobId,
    };
    await fetch.post("/candidate", {
      ...data,
    });
    filter ? setLocalStorageFilter(true, parentSelectedTab, childRef, "candidateList") : isStatus(true);
    setShowShortListCandidate(false);
    setIsShortlistModalOpen(false);
  }, [parentSelectedTab, childRef]);

  const handleShortlistCanceled = useCallback(() => {
    setIsShortlistModalOpen(false);
  }, []);

  return (
    <>
      <Modal
        open={isShortlistModalOpen}
        onClose={handleShortlistCanceled}
        title="Shortlist Candidate"
        primaryAction={{
          content: "Confirm",
          onAction: handleShortlistConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleShortlistCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to Shortlist this candidate?</Text>
        </Modal.Section>
      </Modal>
    </>
  );
}
