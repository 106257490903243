export const activePlanformFields = [
  {
    id: "activePlanName",
    name: "planName",
    labelPosition: "right",
    validated: false,
    type: "text",
    placeholder: "Add Plan Name",
  },
  {
    id: "planPrice",
    name: "planPrice",
    labelPosition: "right",
    validated: false,
    type: "text",
    placeholder: "Add Plan Price",
  },
];
export const activePlaninitialValues = {
  planName: "",
  planPrice: "",
};

export const activeOrganizationPlanformFields = [
  {
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        id: "planId",
        name: "planId",
        label: "Plan",
        validated: true,
        type: "select",
        errormsg: "Plan is required",
        options: [
          {
            label: "Select type",
            value: "",
          },
          {
            label: "Monthly",
            value: "monthly",
          },
          {
            label: "Yearly",
            value: "yearly",
          },
        ],
      },
      {
        id: "planType",
        name: "planType",
        label: "Plan Type",
        validated: true,
        type: "select",
        errormsg: "Plan Type is required",
        options: [
          {
            label: "Select type",
            value: "",
          },
          {
            label: "Free",
            value: "Free",
          },
          {
            label: "Standard",
            value: "Standard",
          },
          {
            label: "Professional",
            value: "Professional",
          },
          {
            label: "Enterprise",
            value: "Enterprise",
          },
        ],
      },
      {
        id: "jobCredit",
        name: "jobCredit",
        label: "Job Credit",
        type: "number",
        errormsg: "Job Credit is required",
        validated: true,
      },
      {
        id: "userCredit",
        name: "userCredit",
        label: "User Credit",
        type: "number",
        errormsg: "User Credit is required",
        validated: true,
      },
      {
        id: "jobExpired",
        name: "jobExpired",
        label: "Job Expired",
        type: "number",
        errormsg: "User Credit is required",
        validated: true,
      },
      {
        id: "price",
        name: "price",
        label: "Plan Price",
        type: "number",
        errormsg: "Plan Price is required",
        validated: true,
      },
    ],
  },
];
export const activeOrganizationPlaninitialValues = {
  planId: "",
  planType: "",
  jobCredit: "",
  userCredit: "",
  price: "",
};
