import React, { useCallback, useContext, useState } from "react";

export default function ProgressCircle({
  score,
  width = "150px",
  border = "20px",
  fontSize = "10px",
  color,
  progress,
}) {
  return (
    // here is reference https://www.freecodecamp.org/news/css-only-pie-chart/
    <div className="ProgressCircle">
      <div className="Circle-Box" style={{ height: width, width: width }}>
        <div
          className="pie animate"
          style={{
            "--p": progress ? score * 10 : score,
            "--c": !color
              ? progress
                ? score > 7
                  ? "rgba(0, 122, 92, 1)"
                  : score > 4
                  ? "rgba(183, 126, 11, 1)"
                  : "rgba(197, 40, 12, 1)"
                : score > 50
                ? "rgba(0, 122, 92, 1)"
                : score > 25
                ? "rgba(183, 126, 11, 1)"
                : "rgba(197, 40, 12, 1)"
              : color,
            "--w": width,
            "--b": border,
            "--f": fontSize,
          }}
        >
          {score}%
        </div>
        <div
          className="pie full-circle"
          style={{
            "--p": 100,
            "--c": "#D9D9D9",
            "--w": width,
            "--b": border,
            "--f": fontSize,
          }}
        ></div>
      </div>
    </div>
  );
}
