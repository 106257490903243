import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Banner, Box, Button, Page } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { formFields, initialValues } from "@/Assets/Mocks/ResendVerification.js";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";

export const VerifyToken = () => {
  const verificationToken = new URLSearchParams(window.location.search).get("token");
  const email = new URLSearchParams(window.location.search).get("email");
  const fetch = useAuthenticatedFetch();
  const { showToast } = useContext(ToastContext);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    const checkVerification = async () => {
      const res = await fetch.get(`/verify/${verificationToken}/${email}`);
      setFormValues(email ? { email: email } : initialValues);
      setLoading(false);
      if (res.data?.verified) {
        setVerificationSuccess(true);
      } else {
        setVerificationSuccess(false);
      }
    };
    checkVerification();
  }, []);

  const handleResendVerification = async () => {
    const res = await fetch.post("/resend", { email: email });
    if (res.data) {
      showToast("Verification mail send sucessfully");
    } else {
      showToast("Try Again!");
    }
  };

  if (verificationSuccess) {
    return (
      <div style={{ minHeight: "520px", marginTop: "30px" }}>
        <Page fullWidth={false} style={{ height: "500px" }}>
          <Box paddingBlockStart="5">
            <Banner tone="info" title="Verification Successful">
              <Link to="/login" style={{ textDecoration: "none" }}>
                Login
              </Link>
            </Banner>
          </Box>
        </Page>
      </div>
    );
  }

  if (!verificationSuccess && !loading) {
    return (
      <div style={{ minHeight: "520px", marginTop: "30px" }}>
        <Page fullWidth={true} style={{ height: "500px" }}>
          <Box paddingBlockStart="5">
            <Banner tone="critical" title="Verification Failed">
              <CommonForm
                onSubmit={handleResendVerification}
                initialValues={formValues}
                formFields={formFields}
                isSave={true}
                label="Send Verification Email Again"
                buttonVariant="primary"
                noValueChanged={false}
              />
            </Banner>
          </Box>
        </Page>
      </div>
    );
  }
};
