import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  BlockStack,
  Button,
  Card,
  DropZone,
  Icon,
  InlineError,
  InlineStack,
  Text,
  Thumbnail,
} from "@shopify/polaris";
import {
  CircleCancelMajor,
  CircleCancelMinor,
  CircleDownMajor,
  DeleteMinor,
  FileMinor,
} from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { getValueFromNestedObject } from "@/Utils/Index";
import { UserContext } from "../../Context/UserContext";
import { removeFileFromServer, uploadFilesToServer } from "./../../Utils/Utils";

export default function FileUpload(props) {
  const fetch = useAuthenticatedFetch();
  const {
    form: { values, setFieldValue, errors, validateForm },
    field: { name, label, showTotal = false },
  } = props;
  const { type, ...restFieldProps } = props.field;
  const [files, setFiles] = useState(
    Array.isArray(props.value) ? props.value : props.value != undefined ? [props.value] : [] || []
  );
  const [loading, setLoading] = useState(false);
  const { userProfileData } = useContext(UserContext);

  useEffect(() => {
    if (!props.field.allowMultiple) {
      if (files.length > 0) {
        setFieldValue(name, files[0]);
      } else {
        setFieldValue(name, files);
      }
    } else {
      setFieldValue(name, files);
    }
    validateForm(values);
  }, [files]);

  const handleDrop = useCallback(
    async (_dropFiles, acceptedFiles, _rejectedFiles) => {
      setLoading(true);
      const data = [];
      acceptedFiles.forEach((value) => {
        data.push({
          file: value,
          fileName: value.name,
        });
      });

      const uploadedFiles = await uploadFilesToServer(fetch, data);

      const newFiles = [...files, ...uploadedFiles].filter((file) => file?.assetUrl?.length > 0);

      setFiles(newFiles);

      setLoading(false);
    },
    [files]
  );

  const handleRemoveFile = async (file, index) => {
    //Remove file from server
    if (file?._id) await removeFileFromServer(fetch, file?._id);

    //Remove file from state
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleDownloadFile = (file) => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = `${process.env.APP_URL}/uploads/${file.assetUrl}`;
    link.download = ""; // Set the download attribute to trigger the download

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <BlockStack gap="100">
      <InlineStack gap={100} align="start" blockAlign="center">
        <Text as="p">{label}</Text>
        {props.requiredIndicator && <Text tone="critical">*</Text>}
      </InlineStack>
      <BlockStack gap={100}>
        <div className="fileUpload" style={{ width: "100%" }}>
          <BlockStack>
            {files.map((file, index) => (
              <Card padding="200" key={index}>
                <div className="file-upload-preview file-upload-icon">
                  {file?.assetType?.indexOf("image") > -1 && (
                    <Thumbnail size="large" source={`${process.env.APP_URL}/uploads/${file.assetUrl}`} />
                  )}
                  {file?.assetType?.indexOf("application") > -1 && (
                    <div
                      className="file-icon"
                      style={{
                        height: "18px",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <InlineStack blockAlign="center" gap={200}>
                        <Icon size source={FileMinor} />
                        <Text>{file?.assetName}</Text>
                        <div className="remove-image" onClick={() => handleRemoveFile(file, index)}>
                          <Icon source={DeleteMinor} tone="base" />
                        </div>
                      </InlineStack>
                    </div>
                  )}
                </div>
              </Card>
            ))}
          </BlockStack>
        </div>
        {props.field.allowMultiple ? (
          <InlineStack align="space-between">
            <DropZone {...restFieldProps} type={restFieldProps.fileType} label="" onDrop={handleDrop}>
              <DropZone.FileUpload type={restFieldProps.fileType} />
            </DropZone>
            {files?.length > 0 && showTotal && (
              <Text fontWeight="bold" tone="subdued">
                Files Uploaded: {files?.length}
              </Text>
            )}
          </InlineStack>
        ) : (
          files.length === 0 && (
            <div className="file-upload" style={{ width: "100%" }}>
              <DropZone {...restFieldProps} label="" type={restFieldProps.fileType} onDrop={handleDrop}>
                <DropZone.FileUpload />
              </DropZone>
            </div>
          )
        )}
      </BlockStack>
      <InlineError message={errors[name]} />
      {props.helpText && props.helpText.length > 0 && (
        <Text as="span" variant="bodySm" tone="subdued">
          {props.helpText}
        </Text>
      )}
    </BlockStack>
  );
}
