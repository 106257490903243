import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeLocalStorageItem } from "@/Utils/Index";

const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    setIsAuthenticated(true);
  };
  const logout = () => {
    removeLocalStorageItem("authorization");
    setIsAuthenticated(false);
    navigate("/login");
  };

  return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
