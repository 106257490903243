import React, { useCallback, useContext, useRef, useState } from "react";
import { Button, Modal, Tooltip } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { sendAssessmentFields, sendAssessmentInitialValues } from "../../../Assets/Mocks/Candidate.mock";

export default function SendAssessment({
  candidate,
  setIsSendingAssessment,
  isSendingAssessment,
  childRef,
  stage,
  setIsStatus,
}) {
  const { showToast } = useContext(ToastContext);
  const fetch = useAuthenticatedFetch();
  const [isSendAssessment, setIsSendAssessment] = useState(false);
  const formRef = useRef();

  const sendAssessment = useCallback(async (e, stages) => {
    setIsSendAssessment(true);
  }, []);

  const handleSenAssessmentConfirmed = useCallback(async () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const handleSubmit = useCallback(async (e) => {
    if (candidate?._id) {
      setIsSendingAssessment(true);
      await fetch.post("/stage/sendAssessment", {
        jobId: candidate?.jobId,
        candidateId: candidate?._id,
        stageId: stage?._id,
        sendAssessmentType: e,
      });
      setIsSendingAssessment(false);
      setIsStatus ? setIsStatus(true) : "";
      childRef?.current?.fetchData();
      showToast("Assessment sent successfully");
    }
  }, []);

  const handleSendAssessmentCanceled = useCallback(() => {
    setIsSendAssessment(false);
  }, []);

  return (
    <>
      {!candidate?.mobile && !candidate?.email ? (
        <Tooltip content={"Please provide either an email address or mobile number to send the assessment."}>
          <Button
            primary
            onClick={() => {
              sendAssessment(candidate, "current");
            }}
            disabled={isSendingAssessment || (!candidate?.mobile && !candidate?.email)}
          >
            Send Assessment
          </Button>
        </Tooltip>
      ) : (
        <Button
          primary
          onClick={() => {
            sendAssessment(candidate, "current");
          }}
          disabled={isSendingAssessment}
        >
          Send Assessment
        </Button>
      )}
      <Modal
        open={isSendAssessment}
        onClose={handleSendAssessmentCanceled}
        title="Send Assessment Method"
        primaryAction={{
          content: "Send",
          onAction: handleSenAssessmentConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleSendAssessmentCanceled,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={sendAssessmentInitialValues}
            formFields={sendAssessmentFields}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
    </>
  );
}
