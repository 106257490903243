export const resetPasswordFormFields = [
  {
    id: "password",
    name: "password",
    label: "Password",
    validated: true,
    type: "password",
    errormsg: "Password is required",
  },
  {
    id: "confirmPassword",
    name: "confirmPassword",
    label: "Confirm Password",
    validated: true,
    type: "password",
    errormsg: "Confirm Password is required",
  },
];

export const resetPasswordInitialValues = {
  password: "",
  confirmPassword: "",
};
