import React, { Suspense, lazy, useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import i18n from "i18next";
import "@/AppStyle.scss";
import FallbackUIComponent from "@/Components/Common/FallbackUIComponent";
import { ReviewModalContextProvider } from "@/Context/ReviewContext";
import { ContextProviderList, PolarisProvider } from "@/Providers/Index";
import { ErrorBoundary } from "@/Services/Logger/Sentry";
import "@/Style.scss";
import { getSessionStorageItem, isAdmin } from "@/Utils/Index";
import { AuthContextProvider } from "../Context/AuthContext";
import { StopRecordingProvider } from "../Context/StopRecordingContext";
import { UserContextProvider } from "../Context/UserContext";
import Routes from "./Routes";

const CustomerlyUpdate = lazy(() => import("@/Components/Common/CustomerlyUpdate"));
const initialLanguage = getSessionStorageItem("appLanguage") || "en";

export default function App() {
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    if (process.env.ENV === "prod" && process.env.HOTJAR_TRACKING_ID && !isAdmin()) {
      Hotjar.init(process.env.HOTJAR_TRACKING_ID, process.env.HOTJAR_VERSION);
    }

    const timer = setTimeout(() => {
      setShowChat(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    import(`@/LanguageJS/${initialLanguage}.js`)
      .then((data) => {
        i18n.use(initReactI18next).init({
          lng: initialLanguage,
          fallbackLng: "en",
          interpolation: {
            escapeValue: false,
          },
          resources: {
            [initialLanguage]: {
              translation: data.default,
            },
          },
        });
      })
      .catch((error) => {
        console.error("Error loading initial language file:", error);
        i18n.use(initReactI18next).init({
          lng: "en",
          fallbackLng: "en",
          interpolation: {
            escapeValue: false,
          },
        });
      });
  }, []);

  return (
    <ErrorBoundary fallback={<FallbackUIComponent />}>
      <PolarisProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <StopRecordingProvider>
              <ContextProviderList>
                <UserContextProvider>
                  <ReviewModalContextProvider>
                    <Routes />
                    <Suspense>{showChat && process.env.CSTOMERLY_WEBSITE_ID && <CustomerlyUpdate />}</Suspense>
                  </ReviewModalContextProvider>
                </UserContextProvider>
              </ContextProviderList>
            </StopRecordingProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </PolarisProvider>
    </ErrorBoundary>
  );
}
