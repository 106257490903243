import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useNavigate, useParams } from "react-router-dom";
import { BlockStack, Button, Card, Divider, Image, InlineGrid, InlineStack, Page, Text } from "@shopify/polaris";
import { isEqual } from "lodash";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { resumeFromObject, resumeTemplate } from "../../../Assets/Mocks/Resume.mock";
import { Template1 } from "./ResumeTemplates/Template1/Template1.jsx";
import { Template2 } from "./ResumeTemplates/Template2/Template2.jsx";
import { Template3 } from "./ResumeTemplates/Template3/Template3.jsx";
import { Template4 } from "./ResumeTemplates/Template4/Template4.jsx";

export const ResumePreview = () => {
  const [resumeData, setResumeData] = useState();
  const fetch = useAuthenticatedFetch();
  const { id } = useParams();
  const formRef = useRef();
  const [formsData, setFormsData] = useState(resumeFromObject);
  const [initialFormsData, setInitialFormsData] = useState(resumeFromObject);
  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const [isResumeTemplateModalOpen, setResumeTemplateModalOpen] = useState(false);
  const [isTemplate, setTemplate] = useState();
  const { showToast } = useContext(ToastContext);

  const templateComponents = {
    Template1: Template1,
    Template2: Template2,
    Template3: Template3,
    Template4: Template4,
  };

  const TemplateComponent = templateComponents[isTemplate];

  useEffect(() => {
    getResumeData();
    document.querySelector(".Polaris-Navigation").classList.toggle("hidden");
  }, []);
  const updateFormsAndSubmit = async (updatedFormsData, formKey, form) => {
    Object.keys(updatedFormsData).forEach((key) => {
      if (key !== formKey) {
        updatedFormsData[key].isOpen = false;
      }
    });

    updatedFormsData[formKey] = { ...form, isOpen: true };
    setTimeout(() => {
      submitForm();
    }, 700);
  };

  const downloadResume = async () => {
    window.print();
    // let reqierdField,
    //   isFormValid = true;
    // const updatedFormsData = { ...formsData };
    // for (const formKey in resumeFromObject) {
    //   const form = resumeFromObject[formKey];
    //   for (const field of form.formField) {
    //     if (field.subfields) {
    //       for (const subField of field?.subfields) {
    //         if (subField?.subfields) {
    //           for (const subFields of subField?.subfields) {
    //             if (subFields.validated) {
    //               resumeData[formKey].forEach((value, index) => {
    //                 reqierdField = value[subFields.name];
    //                 if (!reqierdField) {
    //                   updateFormsAndSubmit(updatedFormsData, formKey, form);
    //                   isFormValid = false;
    //                   return;
    //                 }
    //               });
    //             }
    //           }
    //         }
    //         if (subField.validated) {
    //           if (formKey === "ProfessionalSummary" || formKey === "PersonalInformation") {
    //             reqierdField = resumeData[formKey][subField.name];
    //             if (!reqierdField) {
    //               updateFormsAndSubmit(updatedFormsData, formKey, form);
    //               isFormValid = false;
    //               return;
    //             }
    //           } else {
    //             resumeData[formKey].forEach((value, index) => {
    //               reqierdField = value[subField.name];
    //               if (!reqierdField) {
    //                 updateFormsAndSubmit(updatedFormsData, formKey, form);
    //                 isFormValid = false;
    //                 return;
    //               }
    //             });
    //           }
    //         }
    //       }
    //     }
    //   }
    //   if (!isFormValid) break;
    // }

    // if (isFormValid) {
    //   window.print();
    // } else {
    //   setFormsData(updatedFormsData);
    // }
  };

  const getResumeData = useCallback(async () => {
    const res = await fetch.get(`/resume/${id}`);
    setTemplate(res?.data?.Template);
    setResumeData(res.data);
    setInitialFormsData(res.data);
  }, [id, resumeData, initialFormsData]);

  const handleRowClick = (value) => {
    if (isEqual(resumeData, initialFormsData)) {
      setFormsData((prevFormsData) => {
        const updatedFormsData = {};
        for (const key in prevFormsData) {
          if (Object.hasOwnProperty.call(prevFormsData, key)) {
            const isOpen = prevFormsData[key].title === value.title;
            updatedFormsData[key] = { ...prevFormsData[key], isOpen };
            const awardElement = document.getElementById(value.id);
            if (awardElement) {
              const resumePreviewSection = document.querySelector(".resumePreview");
              if (resumePreviewSection) {
                const awardOffsetTop = awardElement.offsetTop;
                const windowHeight = window.innerHeight;
                const scrollTo = awardOffsetTop - (windowHeight - awardElement.clientHeight) / 2;
                resumePreviewSection.scrollTo({
                  top: scrollTo,
                  behavior: "smooth",
                });
              }
            }
          }
        }
        return updatedFormsData;
      });
    } else {
      showToast("Save your resumedata before proceeding.");
    }
  };

  const handleFormChange = useCallback(
    (e) => {
      setResumeData(e);
    },
    [formsData]
  );

  const submitForm = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const getForm = useCallback(
    (value) => {
      return (
        <div className={`${value.isOpen ? "accordian-form" : ""}`}>
          <button className={`accordion ${value.isOpen ? "is-open" : ""}`} onClick={() => handleRowClick(value)}>
            {value?.title}
          </button>
          <div className={`accordion-content ${value.isOpen ? "open" : ""}`}>
            {value.isOpen && resumeData && (
              <div>
                <CommonForm
                  formFields={value.formField}
                  isSave={false}
                  onSubmit={handleSubmit}
                  formRef={formRef}
                  onFormChange={handleFormChange}
                  initialValues={resumeData ? resumeData : value?.initialValue}
                  noValueChanged={false}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0px" }}>
                  <Button variant="primary" onClick={submitForm}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
    [resumeData]
  );

  const handleSubmit = useCallback(async (values) => {
    let res = await fetch.post(`/resume?id=${id}`, values);
    setInitialFormsData(res);
    setResumeData(res);
    showToast("update successfully");
  }, []);

  const handleBackClick = useCallback(() => {
    navigate(`/resume`);
    document.querySelector(".Polaris-Navigation").classList.remove("hidden");
  }, []);

  const closeResumeTemplateModal = () => {
    setResumeTemplateModalOpen(false);
  };

  const handleFormModal = useCallback(async () => {
    setResumeTemplateModalOpen(true);
  }, [isResumeTemplateModalOpen]);

  const handleImage = useCallback(
    async (value) => {
      setTemplate(value.type);
    },
    [isTemplate]
  );

  const handleResumeTemplate = useCallback(async () => {
    if (isTemplate) {
      await fetch.post(`/resume?id=${id}`, { Template: isTemplate });
      setResumeTemplateModalOpen(false);
    }
  }, [isTemplate]);

  const infoCard = (value) => {
    return (
      <div>
        <InlineStack gap="400" align="center">
          {value.Templates.map((template, index) => (
            <img
              key={index}
              src={template.image}
              alt={`Template ${index + 1}`}
              className="template-image"
              onClick={() => {
                handleImage(template);
              }}
            />
          ))}
        </InlineStack>
      </div>
    );
  };

  return (
    <div className="Resume-preview">
      <FullScreen handle={handle}>
        <Page
          fullWidth={true}
          title="Back"
          secondaryActions={
            <InlineStack align="end" gap="400">
              <Button size="large" onClick={handleFormModal}>
                Select Resume Template
              </Button>
              <Button size="large" onClick={downloadResume}>
                Download Resume
              </Button>
            </InlineStack>
          }
          backAction={{ content: "Back", onAction: handleBackClick }}
        >
          <InlineGrid gap="200" columns={2}>
            <div className="resumeForm">
              <Card>
                <BlockStack>
                  <Text variant="headingXl">Resume Data</Text>
                  <br />
                  {Object.keys(formsData).map((key, index) => (
                    <div key={key}>
                      <div>{getForm(formsData[key])}</div>
                      <Divider />
                    </div>
                  ))}
                </BlockStack>
              </Card>
            </div>
            <div className="resumePreview">
              <Card className="form">
                <BlockStack gap="400">
                  {resumeData && TemplateComponent && (
                    <div className="preview" style={{ padding: "10px" }}>
                      <TemplateComponent resumeData={resumeData}></TemplateComponent>
                    </div>
                  )}
                </BlockStack>
              </Card>
            </div>
          </InlineGrid>
        </Page>
        <BannerModal
          size="large"
          open={isResumeTemplateModalOpen}
          onClose={closeResumeTemplateModal}
          title={"Resume Templates"}
          primaryAction={{
            content: "Select",
            onAction: handleResumeTemplate,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: closeResumeTemplateModal,
            },
          ]}
          children={<BlockStack gap="400">{infoCard(resumeTemplate)}</BlockStack>}
        />
      </FullScreen>
      {resumeData && TemplateComponent && (
        <div className="ispreview">
          <TemplateComponent resumeData={resumeData}></TemplateComponent>
        </div>
      )}
    </div>
  );
};
