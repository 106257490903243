import React from "react";
import { BlockStack, Image, InlineStack, Page, Text } from "@shopify/polaris";
import { WrongIcon } from "@/Assets/Index";

export const DeactivePage = () => {
  return (
    <Page>
      <BlockStack align="center" gap="200">
        <InlineStack gap="100" align="center">
          <Image width={50} source={WrongIcon} />
        </InlineStack>
        <div className="assessment-complate">
          <Text variant="headingLg" alignment="center">
            This job is no longer active.{" "}
          </Text>
          <br />
          <Text variant="bodyMd" alignment="center">
            This position is currently inactive. Please revisit this listing for future availability.
          </Text>
        </div>
      </BlockStack>
    </Page>
  );
};
