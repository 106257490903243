import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import pdfToText from "react-pdftotext";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  IndexTable,
  InlineStack,
  Modal,
  Page,
  Spinner,
  Text,
} from "@shopify/polaris";
import { DeleteMinor, EditMinor, ViewMinor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { ToastContext } from "@/Context/ToastContext";
import { UserContext } from "../../../Context/UserContext";

export const ResumeFormatter = () => {
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const fetch = useAuthenticatedFetch();
  const [resumeData, setResumeData] = useState("");
  const childRef = useRef();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pagination, setParentPagination] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { showToast } = useContext(ToastContext);
  useEffect(() => {
    document.querySelector(".Polaris-Navigation").classList.remove("hidden");
  }, []);

  const handleRowClick = (row) => {
    navigate(`/resume/${row._id}`);
  };

  useEffect(() => {}, [loading]);

  const handleSelectResume = async () => {
    await document.getElementById("fileInput").click();
  };

  // const cutCredit = async () => {
  //   if (userProfileData.pricingPlan.featureLimitation.interview >= 5) {
  //     userProfileData.pricingPlan.featureLimitation.interview -= 5;
  //     const updatedPricingPlan = {
  //       ...userProfileData.pricingPlan,
  //     };

  //     const pricingPlanRes = await fetch.put("/putPlan", updatedPricingPlan);
  //     const updatedUserProfileData = {
  //       ...userProfileData,
  //       pricingPlan: pricingPlanRes.data,
  //     };

  //     updateProfileInfo(updatedUserProfileData);
  //     showToast("Resume Add successfully");
  //   }
  // };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) setLoading(true);

    const formData = new FormData();
    let pdfLocation;
    if (file?.type === "application/pdf") {
      await convertPdfText(file);
    } else {
      formData.append("files", file, `${file?.lastModified}.docx`);
      await fetch.post("/upload-to-shopify", formData, false, {
        "Content-Type": "multipart/form-data",
      });
      const destinationDirectory = "server/backend/uploads/";
      pdfLocation = `${destinationDirectory}${file.lastModified}.docx`;
      const res = await fetch.post(`/filetotext`, { path: pdfLocation });
      navigate(`/resume/${res?._id}`);
      // cutCredit();
    }
  };

  const convertPdfText = async (file) => {
    pdfToText(file).then(async (text) => {
      const res = await fetch.post(`/filetotext`, { pdfText: JSON.stringify(text) });
      navigate(`/resume/${res?._id}`);
      // cutCredit();
      setLoading(true);
    });
  };

  const handleDelete = useCallback((row) => {
    setIsDeleteModalOpen(true);
    setResumeData(row);
    childRef.current.fetchData();
  }, []);

  const handleDeleteConfirmed = async () => {
    if (resumeData) {
      await fetch.delete(`/resume/${resumeData._id}`);
      childRef.current.fetchData();
      setIsDeleteModalOpen(false);
      showToast("Resume Deleted successfully");
    }
  };

  const handleDeleteCanceled = () => {
    setResumeData(null);
    setIsDeleteModalOpen(false);
  };

  const createRowsData = useCallback(
    (rows) => {
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;

      if (rows?.length <= 0) return [];
      return rows?.map((row, index) => {
        return (
          <IndexTable.Row id={row._id} key={row._id} position={index}>
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <Text>{row?.PersonalInformation?.firstName}</Text>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <Text>{row?.PersonalInformation?.lastName}</Text>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <Text>{row?.PersonalInformation?.emailAddress}</Text>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <Text>{row?.PersonalInformation?.phoneNumber}</Text>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  <Button
                    icon={DeleteMinor}
                    onClick={() => {
                      handleDelete(row);
                    }}
                  />
                  <Button
                    icon={ViewMinor}
                    onClick={() => {
                      handleRowClick(row);
                    }}
                  />
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [pagination]
  );
  return (
    <Page
      fullWidth={true}
      title={"Resume"}
      secondaryActions={
        <InlineStack align="end" gap="400">
          {/* <Badge
            fullWidth
            tone={userProfileData.pricingPlan.featureLimitation.interview < 5 ? "critical" : "success"}
          >
            <Text variant="headingSm" align="center">
              {`Credit`} : {userProfileData.pricingPlan.featureLimitation.interview}
            </Text>
          </Badge> */}
          <Button
            size="large"
            onClick={handleSelectResume}
            // disabled={userProfileData.pricingPlan.featureLimitation.interview < 5}
          >
            Select Resume
          </Button>

          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            accept=".pdf, .doc, .docx"
            style={{ display: "none" }}
          />
        </InlineStack>
      }
    >
      {loading ? (
        <div className="loading">
          <div className="loading-spinner">
            <Spinner></Spinner>
          </div>
        </div>
      ) : (
        <CommonTable
          resourceName={{
            singular: "Resume Candidate",
            plural: "Resume Candidates",
          }}
          title="Resume Candidate"
          queryPlaceholder="Search Resume by (First Name, Last Name, Email)"
          url={`/resumes`}
          isFilterVisible
          rowsData={createRowsData}
          selectable={false}
          setParentPagination={setParentPagination}
          headings={[
            { title: "No" },
            { title: "First Name" },
            { title: "Last Name" },
            { title: "Email" },
            { title: "Phone No." },
            { title: "Action" },
          ]}
          searchKey={["jobTitle", "jobLocation", "workMode", "employmentType"]}
          ref={childRef}
          columnContentTypes={["text", "string", "string", "string", "string", "Action"]}
          isAdd={false}
        />
      )}

      <Modal
        open={isDeleteModalOpen}
        onClose={handleDeleteCanceled}
        title="Confirm Delete"
        primaryAction={{
          content: "Delete",
          onAction: handleDeleteConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleDeleteCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to delete this resume?</Text>
        </Modal.Section>
      </Modal>
    </Page>
  );
};
