import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Banner, BlockStack, Button, InlineStack, Page } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import AdminNavigationMenu from "@/Components/Common/AdminNavigationMenu.jsx";
import { AdminPublicHeader } from "@/Components/Common/AdminPublicHeader";
import { CommonHeader } from "@/Components/Common/CommonHeader.jsx";
import { HomePageHeader } from "@/Components/Common/HomePageHeader.jsx";
import { useAuth } from "@/Context/AuthContext";
import { ToastContext } from "@/Context/ToastContext";
import { UserContext } from "@/Context/UserContext";
import { getLocalStorageItem, isAdmin } from "@/Utils/Index";
import { accessCondition } from "../Assets/Mocks/Access.mock.js";
import { PageAccessPermission } from "../Components/Common/AccessPermission.jsx";
import { AssessmentPageHeader } from "../Components/Common/AssessmentPageHeader.jsx";
import { PublicFooter } from "../Components/Common/PublicFooter.jsx";
import NavigationMenu from "./Navigation.jsx";

export const AppRoute = ({ type }) => {
  const { isAuthenticated, login } = useAuth();
  const [loading, setLoading] = useState(true);
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [requiredAccess, setRequiredAccess] = useState([]);
  const [useEffectCompleted, setUseEffectCompleted] = useState(false);
  const [isNavigationVisible, setIsNavigationVisible] = useState(false);
  const { showToast } = useContext(ToastContext);
  const [countdown, setCountdown] = useState(2);

  const fetch = useAuthenticatedFetch();
  const fetchUser = async () => {
    setLoading(true);
    try {
      const user = await fetch.get(`/user`);
      updateProfileInfo(user?.data);
      login();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!isAuthenticated && getLocalStorageItem("authorization")) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [userProfileData]);

  useEffect(() => {
    if (
      isAuthenticated &&
      userProfileData &&
      userProfileData.organizations &&
      userProfileData.organizations.length === 0
    ) {
      if (location.pathname !== "/add-organization") {
        navigate(`/add-organization`);
      }
    }
  }, [userProfileData, location]);

  useEffect(() => {
    const halfHour = 30 * 60 * 1000;
    const updatedTime = new Date(userProfileData?.updated).getTime();
    const now = Date.now();
    const timeElapsed = now - updatedTime;
    const timeLeft = halfHour - timeElapsed;
    const timeLeftInSeconds = Math.ceil(timeLeft / 1000);
    setCountdown(timeLeftInSeconds);
  }, [userProfileData]);

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  useEffect(() => {
    const jobPathRegex = /^\/jobs\/([a-zA-Z0-9]+)$/;
    const match = location.pathname.match(jobPathRegex);
    const path = match ? "/jobs/id" : location.pathname;
    const foundObject = accessCondition.find((obj) => obj.url === path);
    setRequiredAccess(foundObject?.requiredRoles || []);
    setUseEffectCompleted(true);
  }, [location, accessCondition, userProfileData]);

  const handleButtonClick = async () => {
    const res = await fetch.post(`/resend`, { email: userProfileData?.email });
    showToast("The email has been resent successfully. Kindly check your inbox.");
  };

  if (loading) {
    return <></>;
  }
  if (type === "public") {
    return isAuthenticated ? (
      <Navigate to="/jobs" />
    ) : (
      <CommonHeader>
        <div className="page-container-box">
          <Outlet />
        </div>
      </CommonHeader>
    );
  }
  if (type === "private") {
    return isAuthenticated ? (
      <>
        <CommonHeader>
          <div className="page-container-box">
            <NavigationMenu
              setIsNavigationVisible={setIsNavigationVisible}
              setUseEffectCompleted={setUseEffectCompleted}
            />
            {useEffectCompleted && (
              <PageAccessPermission access={requiredAccess}>
                <Page fullWidth={true}>
                  <BlockStack gap="200">
                    {!userProfileData.verified && (
                      <>
                        {countdown < 0 && (
                          <Banner
                            tone="warning"
                            title={
                              <InlineStack gap={400}>
                                Verify your email to secure your account and receive regular software updates,
                                including new features and tips for using Recruitment AI effectively.
                                <Button variant="plain" onClick={handleButtonClick}>
                                  Resend Email
                                </Button>
                              </InlineStack>
                            }
                          ></Banner>
                        )}
                      </>
                    )}
                    <Outlet />
                  </BlockStack>
                </Page>
              </PageAccessPermission>
            )}
          </div>
        </CommonHeader>
      </>
    ) : (
      <Navigate to="/login" />
    );
  }
};

export const GeneralRoute = () => {
  const location = useLocation();
  const [isAssessment, setAssessment] = useState(false);
  useEffect(() => {
    const isAssessmentPage =
      location.pathname.includes("/assessment/") || location.pathname.includes("/stage/share/");
    setAssessment(isAssessmentPage ? false : true);
  }, [location]);

  return (
    <>
      {isAssessment && <HomePageHeader />}
      <Outlet />
      {isAssessment && <PublicFooter />}
    </>
  );
};

export const PublicAdminRoute = () => {
  return (
    <>
      <AdminPublicHeader />
      <Outlet />
    </>
  );
};

export const PrivateAdminRoute = () => {
  return (
    <>
      <AdminPublicHeader />
      <div className="page-container-box">
        <AdminNavigationMenu />
        <Outlet />
      </div>
    </>
  );
};
