export const formFields = [
  {
    id: "firstName",
    name: "firstName",
    label: "First Name",
    validated: true,
    type: "text",
    placeholder: "Enter Your First Name",
    errormsg: "First Name is required",
  },
  {
    id: "lastName",
    name: "lastName",
    label: "Last Name",
    validated: true,
    type: "text",
    placeholder: "Enter Your Last Name",
    errormsg: "Last Name is required",
  },
  {
    id: "email",
    name: "email",
    label: "Email",
    validated: true,
    type: "email",
    placeholder: "Enter Your Email ID",
    errormsg: "Email is required",
  },
  {
    id: "mobile",
    name: "mobile",
    label: "Mobile",
    validated: true,
    type: "tel",
    autoComplete: "off",
    placeholder: "Enter Your Mobile Number",
    errormsg: "Mobile Number is required",
  },
  {
    id: "password",
    name: "password",
    label: "Password",
    validated: true,
    type: "password",
    placeholder: "Enter Your Password",
    errormsg: "Password is required",
  },
];

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
};
