import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Frame, TopBar } from "@shopify/polaris";
import { LogOutMinor, SettingsMinor } from "@shopify/polaris-icons";
import * as Images from "@/Assets/Index";
import NavigationMenu from "../../App/Navigation";
import { UserContext } from "../../Context/UserContext";

export const CommonHeader = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("authorization");
  const { userProfileData } = useContext(UserContext);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isNavigationVisible, setIsNavigationVisible] = useState(false);
  const [useEffectCompleted, setUseEffectCompleted] = useState(false);

  const handleNavigationToggle = useCallback(() => {
    setIsNavigationVisible((prevState) => !prevState);
  }, []);

  const logo = {
    topBarSource: Images.AppLogo,
    width: 180,
    accessibilityLabel: "Recruitments By AI",
    url: "/jobs",
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        setIsNavigationVisible(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleIsUserMenuOpen = useCallback(() => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen), []);

  const userMenuMarkup = isLoggedIn && userProfileData && userProfileData.firstName && (
    <TopBar.UserMenu
      name={userProfileData?.firstName}
      initials={userProfileData?.firstName.split("")[0]}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );
  const topBarMarkup = (
    <TopBar showNavigationToggle userMenu={userMenuMarkup} onNavigationToggle={handleNavigationToggle} />
  );

  return (
    <Frame
      topBar={topBarMarkup}
      logo={logo}
      navigation={
        isNavigationVisible ? (
          <NavigationMenu
            setUseEffectCompleted={setUseEffectCompleted}
            setIsNavigationVisible={setIsNavigationVisible}
          />
        ) : null
      }
      showMobileNavigation={isNavigationVisible}
      onNavigationDismiss={() => setIsNavigationVisible(false)}
    >
      {children}
    </Frame>
  );
};
