export const getTemplateFields = (templates) => {
  let options = [{ value: "none", label: "Start from scratch(Manually)" }, ...templates];
  let fields = [
    {
      nested: "group",
      groupSize: 4,
      section: false,
      subfields: [
        {
          id: "template",
          name: "template",
          label: "Select Template",
          type: "select",
          placeholder: "Select Template",
          options: options,
        },
      ],
    },
  ];
  return fields;
};

export const getSaveTemplateFields = (templates) => {
  let options = [{ value: "none", label: "New Template" }, ...templates];
  let fields = [
    {
      id: "template",
      name: "template",
      label: "Select Template",
      type: "select",
      placeholder: "Select Template",
      options: options,
    },
    {
      id: "label",
      name: "label",
      label: "Template Title",
      type: "text",
      errormsg: "Template title is required",
      validated: true,
      dependOn: {
        name: "template",
        value: "none",
        type: "hidden",
      },
    },
  ];
  return fields;
};

export const templateInitialValues = {
  template: "none",
};
