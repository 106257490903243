import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BlockStack, Box, Button, Card, InlineGrid, InlineStack, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { candidateDetailsInitialValues, getCandidateDetailFields } from "../../../Assets/Mocks/Candidate.mock";
import { AccessPermission } from "../../../Components/Common/AccessPermission";
import { CandidateList } from "./CandidateList.jsx";

export const StageDetails = () => {
  const candidateDetailFields = getCandidateDetailFields();
  const { id } = useParams();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const [stageDetails, setStageDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [actionType, setActionType] = useState("");
  const [isCandidateModelOpen, setIsCandidateModelOpen] = useState(false);
  const formRef = useRef();
  const childRef = useRef();
  const [candidateFormValues, setCandidateFormValues] = useState(candidateDetailsInitialValues);
  const { showToast } = useContext(ToastContext);
  const [jobStages, setJobStages] = useState([]);

  useEffect(() => {
    getStageDetails();
  }, [id]);

  const handleBackClick = useCallback(() => {
    const jobId = stageDetails?.jobId || "";
    navigate(`/jobs/${jobId}`);
  }, [stageDetails]);

  const getJobStages = useCallback(
    async (jobId) => {
      const res = await fetch.get(`/stages/${jobId}`);
      setJobStages(res?.data?.rows || []);
    },
    [stageDetails]
  );

  const handleButtonClick = () => {
    setIsCandidateModelOpen(true);
    setActionType("add");
  };

  const handleModalClose = () => {
    setIsCandidateModelOpen(false);
    setCandidateFormValues({ ...candidateDetailsInitialValues });
  };

  const handleEdit = (row) => {
    setActionType("update");
    setCandidateFormValues({ ...candidateDetailsInitialValues, ...row });
    setIsCandidateModelOpen(true);
  };

  const handleDelete = useCallback(async (row) => {
    setCandidateToDelete(row);
    setIsDeleteModalOpen(true);
  }, []);

  const handleDeleteCanceled = useCallback(() => {
    setCandidateToDelete(null);
    setIsDeleteModalOpen(false);
  }, []);

  const handleRowClick = (row) => {
    navigate(`/candidate/${row._id}`);
  };

  const getStageDetails = useCallback(async () => {
    setIsLoading(true);
    const response = await fetch.get(`/stage/${id}`);
    setStageDetails(response?.data);
    getJobStages(response?.data?.jobId);
    setIsLoading(false);
  }, [id]);

  const saveJob = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (e) => {
      const jobId = stageDetails?.jobId || "";
      await fetch.post("/candidate", {
        jobId: jobId,
        stageId: stageDetails?._id,
        ...e,
      });
      actionType === "add" ? showToast("Candidate Add successfully") : showToast("Candidate Updated successfully");
      setCandidateFormValues(candidateDetailsInitialValues);
      childRef.current.getStageCandidates();

      setIsCandidateModelOpen(false);
    },
    [childRef, actionType]
  );

  return (
    <Page
      fullWidth={true}
      title={stageDetails?.stageDetails?.stageTitle}
      backAction={{ content: "Back", onAction: handleBackClick }}
    >
      <BlockStack gap="400">
        {/* <Box>
          <BlockStack gap="400">
            <Text variant="headingLg">Stage Details</Text>
            <Card>
              <BlockStack gap="200">
                {stageDetails?.stageDetails?.questionList.map(
                  (item, index) =>
                    item.question.length > 0 && (
                      <Box key={index}>
                        <InlineGrid columns={2}>
                          <BlockStack gap="100">
                            <Text variant="subdued">Question {index + 1}</Text>
                            {item?.questionType == "text" && <Text variant="headingSm">{item?.question}</Text>}
                            {item?.questionType == "video" && (
                              <ReactPlayer
                                url={`${process.env.APP_URL}/uploads/${item?.questionVideo?.assetUrl}`}
                                controls={true}
                                height={160}
                                width={215}
                              />
                            )}
                          </BlockStack>
                          <BlockStack gap="100">
                            <Text variant="subdued">Answer Type</Text>
                            <Text variant="headingSm">{item.answerType}</Text>
                          </BlockStack>
                        </InlineGrid>
                      </Box>
                    )
                )}
              </BlockStack>
            </Card>
          </BlockStack>
        </Box> */}
        <Box>
          <BlockStack gap="200">
            <InlineStack align="space-between" blockAlign="center">
              <AccessPermission access={["manageJobs"]}>
                <Text variant="headingLg">Candidates</Text>
                <Button size="large" onClick={handleButtonClick}>
                  Add Candidate
                </Button>
              </AccessPermission>
            </InlineStack>

            <CandidateList stageId={id} jobStages={jobStages} ref={childRef} />
          </BlockStack>
        </Box>
      </BlockStack>
      <BannerModal
        open={isCandidateModelOpen}
        onClose={handleModalClose}
        title={actionType === "update" ? "Edit Candidate" : "Add New Candidate"}
        primaryAction={{
          content: "Submit",
          onAction: saveJob,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalClose,
          },
        ]}
        children={
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={candidateFormValues}
            formFields={candidateDetailFields}
            title="Add New Candidate"
            isSave={false}
          />
        }
      />
    </Page>
  );
};
