import "./Template2.scss";

export function Template2({ resumeData }) {
  function addEducation(value) {
    return (
      <>
        <p className="bolded white">{value?.degreeEarned}</p>
        <p>{value?.institutionName}</p>
        <p>{value?.fieldOfStudy}</p>
        <p>{value?.graduationDate}</p>
      </>
    );
  }

  function addWorkExpireance(value) {
    const dateRange =
      value.endDate === "Present" ? `${value.startDate} - Present` : `${value.startDate} - ${value.endDate}`;

    return (
      <li className=" printable-content">
        <div className="jobPosition">
          <span className="bolded">
            {value?.jobTitle} {"  "}
          </span>
          {value?.startDate || (value?.endDate && <span>{dateRange}</span>)}
        </div>
        <div className="projectName bolded">
          <span>{value.companyName}</span>
        </div>
        <div className="smallText">
          <ul>
            {value?.responsibilities &&
              value?.responsibilities.map((responsibility, index) => <li key={index}>{responsibility}</li>)}{" "}
            <br />
          </ul>
        </div>
      </li>
    );
  }

  function projectExpireance(value) {
    const dateRange =
      value.endDate === "Present" ? `${value?.startDate} - Present` : `${value?.startDate} - ${value?.endDate}`;

    return (
      <li className=" printable-content">
        <div className="jobPosition">
          <span className="bolded">{value?.projectName}</span>
          {value?.startDate || (value?.endDate && <span>{dateRange}</span>)}
        </div>
        <div className="projectName bolded">
          <a href={value?.projectLink}>{value?.projectLink}</a>
        </div>
        <br />
        <div className="smallText">
          <p>Description: {value?.projectDescription}</p>
          <p>Responsibility:{value?.roleResponsibility}</p>
          <ul style={{ display: "flex", flexWrap: "wrap" }}>
            {value?.technologiesUsed.map((technology, index) => (
              <li style={{ minWidth: "130px" }} className="technology" key={index}>
                {technology}
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  }

  function addAword(value) {
    return (
      <li className=" printable-content">
        <div className="jobPosition">
          <span className="bolded">{value?.awardingOrganization}</span>
          {value?.dateReceived && <span>{value?.dateReceived}</span>}
        </div>
        <div className="smallText">
          <ul>
            <li style={{ padding: "5px" }}>{value?.awardName}</li>
          </ul>
        </div>
      </li>
    );
  }

  return (
    <div className="template2">
      <page size="A4">
        <div className="container">
          <div className="leftPanel">
            <img src="avatar.png" />
            <div className="details">
              <div className="item bottomLineSeparator">
                <h2>CONTACT</h2>
                <div className="smallText">
                  <p>
                    <i className="fa fa-phone contactIcon" aria-hidden="true"></i>
                    {resumeData?.PersonalInformation?.phoneNumber}
                  </p>
                  <p>
                    <i className="fa fa-envelope contactIcon" aria-hidden="true"></i>
                    <a href="mailto:lorem@ipsum.com@gmail.com">{resumeData?.PersonalInformation?.emailAddress}</a>
                  </p>
                  <p>
                    <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                    {resumeData?.PersonalInformation?.mailingAddress}
                  </p>
                  <p>
                    <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                    {resumeData?.PersonalInformation?.githubProfile}
                  </p>
                  <p>
                    <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                    {resumeData?.PersonalInformation?.linkedInProfile}
                  </p>
                  <p>
                    <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                    {resumeData?.PersonalInformation?.personalWebsite}
                  </p>
                </div>
              </div>
              {resumeData?.Skills.length > 0 && (
                <div className="item bottomLineSeparator">
                  <h2>SKILLS</h2>
                  <div className="smallText">
                    <ul style={{ display: "flex", flexWrap: "wrap", padding: " 0px 15px" }}>
                      {resumeData?.Skills.map((skill, index) => (
                        <li className="skills " key={index}>
                          {skill}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {resumeData?.Hobby.length > 0 && (
                <div className="item bottomLineSeparator">
                  <h2>HOBBY</h2>
                  <div className="smallText">
                    <ul
                      style={{ display: "flex", flexWrap: "wrap", padding: " 0px 15px" }}
                      className="printable-content"
                    >
                      {resumeData?.Hobby.map((hobby, index) => (
                        <li className="skills" key={index}>
                          {hobby}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {resumeData?.Education.length > 0 && (
                <div className="item">
                  <h2>EDUCATION</h2>
                  <div className="smallText">
                    {resumeData?.Education.map((e, index) => (
                      <div className="printable-content" key={index}>
                        {addEducation(e)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="rightPanel">
            <div>
              <h1>
                {resumeData?.PersonalInformation?.firstName} {resumeData?.PersonalInformation?.lastName}
              </h1>
            </div>
            <br />
            {resumeData?.ProfessionalSummary?.summary && (
              <div>
                <h2 style={{ marginBottom: "10px" }}>About me</h2>
                <div className="smallText">
                  <p>{resumeData?.ProfessionalSummary?.summary}</p>
                </div>
              </div>
            )}
            <br />
            {resumeData?.WorkExperience.length > 0 && (
              <div className="workExperience">
                <h2 style={{ marginBottom: "10px" }}>Work experience</h2>
                <ul id="workExperienceData">
                  {resumeData?.WorkExperience.map((e, index) => (
                    <div className="printable-content" key={index}>
                      {addWorkExpireance(e)}
                    </div>
                  ))}
                </ul>
              </div>
            )}
            <br />
            {resumeData?.Project.length > 0 && (
              <div className="workExperience">
                <h2 style={{ marginBottom: "10px" }}>Project</h2>
                <ul id="workExperienceData" className=" printable-content">
                  {" "}
                  {resumeData?.Project.map((e, index) => (
                    <div className="printable-content" key={index}>
                      {projectExpireance(e)}
                    </div>
                  ))}
                </ul>
              </div>
            )}
            <br />
            {resumeData?.Certification.length > 0 && (
              <div>
                <h2 style={{ marginBottom: "10px" }}>Certification</h2>
                <div className="smallText">
                  <ul className=" printable-content">
                    {resumeData?.Certification.map((certification, index) => (
                      <li key={index} style={{ padding: "5px" }}>
                        {certification?.certificationName}-{certification?.issuingOrganization}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <br />
            {resumeData?.Award.length > 0 && (
              <div>
                <h2 style={{ marginBottom: "10px" }}>Award</h2>
                <div>
                  <ul id="workExperienceData" className=" printable-content">
                    {resumeData?.Award.map((e) => addAword(e))}
                  </ul>
                </div>
              </div>
            )}
            <br />
            {resumeData?.Language.length > 0 && (
              <div>
                <h2 style={{ marginBottom: "10px" }}>Language</h2>
                <div className="smallText">
                  <ul style={{ display: "flex", flexWrap: "wrap" }} className=" printable-content">
                    {resumeData?.Language.map((language, index) => (
                      <li className="skills" key={index}>
                        {language?.languageName}-{language?.proficiencyLevel}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </page>
    </div>
  );
}
