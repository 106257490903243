import React, { useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { BlockStack, Box, Button, Card, Image, InlineStack, Text } from "@shopify/polaris";
import { t } from "i18next";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BlankStar, FilledStar } from "@/Assets/Index";
import CommonForm from "@/Components/Common/CommonForm";

export default function Review({ id, setIsFeedback }) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [rate, setRate] = useState(urlParams.get("rate") || 5);
  const [feedBack, setFeedBack] = useState({ feedBack: "" });
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();

  const formField = [
    {
      id: "feedBack",
      name: "feedBack",
      label: " ",
      nested: "object",
      groupSize: 1,
      section: false,
      subfields: [
        {
          radioId: "smoothProcess",
          id: "smoothProcess",
          name: "reason",
          label: "Smooth process, no technical issues faced",
          type: "radio",
        },
        {
          radioId: "connectivityIssues",
          id: "connectivityIssues",
          name: "reason",
          label: "There are connectivity issues present",
          type: "radio",
        },
        {
          radioId: "technicalIssues",
          id: "technicalIssues",
          name: "reason",
          label: "Faced Technical Issues",
          type: "radio",
        },
        {
          radioId: "slowSpeed",
          id: "slowSpeed",
          name: "reason",
          label: "The interview pace was too slow",
          type: "radio",
        },
      ],
    },
  ];

  const handleSubmit = useCallback(async () => {
    formRef.current.fetchData();
  }, []);

  const postReview = useCallback(async () => {
    await fetch.post("/update/review", JSON.stringify({ review: { ...feedBack, rate, id } }));
    setFeedBack({});
    setIsFeedback(true);
  }, [feedBack, rate]);

  return (
    <div>
      <Card padding="1000">
        <BlockStack inlineAlign="center" gap={200}>
          <Text variant="headingXl">Please rate us.</Text>
          <Text fontWeight="medium" tone="subdued">
            How was your interview experience?
          </Text>
          <InlineStack gap={100}>
            {[...Array(5)].map((_, index) => (
              <Button key={index} variant="plain" onClick={() => setRate(index + 1)}>
                <Image source={index + 1 > rate ? BlankStar : FilledStar} width={30} />
              </Button>
            ))}
          </InlineStack>
          <Box width="70%">
            <CommonForm
              onSubmit={handleSubmit}
              initialValues={feedBack}
              onFormChange={(val) => setFeedBack(val)}
              formFields={formField}
              formRef={formRef}
              isSave={false}
              enableReinitialize={true}
            />
          </Box>
          <InlineStack align="end">
            <Text />
            <Button variant="primary" onClick={postReview}>
              Submit
            </Button>
          </InlineStack>
        </BlockStack>
      </Card>
    </div>
  );
}
