import React, { useCallback, useState } from "react";
import { ActionList, Button, Popover } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CandidateFeedback from "./CandidateFeedback";
import RateCandidate from "./RateCandidate";

export default function CandidateAction({
  candidate,
  jobid,
  parentSelectedTab,
  childRef,
  filter,
  isStatus,
  jobData,
}) {
  const [active, setActive] = useState(false);
  const [showRateCandidate, setShowRateCandidate] = useState(false);
  const [showFeedbackCandidate, setShowFeedbackCandidate] = useState(false);
  const fetch = useAuthenticatedFetch();

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleRateAction = () => {
    setShowRateCandidate(true);
  };

  const handleFeedbackAction = () => {
    setShowFeedbackCandidate(true);
  };

  const getResumeScore = async () => {
    const data = {
      jobData,
      candidateResume: candidate?.resume?.assetUrl,
      candidateId: candidate?._id,
      candidate,
    };
    await fetch.post(`/candidateResumeScore`, data);
    childRef?.current?.fetchData();
  };

  const activator = (
    <Button onClick={toggleActive} disclosure>
      Actions
    </Button>
  );

  return (
    <div className="candidate-select" style={{ maxWidth: "170px" }}>
      <Popover active={active} activator={activator} autofocusTarget="first-node" onClose={toggleActive}>
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: "Rate Candidate",
              onAction: handleRateAction,
            },
            {
              content: "Candidate Feedback",
              onAction: handleFeedbackAction,
            },
            // {
            //   content: "Get Resume Score",
            //   onAction: getResumeScore,
            // },
          ]}
        />
      </Popover>
      {showRateCandidate && (
        <RateCandidate
          candidate={candidate}
          jobid={jobid}
          parentSelectedTab={parentSelectedTab}
          childRef={childRef}
          filter={filter}
          isStatus={isStatus}
          setShowRateCandidate={setShowRateCandidate}
        />
      )}
      {showFeedbackCandidate && (
        <CandidateFeedback
          candidate={candidate}
          jobid={jobid}
          parentSelectedTab={parentSelectedTab}
          childRef={childRef}
          filter={filter}
          isStatus={isStatus}
          setShowFeedbackCandidate={setShowFeedbackCandidate}
        />
      )}
    </div>
  );
}
