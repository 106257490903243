import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlockStack, Box, Card, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { resetPasswordFormFields, resetPasswordInitialValues } from "@/Assets/Mocks/ResetPassword.mock.js";
import CommonForm from "@/Components/Common/CommonForm";
import { useAuth } from "@/Context/AuthContext";
import { ToastContext } from "@/Context/ToastContext";

export const ResetPassword = () => {
  const resetPasswordToken = new URLSearchParams(window.location.search).get("token");
  const email = new URLSearchParams(window.location.search).get("email");
  const [loading, setLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const { logout, login } = useAuth();
  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    const checkVerification = async () => {
      const res = await fetch.get(`/verifyResetToken/${resetPasswordToken}/${email}`);
      setLoading(false);

      if (res.data.resetVerified) {
        setVerificationSuccess(true);
      } else {
        setVerificationSuccess(false);
      }
    };
    checkVerification();
  }, []);

  const handleSubmit = async (e) => {
    const res = await fetch.post("/password", { email: email, ...e });
    if (res.data) {
      showToast("Password updated successfully");
      navigate("/login");
    }
  };

  if (verificationSuccess) {
    return (
      <Page fullWidth={true}>
        <BlockStack gap="200" inlineAlign="center">
          <Box className="public-page" width="35%">
            <Card>
              <BlockStack gap="300">
                <Text alignment="center" variant="headingXl" color="subdued" fontWeight="bold">
                  Reset Password
                </Text>
                <Card>
                  <BlockStack gap="200">
                    <CommonForm
                      onSubmit={handleSubmit}
                      initialValues={resetPasswordInitialValues}
                      formFields={resetPasswordFormFields}
                      isSave={true}
                      label="Reset"
                    />
                  </BlockStack>
                </Card>
              </BlockStack>
            </Card>
          </Box>
        </BlockStack>
      </Page>
    );
  }
};
