import React, { useCallback, useState } from "react";
import { Card, Page, Tabs } from "@shopify/polaris";
import { AssessmentReminder } from "./AssessmentReminder";
import { GeneralSettings } from "./GeneralSettings";
import { Profile } from "./Profile";
import { TeamMates } from "./TeamMates";

export const Settings = () => {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
  const tabs = [
    {
      id: "settings-tab-1",
      content: "My Profile",
      accessibilityLabel: "My Profile",
      panelID: "settings-tab-1",
    },
    {
      id: "settings-tab-2",
      content: "Teammates",
      accessibilityLabel: "Teammates",
      panelID: "settings-tab-2",
    },
    {
      id: "settings-tab-3",
      content: "Organizations",
      accessibilityLabel: "Organizations",
      panelID: "settings-tab-3",
    },
    {
      id: "settings-tab-4",
      content: "Assessment Reminder",
      accessibilityLabel: "Assessment Reminder",
      panelID: "settings-tab-4",
    },
  ];
  return (
    <Page fullWidth={true}>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {selected === 0 && <Profile />}
          {selected === 1 && <TeamMates />}
          {selected === 2 && <GeneralSettings />}
          {selected === 3 && <AssessmentReminder />}
        </Tabs>
      </Card>
    </Page>
  );
};
