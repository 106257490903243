import React, { useContext } from "react";
import { Badge, Icon, Image, InlineStack, Text } from "@shopify/polaris";
import { ChatMajor, ClipboardMinor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { Whatsapp } from "@/Assets/Index";
import ProgressCircle from "@/Components/Common/ProgressCircle";
import { ToastContext } from "@/Context/ToastContext";

export default function AssessmentStatus({ row, stage, jobData }) {
  const { showToast } = useContext(ToastContext);
  const fetch = useAuthenticatedFetch();

  const getAssessmentStatus = (stage) => {
    if (stage?.stageDetails?.stagePreset === "noQuestion") {
      return "Done";
    } else {
      let status = stage.isSubmit ? "Done" : "Sent";
      return status;
    }
  };

  const copyToClipboard = async (stage) => {
    const link = `${process.env.APP_URL}/assessment/${stage?.assessmentId}`;
    await navigator.clipboard.writeText(link);
    showToast("Link copied to clipboard.");
  };

  return (
    <>
      {!stage?.assessmentStatus ? (
        <Text as="p" fontWeight="medium">
          Assessment not send
        </Text>
      ) : stage?.assessmentStatus && ((!stage?.overallScore && stage?.overallScore !== 0) || !stage.isSubmit) ? (
        <>
          <Badge tone={getAssessmentStatus(stage) === "Done" ? "success" : "attention"}>
            {getAssessmentStatus(stage)}
          </Badge>
          {getAssessmentStatus(stage) !== "Done" && (
            <>
              <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(stage)}>
                <Icon source={ClipboardMinor} />
              </a>
            </>
          )}
        </>
      ) : (
        <ProgressCircle score={stage?.overallScore?.toFixed(0)} fontSize="8px" width="25px" border="2px" />
      )}
    </>
  );
}
