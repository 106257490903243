import React, { useCallback, useContext, useEffect, useState } from "react";
import { BlockStack, Image, InlineStack, Page, Text } from "@shopify/polaris";
import { PageLockLogo } from "../../Assets/Index";
import { UserContext } from "../../Context/UserContext";

export function PageAccessPermission({ children, access }) {
  const { userProfileData } = useContext(UserContext);
  const [isPermission, setIsPermission] = useState(false);
  useEffect(() => {
    const organization = userProfileData.currentOrganization;
    if (access.length == 0) return setIsPermission(true);
    if (organization?.role == "administrator") return setIsPermission(true);
    const hasPermission = access.some((permission) =>
      organization?.permission.some((orgPermission) => orgPermission?.name === permission)
    );
    if (hasPermission) {
      setIsPermission(true);
    } else {
      setIsPermission(false);
    }
  }, [access, userProfileData]);
  if (isPermission) {
    return <>{children}</>;
  }
  return (
    <Page fullWidth={true}>
      <div className="lock-logo">
        <InlineStack align="space-between">
          <Image width={350} height={350} source={PageLockLogo}></Image>
        </InlineStack>
        <Text variant="heading2xl" as="h3" alignment="center" fontWeight="regular">
          You don’t have access
        </Text>
        <br />
        <Text variant="headingMd" as="p" alignment="center" tone="subdued" fontWeight="regular">
          Access to this page is restricted, and you do not have the required permissions to view its content.
        </Text>
      </div>
    </Page>
  );
}

export function AccessPermission({ children, access }) {
  const { userProfileData } = useContext(UserContext);
  const [isPermission, setIsPermission] = useState(false);
  const organization = userProfileData?.currentOrganization;
  useEffect(() => {
    const hasPermission = access.some((permission) =>
      organization?.permission.some((orgPermission) => orgPermission?.name === permission)
    );
    if (hasPermission || organization.role == "administrator") {
      setIsPermission(true);
    }
  }, [access, organization, userProfileData]);
  if (isPermission) {
    return <>{children}</>;
  }
  return <></>;
}
