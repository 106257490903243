import React from "react";
import { Card, Layout, SkeletonBodyText, SkeletonPage, Text } from "@shopify/polaris";

export default function CommonSkeletonPage() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
            <Card>
              <SkeletonBodyText lines={1} />
            </Card>
          </Card>
          <Card subdued>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
          </Card>
          <Card subdued>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
          </Card>
          <Card subdued>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
