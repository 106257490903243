import { Template1, Template2, Template3, Template4 } from "@/Assets/Index";

export const personalInformationFields = [
  {
    id: "PersonalInformation",
    name: "PersonalInformation",
    label: " ",
    nested: "object",
    groupSize: 2,
    section: true,
    subfields: [
      {
        id: "firstName",
        name: "firstName",
        label: "First Name",
        validated: true,
        type: "text",
        errormsg: "First Name is required",
      },
      {
        id: "lastName",
        name: "lastName",
        label: "Last Name",
        validated: true,
        type: "text",
        errormsg: "Last Name is required",
      },
      {
        id: "phoneNumber",
        name: "phoneNumber",
        label: "Phone Number",
        type: "text",
        validated: false,
        errormsg: "Phone Number is required",
      },
      {
        id: "emailAddress",
        name: "emailAddress",
        label: "Email Address",
        type: "text",
        validated: false,
        errormsg: "Email Address is required",
      },
      {
        id: "linkedInProfile",
        name: "linkedInProfile",
        label: "LinkedIn Profile",
        type: "text",
      },
      {
        id: "githubProfile",
        name: "githubProfile",
        label: "Github Profile",
        type: "text",
        validated: false,
      },
      {
        id: "personalWebsite",
        name: "personalWebsite",
        label: "Personal Website",
        validated: false,
        type: "text",
      },
    ],
  },
];

export const ProfessionalSummaryFields = [
  {
    id: "ProfessionalSummary",
    name: "ProfessionalSummary",
    label: " ",
    nested: "object",
    groupSize: 2,
    section: true,
    subfields: [
      {
        id: "summary",
        name: "summary",
        label: "Summary",
        validated: true,
        type: "text",
        placeholder: "Add Professional Summary",
        errormsg: "Professional Summary is required",
        multiline: 3,
      },
    ],
  },
];

export const personalInformationInitialValues = {
  PersonalInformation: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    linkedInProfile: "",
    githubProfile: "",
    personalWebsite: "",
  },
};
export const ProfessionalSummaryInitialValues = {
  ProfessionalSummary: {
    summary: "",
  },
};
export const WorkExperienceFields = [
  {
    id: "WorkExperience",
    name: "WorkExperience",
    label: " ",
    nested: "array",
    showDeleteToolTip: true,
    showCollapsible: true,
    CollapsibleLabel:
      "`${index + 1} :  ${values?.WorkExperience?.[index]?.jobTitle && values?.WorkExperience?.[index]?.companyName ?  `${values?.WorkExperience?.[index]?.jobTitle || ''} at ${values?.WorkExperience?.[index]?.companyName || ''}` : ''}`",
    groupSize: 1,
    section: true,
    subfields: [
      {
        nested: "group",
        groupSize: 2,
        section: false,
        subfields: [
          {
            id: "jobTitle",
            name: "jobTitle",
            label: "Job Title",
            validated: true,
            type: "text",
            errormsg: "Job Title is required",
          },
          {
            id: "companyName",
            name: "companyName",
            label: "Company Name",
            validated: true,
            type: "text",
            errormsg: "Company Name is required",
          },
          {
            id: "startDate",
            name: "startDate",
            label: "Start Date",
            type: "text",
            validated: false,
          },
          {
            id: "endDate",
            name: "endDate",
            label: "End Date",
            type: "text",
            validated: false,
          },
          {
            id: "location",
            name: "location",
            label: "Location",
            type: "text",
          },
        ],
      },
      {
        id: "responsibilities",
        name: "responsibilities",
        label: "Responsibilities",
        validated: false,
        type: "array",
        errormsg: "Responsibilities is required",
      },
    ],
  },
];

export const WorkExperienceInitialValues = {
  WorkExperience: [
    {
      jobTitle: "",
      companyName: "",
      startDate: "",
      endDate: "",
      location: "",
      responsibilities: [],
    },
  ],
};

export const EducationField = [
  {
    id: "Education",
    name: "Education",
    label: " ",
    nested: "array",
    showDeleteToolTip: true,
    showCollapsible: true,
    CollapsibleLabel:
      "`${index + 1} :  ${values?.Education?.[index]?.institutionName ?  `${values?.Education?.[index]?.institutionName || ''}` : ''}`",
    groupSize: 2,
    section: true,
    subfields: [
      {
        id: "institutionName",
        name: "institutionName",
        label: "School Or Institution Name",
        validated: true,
        type: "text",
        errormsg: "Institution Name is required",
      },
      {
        id: "degreeEarned",
        name: "degreeEarned",
        label: "Degree Earned",
        validated: false,
        type: "text",
        errormsg: "Degree Earned is required",
      },
      {
        id: "fieldOfStudy",
        name: "fieldOfStudy",
        label: "Field Of Study",
        validated: false,
        type: "text",
        errormsg: "Field Of Study is required",
      },
      {
        id: "graduationDate",
        name: "graduationDate",
        label: "Graduation Date",
        validated: false,
        type: "text",
      },
      {
        id: "location",
        name: "location",
        label: "Location",
        validated: false,
        type: "text",
      },
    ],
  },
];
export const EducationInitialValues = {
  Education: [
    {
      institutionName: "",
      degreeEarned: "",
      fieldOfStudy: "",
      graduationDate: "",
      location: "",
    },
  ],
};
export const ProjectFields = [
  {
    id: "Project",
    name: "Project",
    label: " ",
    nested: "array",
    showDeleteToolTip: true,
    showCollapsible: true,
    CollapsibleLabel:
      "`${index + 1} :  ${values?.Project?.[index]?.projectName ?  `${values?.Project?.[index]?.projectName || ''}` : ''}`",
    groupSize: 2,
    section: true,
    subfields: [
      {
        id: "projectName",
        name: "projectName",
        label: "Project Name",
        validated: true,
        type: "text",
        errormsg: "Project Name is required",
      },
      {
        id: "projectDescription",
        name: "projectDescription",
        label: "Project Description",
        validated: true,
        type: "text",
        errormsg: "Project Description is required",
      },
      {
        id: "startDate",
        name: "startDate",
        label: "Start Date",
        validated: false,
        type: "text",
      },
      {
        id: "endDate",
        name: "endDate",
        label: "End Date",
        validated: false,
        type: "text",
      },
      {
        id: "projectLink",
        name: "projectLink",
        label: "Project Link",
        validated: false,
        type: "text",
      },
      {
        id: "roleResponsibility",
        name: "roleResponsibility",
        label: "Role Responsibility",
        validated: false,
        type: "text",
      },
      {
        id: "technologiesUsed",
        name: "technologiesUsed",
        label: "Technologies Used",
        validated: false,
        type: "array",
      },
    ],
  },
];

export const ProjectInitialValues = {
  Project: [
    {
      projectName: "",
      projectDescription: "",
      startDate: "",
      endDate: "",
      projectLink: "",
      roleResponsibility: "",
      technologiesUsed: [],
    },
  ],
};

export const CertificationFields = [
  {
    id: "Certification",
    name: "Certification",
    label: " ",
    nested: "array",
    showDeleteToolTip: true,
    showCollapsible: true,
    CollapsibleLabel:
      "`${index + 1} :  ${values?.Certification?.[index]?.issuingOrganization ?  `${values?.Certification?.[index]?.issuingOrganization || ''}` : ''}`",
    groupSize: 2,
    section: true,
    subfields: [
      {
        id: "certificationName",
        name: "certificationName",
        label: "Certification Name",
        validated: true,
        type: "text",
        errormsg: "Certification Name is required",
      },
      {
        id: "issuingOrganization",
        name: "issuingOrganization",
        label: "Organization Name",
        validated: true,
        type: "text",
        errormsg: "Organization Name is required",
      },
    ],
  },
];

export const CertificationInitialValues = {
  Certification: [
    {
      certificationName: "",
      issuingOrganization: "",
    },
  ],
};

export const SkillsFields = [
  {
    id: "Skills",
    name: "Skills",
    label: "Skills",
    validated: false,
    type: "array",
  },
];

export const SkillsInitialValues = {
  Skills: [],
};
export const AwardFields = [
  {
    id: "Award",
    name: "Award",
    label: " ",
    nested: "array",
    showDeleteToolTip: true,
    showCollapsible: true,
    CollapsibleLabel:
      "`${index + 1} :  ${values?.Award?.[index]?.awardName ?  `${values?.Award?.[index]?.awardName || ''}` : ''}`",
    groupSize: 2,
    section: true,
    subfields: [
      {
        id: "awardName",
        name: "awardName",
        label: "Award Name",
        validated: true,
        type: "text",
        errormsg: "Award Name is required",
      },
      {
        id: "awardingOrganization",
        name: "awardingOrganization",
        label: "Awarding Organization",
        validated: true,
        type: "text",
        errormsg: "Awarding Organization is required",
      },
      {
        id: "dateReceived",
        name: "dateReceived",
        label: "Date Received",
        type: "text",
        validated: false,
      },
    ],
  },
];

export const AwardInitialValues = {
  Award: [
    {
      awardName: "",
      awardingOrganization: "",
      dateReceived: "",
    },
  ],
};
export const LanguageFields = [
  {
    id: "Language",
    name: "Language",
    label: " ",
    nested: "array",
    showDeleteToolTip: true,
    showCollapsible: true,
    CollapsibleLabel:
      "`${index + 1} :  ${values?.Language?.[index]?.languageName ?  `${values?.Language?.[index]?.languageName || ''}` : ''}`",
    groupSize: 2,
    section: true,
    subfields: [
      {
        id: "languageName",
        name: "languageName",
        label: "Language Name",
        validated: true,
        type: "text",
        errormsg: "Language Name is required",
      },
      {
        id: "proficiencyLevel",
        name: "proficiencyLevel",
        label: "Proficiency Level",
        validated: true,
        type: "text",
        errormsg: "Proficiency Level is required",
      },
    ],
  },
];

export const LanguageInitialValues = {
  Language: [
    {
      languageName: "",
      proficiencyLevel: "",
    },
  ],
};

export const HobbyFields = [
  {
    id: "Hobby",
    name: "Hobby",
    label: "Hobby",
    validated: false,
    type: "array",
  },
];

export const HobbyInitialValues = {
  Hobby: [],
};

export const resumeFromObject = {
  PersonalInformation: {
    initialValue: personalInformationInitialValues,
    formField: personalInformationFields,
    title: "Personal Information",
    isOpen: true,
    id: "personalInformation",
  },
  ProfessionalSummary: {
    initialValue: ProfessionalSummaryInitialValues,
    formField: ProfessionalSummaryFields,
    title: "Professional Summary",
    isOpen: false,
    id: "summary",
  },
  Education: {
    initialValue: EducationInitialValues,
    formField: EducationField,
    title: "Education",
    isOpen: false,
    id: "education",
  },
  WorkExperience: {
    initialValue: WorkExperienceInitialValues,
    formField: WorkExperienceFields,
    title: "Work Experience",
    isOpen: false,
    id: "workExperience",
  },
  Project: {
    initialValue: ProjectInitialValues,
    formField: ProjectFields,
    title: "Project",
    isOpen: false,
    id: "project",
  },
  Certification: {
    initialValue: CertificationInitialValues,
    formField: CertificationFields,
    title: "Certification",
    isOpen: false,
    id: "certification",
  },
  Skills: {
    initialValue: SkillsInitialValues,
    formField: SkillsFields,
    title: "Skills",
    isOpen: false,
    id: "skill",
  },
  Award: {
    initialValue: AwardInitialValues,
    formField: AwardFields,
    title: "Award",
    isOpen: false,
    id: "award",
  },
  Language: {
    initialValue: LanguageInitialValues,
    formField: LanguageFields,
    title: "Language",
    isOpen: false,
    id: "language",
  },
  Hobby: {
    initialValue: HobbyInitialValues,
    formField: HobbyFields,
    title: "Hobby",
    isOpen: false,
    id: "hobby",
  },
};

export const resumeTemplate = {
  Templates: [
    {
      image: Template1,
      type: "Template1",
    },
    {
      image: Template2,
      type: "Template2",
    },
    // {
    //   image: Template3,
    //   type: "Template3",
    // },
    // {
    //   image: Template4,
    //   type: "Template4",
    // },
  ],
};
