import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BlockStack, Card, Page, Text } from "@shopify/polaris";
import { navigate } from "raviger";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { setLocalStorageItem } from "@/Utils/Index";
import { organizationFields, organizationInitialValues } from "../../../Assets/Mocks/Organization.mock";
import { UserContext } from "../../../Context/UserContext";

export const AddOrganization = () => {
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const { showToast } = useContext(ToastContext);
  const location = useLocation();
  const { organization, isEdit } = location.state || {};
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e) => {
      const res = await fetch.post("/organization", e);
      if (isEdit) {
        showToast("Update Organization successfully");
        navigate(`/settings`);
      } else {
        if (res.data) {
          await updateProfileInfo(res.data);
          await setLocalStorageItem("authorization", res.data.token);

          showToast("Add Organization successfully");
          setTimeout(() => {
            navigate(`/jobs`);
          }, 1000);
        }
      }
    },
    [userProfileData]
  );

  const handleBackClick = useCallback(() => {
    navigate(`/settings`);
  }, []);

  return (
    <Page
      backAction={{ content: "Back", onAction: handleBackClick }}
      title="Back to Settings"
      fullWidth={true}
      narrowWidth={true}
    >
      <Card roundedAbove="sm">
        <BlockStack gap="400">
          <Text alignment="center" variant="headingLg">
            Add Organization
          </Text>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={isEdit ? organization : organizationInitialValues}
            formFields={organizationFields}
            label={isEdit ? "Save" : "Add"}
          />
        </BlockStack>
      </Card>
    </Page>
  );
};
