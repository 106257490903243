import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Banner, BlockStack, Box, Button, Card, InlineStack, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { formFields, initialValues } from "@/Assets/Mocks/UserSignup.mock";
import CommonForm from "@/Components/Common/CommonForm";
import { useAuth } from "@/Context/AuthContext";
import { UserContext } from "@/Context/UserContext";
import { setLocalStorageItem } from "@/Utils/Index";

export const SignUp = () => {
  const fetch = useAuthenticatedFetch();
  const { updateProfileInfo } = useContext(UserContext);
  const [isRegistration, setIsRegistration] = useState(false);
  const { login, logout } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams && urlParams.get("email");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    const res = await fetch.post("/signup", { inviteEmail: email, ...e });
    if (res.success) {
      let value = {
        email: e.email,
        password: e.password,
      };
      const data = await fetch.post("/login", value);
      if (data.data) {
        updateProfileInfo(data.data);
        setLocalStorageItem("authorization", data.data.token);
        await login();
      }
      // let values = {
      //   name: "Default",
      //   companySize: "5",
      //   domain: "other",
      //   country: "IN",
      // };
      // await fetch.post("/organization", values);
      const loginData = await fetch.post("/login", value);
      if (loginData.data) {
        updateProfileInfo(loginData.data);
        setLocalStorageItem("authorization", loginData.data.token);
        login();
      }
      setLoading(false);
    }
  };

  const navidateLogin = () => {
    navigate(`/login`);
  };

  if (loading) {
    return <></>;
  }
  if (isRegistration) {
    return (
      <Page>
        <Card padding="600">
          <Banner
            tone="success"
            title=" Thank you for signing up with Recruitments.AI To complete your registration please check your email
              for a verification link."
          ></Banner>
        </Card>
      </Page>
    );
  }
  return (
    <Page fullWidth={true}>
      <InlineStack align="center">
        <Box className="public-page" width="35%">
          <Card>
            <BlockStack gap="300">
              <Text alignment="center" variant="headingXl" color="subdued" fontWeight="bold">
                Sign Up
              </Text>
              <Card>
                <CommonForm
                  onSubmit={handleSubmit}
                  initialValues={{ ...initialValues, email: email || "" }}
                  formFields={formFields}
                  isSave={true}
                  label="Sign Up"
                />
              </Card>
              <Card>
                <Text alignment="center" color="subdued" fontWeight="semibold">
                  Already Have An Account?{" "}
                  <Button
                    variant="primary"
                    onClick={() => {
                      navidateLogin();
                    }}
                  >
                    Login
                  </Button>
                </Text>
              </Card>
            </BlockStack>
          </Card>
        </Box>
      </InlineStack>
    </Page>
  );
};
