import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, BlockStack, Button, IndexTable, InlineStack, Link, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { AccessPermission } from "../../../Components/Common/AccessPermission";

export const CandidateList = forwardRef((props, ref) => {
  const { stageId, jobStages } = props;
  const { id } = useParams();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const [candidateList, setCandidateList] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCandidateModelOpen, setIsCandidateModelOpen] = useState(false);
  const childRef = useRef();

  const [pagination, setParentPagination] = useState(null);

  useEffect(() => {
    ref.current.getStageCandidates();
  }, [stageId]);

  useImperativeHandle(ref, () => ({
    getStageCandidates: async () => {
      setIsLoading(true);
      const response = await fetch.get(`/stage/candidates/${stageId}`);
      setCandidateList(response.data);
      childRef.current.fetchData();
      setIsLoading(false);
    },
  }));

  const handleRowClick = useCallback(() => {}, []);

  const createRowsData = useCallback(
    (rows) => {
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;
      if (rows?.length <= 0) return [];
      return rows?.map((row, index) => {
        return (
          <IndexTable.Row id={row._id} key={row._id} position={index}>
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell>
              <Link onClick={() => handleRowClick(row)} removeUnderline monochrome>
                {row?.fullName}
              </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>{row?.email}</IndexTable.Cell>
            <IndexTable.Cell>{row?.noticePeriod}</IndexTable.Cell>
            <IndexTable.Cell>{row?.totalExperience}</IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  {row?.assessment ? <Badge size="small">Sent</Badge> : <Badge size="small">Pending</Badge>}
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  <Button
                    plain
                    monochrome
                    onClick={() => {
                      handleEdit(row);
                    }}
                  >
                    Manage Interview Round
                  </Button>
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  <AccessPermission access={["manageJobs", "manageCandidate"]}>
                    {row?.assessment ? (
                      <InlineStack align="center" blockAlign="center" gap="200">
                        <Button
                          plain
                          monochrome
                          onClick={() => {
                            handleViewAssessment(row);
                          }}
                        >
                          View Assessment
                        </Button>
                      </InlineStack>
                    ) : (
                      <Button
                        plain
                        monochrome
                        onClick={() => {
                          sendAssessment(row);
                        }}
                      >
                        Send Assessment
                      </Button>
                    )}
                  </AccessPermission>
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [childRef, pagination]
  );

  const handleEdit = useCallback(
    (row) => {
      setSelectedCandidate(row);
      setIsCandidateModelOpen(true);
    },
    [jobStages]
  );

  const handleModalClose = () => {
    setIsCandidateModelOpen(false);
  };

  const handleMarkAsDone = useCallback(async (stage) => {
    // console.log(" - - handleMarkAsDone - - ");
    // console.log(stage);
    // console.log(selectedCandidate);
    try {
      if (selectedCandidate?._id && stage?._id) {
        await fetch.post("/stage/updatecandidate", {
          stageId: stage?._id,
          candidateId: selectedCandidate?._id,
          status: "done",
          stageTitle: stage?.stageDetails?.stageTitle,
        });
        // actionType === "add"
        //   ? showToast("Candidate Add successfully")
        //   : showToast("Candidate Updated successfully");
        // setCandidateFormValues(candidateDetailsInitialValues);
        // childRef.current.getStageCandidates();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleViewAssessment = useCallback(async () => {}, []);

  const sendAssessment = useCallback(
    async (e) => {
      try {
        if (e?._id) {
          await fetch.post("/stage/sendAssessment", {
            stageId: stageId,
            candidateId: e?._id,
            ...e,
          });
          // actionType === "add"
          //   ? showToast("Candidate Add successfully")
          //   : showToast("Candidate Updated successfully");
          // setCandidateFormValues(candidateDetailsInitialValues);
          // childRef.current.getStageCandidates();
        }
      } catch (error) {
        console.error("Error:", error);
      }
      // setIsCandidateModelOpen(false);
    },
    [stageId]
  );

  return (
    <>
      <CommonTable
        title="Candidates"
        selectable={false}
        url={`/stage/candidates/${stageId}`}
        rowsData={createRowsData}
        headings={[
          { title: "No" },
          { title: "Name" },
          { title: "Email" },
          { title: "Notice Period" },
          { title: "Total Experience" },
          { title: "Status" },
          { title: "Manage" },
          { title: "Action" },
        ]}
        ref={childRef}
        columnContentTypes={["string", "Action"]}
        isAdd={false}
        setParentPagination={setParentPagination}
      />
      <BannerModal
        open={isCandidateModelOpen}
        onClose={handleModalClose}
        title={"Manage Candidate"}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalClose,
          },
        ]}
        children={
          <BlockStack gap="200">
            {jobStages.map((stage, index) => (
              <InlineStack key={index} align="space-between" blockAlign="center">
                <Text variant="headingSm">{stage.stageDetails.stageTitle}</Text>
                {selectedCandidate &&
                (!selectedCandidate?.stages ||
                  Object.keys(selectedCandidate?.stages).indexOf(stage?.title) > -1) ? (
                  <InlineStack gap="200" align="end" blockAlign="center">
                    <Button onClick={() => handleMarkAsDone(stage)}>Mark as done</Button>
                  </InlineStack>
                ) : (
                  <Badge size="small" tone="success">
                    Done
                  </Badge>
                )}
              </InlineStack>
            ))}
          </BlockStack>
        }
      />
    </>
  );
});
