import React, { useCallback, useEffect, useState } from "react";
import { BlockStack, InlineError } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import { AITextGenerator } from "./AITextGenerator";
import ShopifyImages from "./ShopifyImages";
import VideoButton from "./VideoButton";

const TinyEditorComponent = (props) => {
  const {
    error,
    value,
    form: { setFieldValue },
    field: { name, label, toolbar, plugins, scrollHeight, aiButton = false, addImage = false, addVideo = false },
  } = props;

  const [editorState, setEditorState] = useState(value);
  const [aiGeneratedValue, setAiGeneratedValue] = useState();
  const [showAiModal, setShowAiModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const onEditorChange = useCallback((newValue, editor) => {
    setEditorState(newValue);
    setFieldValue(name, newValue);
  }, []);

  useEffect(() => {
    setEditorState(value ?? "");
  }, [value]);

  const handleEditorInit = (editor) => {
    editor.on("click", (e) => {
      const iframeElement = e.target.querySelector("iframe");
      if (iframeElement) {
        const source = iframeElement.getAttribute("src");
        const width = iframeElement.getAttribute("width");
        const height = iframeElement.getAttribute("height");
        setSelectedVideo({ source, width, height, embedCode: iframeElement.outerHTML });
      }
    });

    if (aiButton) {
      editor.ui.registry.addButton("aiButton", {
        text: "AI",
        onAction: () => {
          const selectedTextData = editor.selection.getContent({ format: "text" });
          if (selectedTextData) {
            setSelectedData(selectedTextData);
            setShowAiModal(true);
          }
        },
      });
    }

    if (addImage) {
      editor.ui.registry.addButton("addImage", {
        icon: "image",
        tooltip: "Add Image",
        onAction: () => setShowImageModal(true),
      });
    }
    if (addVideo) {
      editor.ui.registry.addButton("addVideo", {
        icon: "video",
        tooltip: "Add Media",
        onAction: () => setShowVideoModal(editor),
      });
    }
  };

  const closeModals = () => {
    setShowAiModal(false);
    setShowImageModal(false);
    setShowVideoModal(false);
    setAiGeneratedValue(null);
  };

  const handleAiSubmit = useCallback(async (data) => {
    const editor = tinymce.activeEditor;
    editor.selection.setContent(data);
    setEditorState(editor.getContent());
    setFieldValue(name, editor.getContent());
    setAiGeneratedValue("");
    closeModals();
  }, []);

  const handleImageSubmit = useCallback(async (data) => {
    const url = data.image?.originalSrc;
    const editor = tinymce.activeEditor;
    const imgElement = `<img src="${url}" style="margin-left: 10px; margin-right: 10px;">`;
    editor.execCommand("mceInsertContent", false, imgElement);
    closeModals();
  }, []);

  const handleVideoSubmit = useCallback((data) => {
    const editor = tinymce.activeEditor;
    let videoContent;

    if (data.embedCode) {
      let embedCode = data.embedCode;
      embedCode = data?.width ? embedCode.replace(/width="\d+"/, `width="${data?.width}"`) : embedCode;
      embedCode = data?.height ? embedCode.replace(/height="\d+"/, `height="${data?.height}"`) : embedCode;
      videoContent = embedCode;
    } else if (
      data.source.includes("youtube.com") ||
      data.source.includes("youtu.be") ||
      data.source.includes("vimeo.com")
    ) {
      videoContent = `
        <iframe width="${data.width}" height="${data.height}" src="${data.source}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      `;
    }
    editor.execCommand("mceInsertContent", false, videoContent);
    closeModals();
  }, []);

  return (
    <BlockStack gap="200">
      <span>{label}</span>
      <div className={error ? "editor-error-field" : ""}>
        <Editor
          tinymceScriptSrc={process.env.APP_URL + "/tinymce/tinymce.min.js"}
          value={editorState}
          onEditorChange={onEditorChange}
          init={{
            height: scrollHeight,
            menubar: false,
            statusbar: false,
            plugins: plugins,
            toolbar: `aiButton addImage addVideo ${toolbar}`,
            content_style: "body { font-size:0.8rem }",
            entity_encoding: "raw",
            setup: handleEditorInit,
            image_advtab: true,
            imagetools_toolbar: "editimage imageoptions editmedia | remove",
          }}
        />
      </div>
      {showAiModal && (
        <AITextGenerator
          selectedData={selectedData}
          showAiModal={showAiModal}
          setShowAiModal={setShowAiModal}
          aiGeneratedValue={aiGeneratedValue}
          setAiGeneratedValue={setAiGeneratedValue}
          closeModals={closeModals}
          handleAiSubmit={handleAiSubmit}
        />
      )}
      {showImageModal && (
        <ShopifyImages
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          closeModals={closeModals}
          handleImageSubmit={handleImageSubmit}
        />
      )}

      {showVideoModal && (
        <VideoButton
          showVideoModal={showVideoModal}
          setShowVideoModal={setShowVideoModal}
          closeModals={closeModals}
          handleVideoSubmit={handleVideoSubmit}
          selectedVideo={selectedVideo}
        />
      )}

      <InlineError message={error} />
    </BlockStack>
  );
};

export default TinyEditorComponent;
