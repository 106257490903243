import React, { useEffect, useRef, useState } from "react";
import { Banner, BlockStack, Button, ButtonGroup, InlineError, InlineStack } from "@shopify/polaris";
import { PlayMinor } from "@shopify/polaris-icons";
import "plyr/dist/plyr.css";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { useStopRecording } from "@/Context/StopRecordingContext";
import { logger } from "@/Services/Logger/Index";
import { removeFileFromServer, uploadFileToServer } from "@/Utils/Utils";
import { VideoDisplay } from "./VideoPlayer";

function VideoRecordInput(props) {
  const {
    value,
    settings,
    error,
    countdown,
    isCountdown,
    isPublic,
    form: { values, setFieldValue, errors, validateForm },
    field: { name, autoUpload, reRecordable, isTestScreen, isStopRecording, videoUrl, takeScreenshot },
  } = props;

  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const fetch = useAuthenticatedFetch();
  const [recordedBlob, setRecordedBlob] = useState(value?.recordedBlob ? value?.recordedBlob : null);
  const [isRecording, setIsRecording] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);
  const startButtonTimeoutRef = useRef(null);
  const [videoCountdown, setCountdown] = useState(countdown ? countdown : 30);
  const { handleStopRecording, stopRecordingContext } = useStopRecording();
  const [contdown, setIsCountdown] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [duration, setDuration] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const isMobile = screen.width <= 768; // Example threshold for mobile

  useEffect(() => {
    uploadVideo();
  }, [fetch, recordedBlob]);
  useEffect(() => {
    if (value?.id?.length > 0 && value?.assetUrl?.length > 0) {
      setVideoDetails(value);
      setVideoURL(value?.videoURL);
      setDuration(value?.duration);
    } else {
      setVideoDetails(null);
    }
  }, [value]);

  useEffect(() => {
    if (stopRecordingContext) {
      stopRecording();
    }
  }, [stopRecordingContext]);

  useEffect(() => {
    if (contdown && !isRecording) {
      startRecording();
    }
  }, [isRecording, value, contdown]);

  useEffect(() => {
    if (props?.countdown === 0) {
      setCountdown(0);
      setIsCountdown(true);
    }
    let interval;
    if (isNaN(videoCountdown) && videoCountdown !== 0) setCountdown(countdown ? countdown : 30);
    if ((videoCountdown || videoCountdown == 0) && isCountdown) {
      interval = setInterval(() => {
        if (videoCountdown > -1) {
          setCountdown(videoCountdown - 1);
        } else {
          setIsCountdown(true);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [videoCountdown, countdown]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const uploadVideo = async () => {
    if (recordedBlob) {
      const data = {
        file: recordedBlob,
        fileName: `video.webm`,
        isPublic: isPublic,
      };
      try {
        if (!autoUpload) {
          setRecordedBlob(null);
          setVideoDetails(null);
          setFieldValue(name, {
            recordedBlob,
            videoURL: videoURL,
            duration: duration,
            ...(takeScreenshot && { snapshot: snapshot }),
          });
        } else {
          if (!takeScreenshot) {
            const uploadedVideo = await uploadFileToServer(fetch, data);
            setVideoDetails(uploadedVideo);
            setFieldValue(name, {
              id: uploadedVideo._id,
              assetUrl: uploadedVideo.assetUrl,
              videoURL: videoURL,
              duration: duration,
              ...(takeScreenshot && { snapshot: snapshot }),
            });
          } else {
            setFieldValue(name, {
              videoURL: videoURL,
              duration: duration,
              ...(takeScreenshot && { snapshot: snapshot }),
            });
          }
          setRecordedBlob(null);
        }
      } catch (error) {
        logger.error(error);

        // console.error("Error:", error);
      }
    }
  };

  const takeSnapshot = async () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL("image/png");
      await uploadSnapshot(image);
    }
  };

  const uploadSnapshot = async (snapshot) => {
    const formData = new FormData();
    const [metadata, base64Data] = snapshot.split(";base64,");
    const mime = metadata.split(":")[1];
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const file = new File([uint8Array], "snapshot.png", { type: mime });
    formData.append("files", file, `${file.lastModified}.png`);
    try {
      if (takeScreenshot) {
        const response = await fetch.post("/upload-to-shopify", formData, false, {
          "Content-Type": " multipart/form-data",
        });
        setSnapshot(response?.data[0]?.filename);
      }
    } catch (error) {
      console.error("Error uploading snapshot:", error);
    }
  };

  const startRecording = () => {
    setCountdown(0);
    if (isTestScreen) {
      setFieldValue(name, { start: true, image: snapshot });
    }

    setIsRecording(true);
    handleStopRecording(false);

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        const startTime = Date.now();
        setStartTime(startTime);
        videoRef.current.srcObject = stream;

        videoRef.current.onloadedmetadata = () => {
          setTimeout(() => {
            takeSnapshot();
          }, 1000);
        };

        const mimeType = MediaRecorder.isTypeSupported("video/webm;codecs=vp9")
          ? "video/webm;codecs=vp9"
          : MediaRecorder.isTypeSupported("video/webm")
          ? "video/webm"
          : "video/mp4";

        const options = {
          mimeType: mimeType,
          videoBitsPerSecond: 150000, // Adjust as needed (150 Kbps)
        };
        const mediaRecorder = new MediaRecorder(stream, options);

        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunksRef.current.push(e.data);
          }
        };
        mediaRecorder.onstop = async () => {
          const videoBlob = new Blob(chunksRef.current, { type: mimeType });
          const videoURL = URL.createObjectURL(videoBlob);
          setVideoURL(videoURL);
          setRecordedBlob(videoBlob);
          const endTime = Date.now();
          const durationInSeconds = Math.round((endTime - startTime) / 1000);
          // Calculate duration in seconds
          setDuration(durationInSeconds);
          chunksRef.current = [];
        };

        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
      })
      .catch((error) => {
        logger.error(error);

        // console.error("Error accessing webcam: ", error);
      });
  };

  const stopRecording = async () => {
    if (videoRef.current) {
      // setDuration(videoRef.current.currentTime);
    }
    mediaRecorderRef?.current?.stop();
    videoRef?.current?.srcObject?.getTracks().forEach((track) => track.stop());
    setIsRecording(false);
    setIsCountdown(false);
    setCountdown(countdown);
  };

  const reRecordVideo = async () => {
    if (takeScreenshot) {
      setVideoURL(null);
    } else {
      const removeRecordedVideo = await removeFileFromServer(fetch, videoDetails.id);
      if (removeRecordedVideo) {
        setVideoDetails(null);
        setFieldValue(name, null);
        setVideoURL(null);
        setDuration(null);
      }
    }
  };

  return (
    <InlineStack align={takeScreenshot ? "center" : "start"}>
      <BlockStack gap="200" inlineAlign="center">
        {isCountdown && videoCountdown > 0 && !isRecording ? (
          <Banner
            tone="info"
            title={
              isMobile
                ? `You have ${formatTime(videoCountdown)} seconds  to read the question.`
                : `Prepare your answer. You have ${formatTime(
                    videoCountdown
                  )} seconds to read the question. When the timer ends, you must respond.`
            }
          />
        ) : (
          <></>
        )}
        {isRecording && (
          <>
            <video
              className="video-screen"
              width={settings.width ? settings.width : "215px"}
              height={settings.height ? settings.height : "160px"}
              ref={videoRef}
              autoPlay
              playsInline
              muted
              style={{
                width: "100%",
                maxWidth: settings.width ? settings.width : "215px",
                maxHeight: settings.height ? settings.height : "160px",
                height: "auto",
              }}
            />
            {isStopRecording && (
              <Button size="micro" icon={PlayMinor} onClick={stopRecording} className="start-button">
                Stop Recording
              </Button>
            )}
          </>
        )}
        {!isRecording && !videoDetails?.assetUrl?.length > 0 && !videoURL && (
          <InlineStack align="center">
            <Button size="micro" icon={PlayMinor} onClick={startRecording} className="start-button">
              Start Recording
            </Button>
          </InlineStack>
        )}
        {!isRecording && (videoDetails?.assetUrl?.length > 0 || (videoURL && !isRecording && takeScreenshot)) && (
          <>
            {reRecordable && duration > 0 && (
              <InlineStack>
                <VideoDisplay
                  url={videoUrl ? videoURL : `${process.env.APP_URL}/uploads/${videoDetails.assetUrl}`}
                  duration={Math.floor(duration)}
                  settings={{
                    width: settings.width ? settings.width : "400px",
                    height: settings.height ? settings.height : "320px",
                  }}
                />
              </InlineStack>
            )}
            <ButtonGroup>
              {reRecordable && (
                <Button icon={PlayMinor} onClick={reRecordVideo} className="start-button">
                  Re-record
                </Button>
              )}
            </ButtonGroup>
          </>
        )}
        <InlineError message={error} />
      </BlockStack>
    </InlineStack>
  );
}

export default VideoRecordInput;
