import "./Template3.scss";

export function Template3({ resumeData }) {
  function addEducation(value) {
    return (
      <>
        <span className="degree">
          <span className="txt-bold">{value?.degreeEarned}</span>
        </span>
        <span className="disp-block">
          <span>{value?.institutionName}</span>

          <span> {value?.location}</span>
        </span>
        <span className="disp-block txt-italic">
          {value?.graduationDate && (
            <span>
              <span className="xslt_static_change displayNoneThisField">Expected in </span>
              <span>{value?.graduationDate}</span>
            </span>
          )}
        </span>
      </>
    );
  }

  function addWorkExpireance(value) {
    const dateRange =
      value?.endDate === "Present" ? `${value?.startDate} - Present` : `${value?.startDate} - ${value?.endDate}`;
    return (
      <>
        <span className="jobtitle txt-bold disp-block txt-capitalize">{value?.jobTitle}</span>
        <span className="txt-italic">
          <span>{value?.companyName}</span>
          <span>
            <span> | </span>
          </span>
          <span>{value?.location}</span>
          <span>
            <span> | </span>
          </span>
          <span>{dateRange}</span>
        </span>
        <span>
          <ul>
            {value?.responsibilities &&
              value?.responsibilities.map((responsibility, index) => <li key={index}>{responsibility}</li>)}
          </ul>
        </span>
      </>
    );
  }

  function projectExpireance(value) {
    const dateRange =
      value.endDate === "Present" ? `${value?.startDate} - Present` : `${value?.startDate} - ${value?.endDate}`;

    return (
      <>
        <h3>
          {value?.startDate || (value?.endDate && <span>{dateRange} &nbsp; &nbsp;</span>)}
          <span>
            <strong>{value.projectName}</strong>
          </span>
        </h3>
        <a href={value?.projectLink}>{value?.projectLink}</a>
        {value?.projectDescription && <p>Description: {value?.projectDescription}</p>}
        {value?.roleResponsibility && <p>Responsibility:{value?.roleResponsibility}</p>}

        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {value?.technologiesUsed &&
            value?.technologiesUsed.map((technology, index) => (
              <li style={{ width: "150px" }} className="technology" key={index}>
                {technology}
              </li>
            ))}
        </ul>
      </>
    );
  }

  return (
    <div className="document doc-root fontsize fontface vmargins hmargins pagesize skn-mlf9 MLF9 RNA">
      <div className="top-section">
        <div className="left-box">
          <div className="sortable-item section-container SortableItem-sibling data-NAME">
            <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
            <div className="section name-sec notdraggable SECTION_NAME">
              <div className=" doc-item">
                <div className="paragraph PARAGRAPH_NAME firstparagraph">
                  <div className="name txt-bold">
                    <span>
                      {resumeData?.PersonalInformation?.firstName} {resumeData?.PersonalInformation?.lastName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="parent-container">
        <div className="left-box">
          {resumeData?.ProfessionalSummary?.summary && (
            <div className="sortable-item section-container SortableItem-sibling  data-SUMM" id="summary">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section SECTION_SUMM has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">
                      Summary<span className="rename-section text-rename"></span>
                    </div>
                  </div>

                  <div className="paragraph PARAGRAPH_SUMM firstparagraph">
                    <div className="clearfix doc-item">
                      <div className="singlecolumn">
                        <p>{resumeData?.ProfessionalSummary?.summary}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resumeData?.WorkExperience?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-EXPR" id="workExperience">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section expr-sec SECTION_EXPR multi-para has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">
                      Experience<span className="rename-section text-rename"></span>
                    </div>
                  </div>
                  <div className="sortableInner">
                    <div className="sortable-item paragraph-container SortableItem-sibling ">
                      <div className="paragraph PARAGRAPH_EXPR firstparagraph">
                        <div className="clearfix doc-item">
                          <div className="singlecolumn">
                            <span className="disp-block">
                              <div id="workExperienceData" style={{ paddingTop: "10px" }}>
                                {resumeData.WorkExperience.map((e, index) => (
                                  <div style={{ marginBottom: "10px" }} className="printable-content" key={index}>
                                    {addWorkExpireance(e)}
                                  </div>
                                ))}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resumeData?.Project?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-EXPR" id="project">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section expr-sec SECTION_EXPR multi-para has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">
                      Project<span className="rename-section text-rename"></span>
                    </div>
                  </div>
                  <div className="sortableInner">
                    <div className="sortable-item paragraph-container SortableItem-sibling ">
                      <div className="paragraph PARAGRAPH_EXPR firstparagraph">
                        <div className="clearfix doc-item">
                          <div className="singlecolumn">
                            <span className="disp-block">
                              <div id="workExperienceData" style={{ paddingTop: "10px" }}>
                                {resumeData?.Project.map((e, index) => (
                                  <div style={{ marginBottom: "10px" }} className="printable-content" key={index}>
                                    {projectExpireance(e)}
                                  </div>
                                ))}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resumeData?.Certification?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-EXPR" id="certification">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section expr-sec SECTION_EXPR multi-para has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">
                      Certification<span className="rename-section text-rename"></span>
                    </div>
                  </div>
                  <div className="sortableInner">
                    <div className="sortable-item paragraph-container SortableItem-sibling ">
                      <div className="paragraph PARAGRAPH_EXPR firstparagraph">
                        <div className="clearfix doc-item">
                          <div className="singlecolumn">
                            <span className="disp-block">
                              <div id="workExperienceData" style={{ paddingTop: "10px" }}>
                                <ul>
                                  {resumeData?.Certification.map((certification, index) => (
                                    <li key={index} style={{ padding: "5px" }}>
                                      {certification?.certificationName}-{certification?.issuingOrganization}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resumeData?.Award?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-EXPR" id="award">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section expr-sec SECTION_EXPR multi-para has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">
                      Award<span className="rename-section text-rename"></span>
                    </div>
                  </div>
                  <div className="sortableInner">
                    <div className="sortable-item paragraph-container SortableItem-sibling ">
                      <div className="paragraph PARAGRAPH_EXPR firstparagraph">
                        <div className="clearfix doc-item">
                          <div className="singlecolumn">
                            <span className="disp-block">
                              <div id="workExperienceData" style={{ paddingTop: "10px" }}>
                                <ul>
                                  {resumeData?.Award.map((award, index) => (
                                    <>
                                      <h3>
                                        <span>{award?.dateReceived} </span>
                                        <span>{award?.awardingOrganization} </span>
                                      </h3>
                                      <li key={index} style={{ padding: "5px" }}>
                                        {award?.awardName}
                                      </li>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resumeData?.Language?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-EXPR" id="language">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section expr-sec SECTION_EXPR multi-para has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">
                      Language<span className="rename-section text-rename"></span>
                    </div>
                  </div>
                  <div className="sortableInner">
                    <div className="sortable-item paragraph-container SortableItem-sibling ">
                      <div className="paragraph PARAGRAPH_EXPR firstparagraph">
                        <div className="clearfix doc-item">
                          <div className="singlecolumn">
                            <span className="disp-block">
                              <div id="workExperienceData" style={{ paddingTop: "10px" }}>
                                <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                  {resumeData?.Language.map((language, index) => (
                                    <li className="skills" key={index}>
                                      {language?.languageName}-{language?.proficiencyLevel}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="right-box">
          <div className="sortable-item section-container SortableItem-sibling  data-CNTC">
            <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
            <div className="section notdraggable cntc-sec SECTION_CNTC has-title" id="personalInformation">
              <div className=" doc-item">
                <div className="heading">
                  <div className="sectiontitle">
                    Contact<span className="rename-section text-rename"></span>
                  </div>
                </div>

                <div className="paragraph PARAGRAPH_CNTC firstparagraph">
                  <div className="clearfix doc-item">
                    <div className="address">
                      {resumeData?.PersonalInformation?.phoneNumber && (
                        <div className="icon-row">
                          <div className="icon-svg">
                            <svg width="10px" height="10px" viewBox="0 0 10 11">
                              <path
                                d="M1.31641074,10.2480523 C6.09959102,10.249997 9.99997616,6.37696319 9.99997616,1.56837709 C9.99997616,1.15040171 9.71482822,0.791039011 9.30661844,0.69729305 L7.46677526,0.27346212 C7.04490889,0.175806103 6.61522242,0.394559396 6.44529713,0.792984502 L5.59568978,2.77540165 C5.43944016,3.13869348 5.54491628,3.56837995 5.85154092,3.81837554 L6.63278906,4.45704971 C6.07420427,5.48047333 5.23045246,6.32422513 4.20702885,6.88086443 L3.56835468,6.09961629 C3.31835909,5.79297258 2.88867262,5.68751553 2.52538079,5.84376515 L0.54101815,6.6933725 C0.14257397,6.86327871 -0.0742147584,7.29296519 0.0214766944,7.71485063 L0.445307624,9.55469381 C0.539053585,9.96288451 0.898435358,10.2480523 1.31641074,10.2480523 Z M1.35350858,9.31249925 L0.945317876,7.54101529 L2.86326679,6.71875925 L3.95115864,8.04882658 C5.88083772,7.14259018 6.89256932,6.13476864 7.79880572,4.2011795 L6.46873839,3.11328766 L7.29099443,1.19533874 L9.06247839,1.60352944 C9.04294719,5.85353076 5.60350989,9.29296805 1.35350858,9.31249925 Z"
                                id="phone"
                                fill="#2A2A2A"
                                fill-rule="nonzero"
                              ></path>
                            </svg>
                          </div>
                          <div className="ico-txt">
                            <span>{resumeData?.PersonalInformation?.phoneNumber}</span>
                          </div>
                        </div>
                      )}
                      {resumeData?.PersonalInformation?.emailAddress && (
                        <div className="icon-row">
                          <div className="icon-svg">
                            <svg width="10px" height="10px" viewBox="0 0 10 8">
                              <path
                                d="M9.06247839,8 C9.58024602,8 9.99997616,7.58026986 9.99997616,7.06250224 L9.99997616,1.43751565 C9.99997616,0.919748021 9.58024602,0.500017881 9.06247839,0.500017881 L0.937497765,0.500017881 C0.419730139,0.500017881 0,0.919748021 0,1.43751565 L0,7.06250224 C0,7.58026986 0.419730139,8 0.937497765,8 L9.06247839,8 Z M4.99998808,5.18743041 C4.54670776,5.19475461 3.89493014,4.57299859 3.56614216,4.31436273 C2.07381708,3.1458093 1.37548119,2.59117272 0.937497765,2.2344803 L0.937497765,1.43751565 L9.06247839,1.43751565 L9.06247839,2.2344803 C8.62455219,2.5911155 7.92638796,3.14563764 6.433834,4.31436273 C6.10491251,4.57309396 5.45334469,5.19467832 4.99998808,5.18743041 Z M9.06247839,7.06250224 L0.937497765,7.06250224 L0.937497765,3.43748036 C1.38503698,3.7939439 2.01970573,4.29414488 2.9870725,5.05164659 C3.41395518,5.38768247 4.16155777,6.1295058 4.99998808,6.1249854 C5.83429853,6.1295058 6.57242162,5.39843989 7.012732,5.05179917 C7.9800797,4.29431654 8.6149201,3.79398204 9.06247839,3.43748036 L9.06247839,7.06250224 Z"
                                fill="#2A2A2A"
                                fill-rule="nonzero"
                              ></path>
                            </svg>
                          </div>
                          <div className="ico-txt">
                            <span>{resumeData?.PersonalInformation?.emailAddress}</span>
                          </div>
                        </div>
                      )}
                      {resumeData?.PersonalInformation?.mailingAddress && (
                        <div className="icon-row">
                          <div className="icon-svg">
                            <svg width="8px" height="10px" viewBox="0 0 8 11">
                              <path
                                d="M4.54544891,9.80905721 C7.03270189,5.82099147 7.49998212,5.38873008 7.49998212,4.00001192 C7.49998212,1.92781609 5.8200316,0.250020862 3.74999106,0.250020862 C1.67779522,0.250020862 0,1.92997139 0,4.00001192 C0,5.38901618 0.468577221,5.82307047 2.95451414,9.80905721 C3.32139177,10.3973203 4.17899089,10.3966146 4.54544891,9.80905721 Z M3.74999106,9.31249925 C1.26125035,5.32199211 0.937497765,5.07008917 0.937497765,4.00001192 C0.937497765,2.44670904 2.19668818,1.18751863 3.74999106,1.18751863 C5.30329394,1.18751863 6.56248435,2.44670904 6.56248435,4.00001192 C6.56248435,5.06549247 6.26627382,5.27783709 3.74999106,9.31249925 Z M3.74999106,5.5625082 C4.61293074,5.5625082 5.31248733,4.86295161 5.31248733,4.00001192 C5.31248733,3.13707224 4.61293074,2.43751565 3.74999106,2.43751565 C2.88705137,2.43751565 2.18749478,3.13707224 2.18749478,4.00001192 C2.18749478,4.86295161 2.88705137,5.5625082 3.74999106,5.5625082 Z"
                                id="address"
                                fill="#2A2A2A"
                                fill-rule="nonzero"
                              ></path>
                            </svg>
                          </div>
                          <div className="ico-txt">
                            <span>{resumeData?.PersonalInformation?.mailingAddress}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {resumeData?.Skills?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-HILT" id="skill">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section SECTION_HILT has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle" style={{ marginBottom: "10px" }}>
                      Skills<span className="rename-section text-rename"></span>
                    </div>
                  </div>
                  <div className="paragraph PARAGRAPH_HILT firstparagraph">
                    <div className="clearfix doc-item">
                      <div className="singlecolumn maincolumn">
                        <div className="skill">
                          <span className="paddedline">
                            <ul style={{ display: "flex", flexWrap: "wrap" }}>
                              {resumeData?.Skills.map((skill, index) => (
                                <li className="skills" key={index}>
                                  {skill}
                                </li>
                              ))}
                            </ul>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resumeData?.Hobby?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-HILT" id="hobby">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section SECTION_HILT has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">
                      Hobby<span className="rename-section text-rename"></span>
                    </div>
                  </div>
                  <div className="paragraph PARAGRAPH_HILT firstparagraph">
                    <div className="clearfix doc-item">
                      <div className="singlecolumn maincolumn">
                        <div className="skill">
                          <span className="paddedline">
                            <ul style={{ display: "flex", flexWrap: "wrap" }}>
                              {resumeData?.Hobby.map((hobby, index) => (
                                <li className="skills" key={index}>
                                  {hobby}
                                </li>
                              ))}
                            </ul>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resumeData?.Education?.length > 0 && (
            <div className="sortable-item section-container SortableItem-sibling  data-EDUC" id="education">
              <div className="document-tool sec-tool" style={{ right: "-42px" }}></div>
              <div className="section educ-sec SECTION_EDUC multi-para has-title">
                <div className=" doc-item">
                  <div className="heading">
                    <div className="sectiontitle">Education</div>
                  </div>
                  <div className="paragraph PARAGRAPH_EDUC firstparagraph">
                    <div className="clearfix doc-item">
                      <div className="singlecolumn">
                        <span className="disp-block">
                          <div style={{ paddingTop: "10px" }}>
                            {resumeData?.Education.map((e, index) => (
                              <div style={{ marginBottom: "10px" }} className="printable-content" key={index}>
                                {addEducation(e)}
                              </div>
                            ))}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
