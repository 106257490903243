export const TotalQuestionsFields = [
  {
    id: "customQuestion",
    name: "customQuestion",
    label: "Add custom question to start",
    labelPosition: "right",
    validated: false,
    type: "switch",
  },
  {
    id: "totalQuestions",
    name: "totalQuestions",
    label: "Select Number of Questions (Including Custom Questions)",
    validated: true,
    type: "rangeSlider",
    max: 30,
    min: 5,
  },
];

export const TotalQuestionsInitialValues = {
  customQuestion: true,
  totalQuestions: "10",
};
