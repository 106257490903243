import { BlockStack, Page, Text } from "@shopify/polaris";

export default function TermsAndConditions() {
  return (
    <div className="Privacy_Policy">
      <Page title="Recruitments.AI Terms of Service" divider>
        <BlockStack gap="500">
          <Text>
            Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the
            Recruitments.AI software (the "Service") operated by Webrex ("us", "we", or "our").
          </Text>
          <Text variant="headingXl" as="h4">
            Acceptance of Terms
          </Text>
          <Text>
            By accessing and using our Service, you agree to be bound by these Terms. If you disagree with any part
            of the terms, then you may not access the Service.
          </Text>
          <Text variant="headingXl" as="h4">
            Use of Service
          </Text>
          <Text>
            Your access to and use of the Service is conditioned on your acceptance of and compliance with these
            Terms. These Terms apply to all visitors, users and others who access or use the Service.
          </Text>
          <Text variant="headingXl" as="h4">
            Accounts{" "}
          </Text>
          <Text>
            When installing Recruitment.AI software, you are required to provide us with information that is
            accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which
            may result in immediate termination of your account on our Service.
          </Text>
          <Text variant="headingXl" as="h4">
            Termination{" "}
          </Text>
          <Text>
            We may terminate or suspend access to our Service immediately, without prior notice or liability, for
            any reason whatsoever, including without limitation if you breach the Terms.
          </Text>
          <Text variant="headingXl" as="h4">
            Governing Law
          </Text>
          <Text>
            These Terms shall be governed and construed in accordance with the laws of Gujarat, India, without
            regard to its conflict of law provisions.
          </Text>
          <Text variant="headingXl" as="h4">
            Changes{" "}
          </Text>
          <Text>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. In case of
            changes in it, it will be reflacted here, without any prior notice. What constitutes a material change
            will be determined at our sole discretion.
          </Text>
          <Text variant="headingXl" as="h4">
            Contact Us
          </Text>
          <Text>
            If you have any questions about these Terms, please contact us by e-mail at hello@webrexstudio.com or
            by mail using the details provided below:
          </Text>
          <Text>209, Saumya Square near Goverdhan party plot, Thaltej 380052, Ahmedabad, India.</Text>

          <Text></Text>
        </BlockStack>
      </Page>
    </div>
  );
}
