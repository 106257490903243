export const assessmentFormFields = [
  {
    id: "jobDescription",
    name: "jobDescription",
    label: "Job Description",
    validated: true,
    type: "text",
    placeholder: "Add Job Description",
  },
];

export const assessmentFormitialValues = {
  jobDescription: "",
};

export const assessmentPreviewFormFields = [
  {
    id: "logo",
    name: "logo",
    label: "Company Logo",
    type: "imagePicker",
    allowMultiple: false,
    fileType: "image",
    accept: "image/*",
  },
  {
    id: "title",
    name: "title",
    label: "Company Title",
    validated: true,
    type: "text",
    placeholder: "Add Title of Company",
    errormsg: "Title is required",
  },
];

export const assessmentPreviewFormitialValues = {
  logo: "",
  title: "",
};
