import React, { useCallback, useEffect, useState } from "react";
import { BlockStack, Box, Button, Card, Divider, InlineStack, Tabs, Text } from "@shopify/polaris";
import { PlayMinor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import ProgressCircle from "@/Components/Common/ProgressCircle";
import VideoPlayer from "@/Components/Common/VideoPlayer";
import { VideoDisplay } from "@/Components/Common/VideoPlayer";

export const ResultBlock = (props) => {
  const { item, index, type } = props;
  const [width, setWidth] = useState("100%");
  const [question, setQuestion] = useState();
  const [heading, setHeading] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [answerValidationText, setAnswerValidationText] = useState("");
  const [isAnswerInText, setIsAnswerInText] = useState(false);
  const [isQuestionInText, setIsQuestionInText] = useState(false);

  useEffect(() => {
    setQuestion(item);
  }, [item]);

  useEffect(() => {
    if (type == "question") {
      setHeading(`Question: ${index + 1} ${question?.question}`);
    } else {
      setHeading(`Answer: ${index + 1}`);
    }
    if (question?.answer?.answerVideoText) {
      setAnswerText(question?.answer?.answerVideoText);
    }
    if (question?.answer?.validationResponse) {
      setAnswerValidationText(question?.answer?.validationResponse);
    }
    if (
      question?.answerType == "text" ||
      question?.answerType == "number" ||
      question?.answerType == "checkbox" ||
      question?.answerType == "radio"
    ) {
      setIsAnswerInText(true);
    }
    if (question?.questionType == "text" || question?.questionType == "number") {
      setIsQuestionInText(true);
    }
  }, [question]);

  const textAnswerBlock = () => {
    return (
      <BlockStack gap="200">
        <Text variant="headingMd">{heading}</Text>
        <Divider />
        <Text alignment="justify" variant="bodyMd">
          Answer: {answerText}
        </Text>
        {question.pair != "video-number" && question.pair != "text-number" && answerValidationText?.length > 0 && (
          <Text alignment="justify" variant="bodyMd">
            Validation: {answerValidationText}
          </Text>
        )}
        {question.pair != "video-number" &&
          question.pair != "text-number" &&
          question?.answer?.evaluationScore?.toString()?.length > 0 && (
            <Text alignment="justify" variant="bodyMd">
              Evaluation Score: {question?.answer?.evaluationScore} /10
            </Text>
          )}
      </BlockStack>
    );
  };

  return (
    <div className={`${type}`}>
      <BlockStack gap="200">
        {type == "question" && isQuestionInText && textAnswerBlock()}
        {type == "question" && question?.questionType == "video" && (
          <BlockStack gap="200">
            <Text variant="headingMd">{heading}</Text>
            <Divider />
            <div className="desplayVideo">
              <VideoDisplay
                url={`${process.env.APP_URL}/uploads/${question?.questionVideo?.assetUrl}`}
                settings={{ height: "320px", width: "400px" }}
              />
            </div>
          </BlockStack>
        )}
        {type == "answer" && question?.answer?.answerVideoURL && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box paddingBlockEnd={200}>
              <Text variant="headingMd">{heading}</Text>
            </Box>
            <Divider />
            <Box paddingBlockEnd={200}></Box>
            <VideoDisplay
              url={
                question?.answer?.videoURL
                  ? `${process.env.APP_URL}/uploads/${question?.answer?.answerVideoURL}`
                  : `${process.env.APP_URL}/uploads/${question?.answer?.answerVideoURL}`
              }
              duration={Math.floor(question?.answer?.duration)}
              settings={{ height: "320px", width: "100%" }}
            />
          </div>
        )}
        {type == "answer" &&
          question?.pair !== "text-number" &&
          question?.pair !== "text-text" &&
          question?.pair !== "text-checkbox" &&
          question?.pair !== "text-radio" &&
          isAnswerInText &&
          textAnswerBlock()}
      </BlockStack>
    </div>
  );
};
