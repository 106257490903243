import React from "react";
import { Text } from "@shopify/polaris";

export const freeFeatures = [
  {
    text: "Job Credit - 3",
    icon: "",
  },
  {
    text: "Member Credit - 3",
    icon: "",
  },
  {
    text: "30 Days Video Backup",
    icon: "",
  },
  {
    text: "Assessment Evolution by Ai",
    icon: "",
  },
  {
    text: "Unlimited Candidates",
    icon: "",
  },
  {
    text: "Customized Access Level",
    icon: "",
  },
  {
    text: "Bulk Upload Candidate",
    icon: "",
  },
  {
    text: "Multiple Organizations Access",
    icon: "",
  },
  {
    text: "24*7 Live Chat Support",
    icon: "",
  },
];

export const paidFeatures = [
  {
    text: "Job Credit - 10",
    icon: "",
  },
  {
    text: "Member Credit - 5",
    icon: "",
  },
  {
    text: "30 Days Video Backup",
    icon: "",
  },
  {
    text: "Assessment Evolution by Ai",
    icon: "",
  },
  {
    text: "Unlimited Candidates",
    icon: "",
  },

  {
    text: "Customized Access Level",
    icon: "",
  },
  {
    text: "Bulk Upload Candidate",
    icon: "",
  },
  {
    text: "Multiple Organizations Access",
    icon: "",
  },
  {
    text: "24*7 Live Chat Support",
    icon: "",
  },
];

export const featureList = {
  Premium: paidFeatures,
};

export const interval = {
  Premium: "",
  Default: "",
};

export const getPlanData = () => [
  {
    type: "free",
    id: "Free",
    name: "Free",
    price: 0,
    jobInterview: 10,
    jobCredit: 3,
    Job: "Unlimited",
    candidate: "Unlimited",
    userCredit: 3,
    isHidden: false,
    features: [...freeFeatures],
  },
  {
    type: "recurring",
    intervalLable: "Month",
    interval: "EVERY_30_DAYS",
    id: "Premium-Monthly",
    name: "Premium",
    originalPrice: 50,
    price: 49,
    jobInterview: 50,
    jobCredit: 10,
    video: 30,
    Job: "Unlimited",
    candidate: "Unlimited",
    userCredit: 5,
    isHidden: false,
    features: [...paidFeatures],
    badge: {
      text: "POPULAR",
    },
  },
  // {
  //   type: "onetime",
  //   intervalLable: "Lifetime",
  //   interval: "",
  //   id: "Premium",
  //   name: "App Sumo",
  //   originalPrice: 499,
  //   price: 49,
  //   jobInterview: 100,
  //   video: 30,
  //   Job: "Unlimited",
  //   candidate: "Unlimited",
  //   members: "Unlimited",
  //   isHidden: true,
  //   features: [...paidFeatures],
  //   badge: {
  //     text: "POPULAR",
  //   },
  // },
];

export const icon = {
  icon: "CircleTickMajor",
  color: "sucess",
};
