import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, BlockStack, Button, Card, Icon, InlineStack, Layout, ResourceList, Text } from "@shopify/polaris";
import { AddMajor, EditMajor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { setLocalStorageItem } from "@/Utils/Index";
import { UserContext } from "../../../Context/UserContext";
import CountryData from "./../../../Assets/Mocks/countries.json";

export const GeneralSettings = () => {
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const [organizations, setOrganizations] = useState([]);
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (!userProfileData) return;

      const response = await fetch.get(`/organizations`);
      setOrganizations(response.data);
    };

    fetchOrganizations();
  }, [userProfileData]);

  const handleEditOrganization = (organization) => {
    navigate("/organization", { state: { organization, isEdit: true } });
  };

  const addOrganization = () => {
    navigate("/organization");
  };

  const handleOrganizationSelect = async (orgId) => {
    const res = await fetch.post("/updateToken", {
      orgId: orgId,
      _id: userProfileData?._id,
      email: userProfileData?.email,
    });
    updateProfileInfo(res.data);
    setLocalStorageItem("authorization", res.data.token);
  };

  function getItemNameByCountryCode(countryCode) {
    const country = CountryData[countryCode];
    return country ? country.itemName : null;
  }

  return (
    <Layout sectioned>
      <Layout.Section>
        <BlockStack gap="200">
          <InlineStack align="end">
            <Button align="end" onClick={() => addOrganization()}>
              <InlineStack blockAlign="center">
                <Icon source={AddMajor} tone="base" />
                <Text>Add Organization</Text>
              </InlineStack>
            </Button>
          </InlineStack>
          <Card>
            <BlockStack gap="200">
              {organizations.flat().length > 0 ? (
                <ResourceList
                  resourceName={{ singular: "organization", plural: "organizations" }}
                  items={organizations.flat()}
                  renderItem={(organization) => {
                    const { _id, name, companySize, domain, website, linkedInProfile, country } = organization;

                    return (
                      <ResourceList.Item id={_id} accessibilityLabel={`View details for ${name}`}>
                        <BlockStack gap="100">
                          <InlineStack align="space-between">
                            <Text variant="headingLg">{name}</Text>
                            <InlineStack gap="100" align="end" blockAlign="center">
                              {userProfileData &&
                              userProfileData?.organizations &&
                              userProfileData?.organizations.find((organization) => organization.orgId === _id)
                                .selected ? (
                                <Badge tone="success">Selected</Badge>
                              ) : (
                                <Button onClick={() => handleOrganizationSelect(organization._id)}>Select</Button>
                              )}

                              <Button onClick={() => handleEditOrganization(organization)}>
                                <Icon source={EditMajor} tone="base" />
                              </Button>
                            </InlineStack>
                          </InlineStack>
                          {companySize && <Text variant="bodyMd">Company Size: {companySize}</Text>}
                          {domain && <Text variant="bodyMd">Domain: {domain}</Text>}
                          {website && <Text variant="bodyMd">Website: {website}</Text>}
                          {linkedInProfile && <Text variant="bodyMd">LinkedIn Profile: {linkedInProfile}</Text>}
                          {country && <Text variant="bodyMd">Country: {getItemNameByCountryCode(country)}</Text>}
                        </BlockStack>
                      </ResourceList.Item>
                    );
                  }}
                />
              ) : (
                <InlineStack align="space-between">
                  <Text variant="bodyLg">No organization found</Text>
                  <Button onClick={() => addOrganization()}>
                    <Icon source={AddMajor} tone="base" />
                  </Button>
                </InlineStack>
              )}
            </BlockStack>
          </Card>
        </BlockStack>
      </Layout.Section>
    </Layout>
  );
};
