import React, { useCallback, useContext, useRef, useState } from "react";
import { Badge, Banner, BlockStack, Button, InlineStack, Modal, Text, Tooltip } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";

const formField = [
  {
    id: "resumes",
    name: "resumes",
    label: "Resume",
    type: "fileUpload",
    validated: true,
    allowMultiple: true,
    size: "small",
    min: 1,
    errormsg: "Resume is required",
    requiredIndicator: true,
    fileType: "file",
    accept: ".pdf, .doc, .docx",
    showTotal: true,
  },
];

export function ImportBulkResume({ jobData, resumeCount }) {
  const [openModal, setOpenModal] = useState(false);
  const [formValue, setFormValue] = useState({});
  const { showToast } = useContext(ToastContext);
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const remainingCredit = 50 - resumeCount;

  const submitResume = () => {};
  const closeModal = () => {
    setOpenModal(false);
    setFormValue({});
  };

  const addResumes = useCallback(
    async (e) => {
      await fetch.post(`/uploadBulkResume`, { ...formValue, jobData });
      closeModal();
      showToast(
        "Operation is created and working in the background. You will receive an email once it has been completed."
      );
    },
    [formValue, jobData]
  );

  return (
    <>
      <Tooltip
        content={
          "Upload multiple resumes in bulk to automatically extract candidate information, calculate resume scores, and generate detailed summaries instantly."
        }
      >
        <Button size="large" disabled={!jobData.isActive} onClick={() => setOpenModal(true)}>
          Import Multiple Resumes
        </Button>
      </Tooltip>
      <Modal
        open={openModal}
        onClose={closeModal}
        title={"Upload Bulk Resumes (Accepts .pdf .doc .docx)"}
        primaryAction={{
          content: "Import Multiple Resumes",
          onAction: addResumes,
          // disabled: remainingCredit < 1 || formValue?.resumes?.length > remainingCredit,
          disabled: formValue?.resumes?.length > 50 || formValue?.resumes?.length < 1,
        }}
        secondaryActions={[{ content: "Cancel", onAction: closeModal }]}
      >
        <Modal.Section>
          <BlockStack gap={300}>
            {/* <InlineStack gap={300}>
              <Badge size="small" tone="success">
                <Text fontWeight="bold"> Total Credit : 50</Text>
              </Badge>
              <Badge size="small" tone="success">
                <Text fontWeight="bold">Remaining Credit : {remainingCredit}</Text>
              </Badge>
            </InlineStack> */}

            <Banner tone="info" title="A maximum of 50 resumes can be uploaded." />
            <CommonForm
              onSubmit={submitResume}
              formRef={formRef}
              initialValues={formValue}
              formFields={formField}
              isSave={false}
              onFormChange={(e) => setFormValue(e)}
            />
          </BlockStack>
        </Modal.Section>
      </Modal>
    </>
  );
}
