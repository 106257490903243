export const formFields = [
  {
    id: "email",
    name: "email",
    label: "Email",
    validated: true,
    type: "email",
    placeholder: "Enter your Email",
    errormsg: "Email is required",
  },
];

export const initialValues = {
  email: "",
};
