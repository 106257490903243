import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Frame,
  Image,
  IndexTable,
  InlineStack,
  Link,
  Loading,
  Modal,
  Page,
  Tabs,
  Text,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMinor, EditMinor, ViewMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import parse from "html-react-parser";
import { getCountryCallingCode } from "libphonenumber-js";
import Papa from "papaparse";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { Whatsapp } from "@/Assets/Index";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import ProgressCircle from "@/Components/Common/ProgressCircle";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import { setLocalStorageFilter } from "@/Utils/AppUtils.js";
import {
  bulkSendAssessmentFormFields,
  bulkSendAssessmentInitialValues,
  candidateDetailsInitialValues,
  getCandidateDetailFields,
  getFilterField,
  getImportCandidateDetailFields,
  sendAssessmentFields,
  sendAssessmentInitialValues,
  totalExperience,
} from "../../../Assets/Mocks/Candidate.mock";
import { TotalQuestionsFields, TotalQuestionsInitialValues } from "../../../Assets/Mocks/ToalQuestion.mock";
import { AccessPermission } from "../../../Components/Common/AccessPermission";
import StarCandidate from "../../../Components/Common/StarCandidate";
import { UserContext } from "../../../Context/UserContext";
import { removeSessionStorageItem } from "../../../Utils/Index.js";
import Plan from "../../Plan/Plan.jsx";
import CandidateAction from "../Candidate/CandidateAction";
import CandidateStatus from "../Candidate/CandidateStatus";
import { AssessmentReminder } from "../Settings/AssessmentReminder";
import { Stage } from "../Stage/Stage.jsx";
import AssessmentStatus from "./AssessmentStatus";
import { ImportBulkResume } from "./ImportBulkResume.jsx";
import SendAssessment from "./SendAssessment";
import ShowResumeScore from "./ShowResumeScore.jsx";

export const JobDetail = () => {
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const [isCandidateModelOpen, setIsCandidateModelOpen] = useState(false);
  const [isImportCandidateModelOpen, setIsImportCandidateModelOpen] = useState(false);
  const [candidateFormValues, setCandidateFormValues] = useState(candidateDetailsInitialValues);
  const [actionType, setActionType] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [totalQuestions, setTotalQuestions] = useState(TotalQuestionsInitialValues.totalQuestions);
  const [customQuestion, setcustomQuestion] = useState(TotalQuestionsInitialValues.customQuestion);
  const [isAssessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [isPremiumModelOpen, setIsPremiumModelOpen] = useState(false);
  const [isSendingAssessment, setIsSendingAssessment] = useState(false);
  const { showToast } = useContext(ToastContext);
  const [file, setFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [jobData, setJobData] = useState();
  const formRef = useRef();
  const customQuestionsformRef = useRef();
  const [fileInputRef] = useState(useRef());
  const childRef = useRef();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [pagination, setParentPagination] = useState(null);
  const [candidateDetailFields, setCandidateDetailFields] = useState(getCandidateDetailFields());
  const [stageTabs, setStageTabs] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [parentSelectedTab, setParentSelectedTab] = useState(urlParams.get("tabindex") || 0);
  const key = "candidateList";
  const [options, setOptions] = useState(null);
  const [stepCount, setStepCount] = useState(1);
  const [isUploadCandidate, setIsUploadCandidate] = useState(null);
  const [candidateTotalUpload, setCandidateTotalUpload] = useState(0);
  const [candidateSuccessUpload, setCandidateSuccessUpload] = useState(0);
  const [candidateFailUpload, SetCandidateFailUpload] = useState(0);
  const [resumeCount, setResumeCount] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bulkAction, setBulkAction] = useState();
  const [clearSelection, setClearSelection] = useState(false);
  const [sendAssessmentModel, setSendAssessmentModel] = useState(false);
  const [NextRound, setNextRound] = useState(false);
  const [isLastStage, setIsLastStage] = useState(false);
  const [NextSendAssessment, setNextSendAssessment] = useState(false);
  const [bulkoptions, setBulkOptions] = useState(null);
  const [assessmentSend, setAssessmentSend] = useState(false);
  const [assessmentSendModel, setAssessmentSendModel] = useState(false);
  const [bulkAssessmnet, setBulkAssessment] = useState();

  const pricingConfig = {
    hideHeader: true,
    plans: ["Free", "Premium"],
  };

  const tabs = [
    {
      id: "candidates",
      content: "Candidates",
      accessibilityLabel: "Candidates",
      panelID: "candidates",
    },
    {
      id: "stages",
      content: "Interview Rounds",
      accessibilityLabel: "Interview Rounds",
      panelID: "stages",
    },
    {
      id: "reminder",
      content: "Reminder",
      accessibilityLabel: "Reminder",
      panelID: "reminder",
    },
  ];

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    if (selectedTabIndex === 0) {
      getJobData();
    }
  }, []);
  const handleBulkDeleteClose = useCallback(() => setShowConfirmation(!showConfirmation), [showConfirmation]);
  const handleBulkSendClose = useCallback(
    () => setSendAssessmentModel(!sendAssessmentModel),
    [sendAssessmentModel]
  );

  const handleBulkSendAssessmentClose = useCallback(
    () => setAssessmentSendModel(!assessmentSendModel),
    [assessmentSendModel]
  );

  useEffect(() => {
    getJobData();
  }, []);

  const saveJob = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleBulkSendAssessment = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef, filterValues, bulkAction]);

  const handleBulkSendAssessmentMassage = useCallback(() => {
    setAssessmentSend(false);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef, filterValues, bulkAction, assessmentSend]);

  useEffect(() => {}, [parentSelectedTab]);

  const getJobData = useCallback(async () => {
    localStorage.setItem("playbackSpeed", 1);
    const stagesData = await fetch.get(`/stages/${id}`);
    const stageTitles = stagesData?.data?.rows?.map((item) => ({
      value: item._id,
      label: item.stageDetails.stageTitle,
    }));
    setBulkOptions(stageTitles);
    const candidateTab = document.querySelector(".Polaris-Tabs__TabContainer #candidates");
    if (stagesData?.data?.rows?.length > 0) {
    } else {
      handleTabChange(1);
      document.querySelector("li #candidates")?.classList?.toggle("hidden");
    }

    const mappedTabs = stagesData?.data?.rows?.map((stage, index) => ({
      id: `${stage?.stageDetails?.stageTitle}-${index}`,
      content: stage?.stageDetails?.stageTitle,
      searchQuery: {
        key: "lastStage._id",
        value: stage?._id,
        tabQuery: true,
        type: "marketQuery",
        secondQuery: [{ reject: false }],
      },
      hideFilter: ["reject"],
    }));
    const stageTabs = [{ id: "all", content: "All", hideFilter: [] }];
    if (mappedTabs && mappedTabs.length > 0) {
      stageTabs.push(...mappedTabs);
      stageTabs.push(
        {
          id: "reject",
          content: "Reject",
          searchQuery: { key: "reject", value: true, tabQuery: true },
          hideFilter: ["reject"],
        },
        {
          id: "Shortlist",
          content: "Shortlist",
          searchQuery: { key: "isShortList", value: true, tabQuery: true },
          hideFilter: ["reject"],
        }
      );
    }
    setStageTabs(stageTabs);
    const res = await fetch.get(`/job/${id}`);
    setJobData(res.data);
  }, [id, setJobData, selected]);

  const handleSubmit = useCallback(
    async (value) => {
      if (value?.sendAssessment?.includes("whatsapp") || value?.sendAssessment?.includes("email")) {
        bulkAssessmnet["sendAssessmentValue"] = value?.sendAssessment;
        value = {
          ...bulkAssessmnet,
        };
      }
      if (value.stage) {
        if (bulkAction === "Send Assessment" && assessmentSend) {
          setSendAssessmentModel(false);
          setAssessmentSendModel(true);
          setBulkAssessment(value);
        } else if (filterValues) {
          await fetch.post(`bulkAction`, { filterValues, bulkAction, id, value });
          showToast(`${bulkAction} successfully`);
          setClearSelection(true);
          setLocalStorageFilter(true, parentSelectedTab, childRef, key);
          setSendAssessmentModel(false);
          setAssessmentSendModel(false);
        }
      } else if (stepCount === 2) {
        const chunkSize = 10;
        let TotalUpload = 0,
          SuccessUpload = 0,
          FailUpload = 0;
        for (let i = 0; i < isUploadCandidate.length; i += chunkSize) {
          const chunk = isUploadCandidate.slice(i, i + chunkSize);
          let response = await fetch.post("/candidates", { candidates: chunk, header: value });
          TotalUpload = TotalUpload + response?.data?.totalOperations;
          SuccessUpload = SuccessUpload + response?.data?.successfulOperations;
          FailUpload = FailUpload + response?.data?.failedOperations;
        }
        setCandidateTotalUpload(TotalUpload);
        setCandidateSuccessUpload(SuccessUpload);
        SetCandidateFailUpload(FailUpload);
        setLocalStorageFilter(true, parentSelectedTab, childRef, key);
        showToast(`Candidates Upload successfully`);
        setStepCount(stepCount + 1);
      } else {
        await fetch.post("/candidate", {
          jobId: jobData?._id,
          ...value,
        });
        actionType === "add"
          ? showToast("Candidate Add successfully")
          : showToast("Candidate Updated successfully");
        setCandidateFormValues(candidateDetailsInitialValues);
        setLocalStorageFilter(true, parentSelectedTab, childRef, key);
      }

      setIsCandidateModelOpen(false);
    },
    [
      childRef,
      actionType,
      parentSelectedTab,
      stepCount,
      candidateTotalUpload,
      candidateTotalUpload,
      candidateFailUpload,
      bulkAction,
      assessmentSend,
    ]
  );

  const handleButtonClick = () => {
    setCandidateDetailFields(getCandidateDetailFields(true, jobData));
    setIsCandidateModelOpen(true);
    setActionType("add");
  };

  const handleImportCandidate = async () => {
    setIsImportCandidateModelOpen(true);
    setSelectedFileName("");
  };

  const handleBulkModalClose = () => {
    setStepCount(1);
    setIsUploadCandidate(null);
    setIsImportCandidateModelOpen(false);
  };

  const downlodCsv = useCallback(async () => {
    const res = await fetch.get(`/bulkUpload`);
    const bulkCandidateData = res.data.rows;
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(bulkCandidateData[0]).join(",") +
      "\n" +
      bulkCandidateData.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sampleCandidateData.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showToast("Download successfully");
  }, []);

  const handleModalClose = () => {
    setIsCandidateModelOpen(false);
    setCandidateFormValues({ ...candidateDetailsInitialValues });
  };

  const handleEdit = (row) => {
    row.totalExperience = row?.totalExperience?.toString();
    setCandidateDetailFields(getCandidateDetailFields(false, jobData));
    setActionType("update");
    const rowData = JSON.parse(JSON.stringify(row, (key, value) => (value == null ? undefined : value)));
    setCandidateFormValues({ ...candidateDetailsInitialValues, ...rowData });
    setIsCandidateModelOpen(true);
  };

  const handleDelete = useCallback(async (row) => {
    setCandidateToDelete(row);
    setIsDeleteModalOpen(true);
  }, []);

  const handleDeleteConfirmed = useCallback(async () => {
    try {
      if (candidateToDelete) {
        await fetch.delete(`/candidate/${candidateToDelete._id}`);
        childRef.current.fetchData();
        showToast("Candidate Delete successfully");
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  }, [candidateToDelete, childRef]);

  const handleDeleteCanceled = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleRowClick = (row) => {
    navigate(`/candidate/${row._id}?tabindex=${parentSelectedTab}`);
  };

  const handleRangeChange = (values) => {
    setTotalQuestions(values.totalQuestions);
    setcustomQuestion(values.customQuestion);
  };

  const handleAssessment = (values) => {
    const lastOption = bulkoptions[bulkoptions.length - 1];
    setIsLastStage(lastOption.value);
    setNextRound(values.sendAssessment === "NextRound" ? true : false);
    setNextSendAssessment(values.sendAssessment === "NextSendAssessment" ? true : false);
    if (values.sendAssessment === "SendAssessmentAll" || values.sendAssessment === "NextSendAssessment") {
      setAssessmentSend(true);
    }
  };

  const handleSendAssessment = useCallback(async () => {
    setIsSendingAssessment(true);
    await fetch.post(`/assessment`, {
      ...selectedCandidate,
      fromEmail: userProfileData?.email,
      jobTitle: jobData?.jobTitle,
      jobDescription: jobData?.jobDescription,
      customQuestion: jobData?.customQuestion,
      totalQuestions: totalQuestions,
      assessmentStatus: true,
      customQuestionPosition: customQuestion,
      orgId: userProfileData?.currentOrganization.orgId,
    });
    setLocalStorageFilter(true, parentSelectedTab, childRef, key);

    setIsSendingAssessment(false);
    setTotalQuestions(10);

    showToast("Sent Assessment successfully");

    setAssessmentModalOpen(false);
  }, [jobData, userProfileData, selectedCandidate, totalQuestions, parentSelectedTab, childRef]);

  const handlePremiumModelClose = () => {
    setIsPremiumModelOpen(false);
  };

  const handleBackClick = useCallback(() => {
    removeSessionStorageItem("candidateList");
    navigate(`/jobs`);
  }, []);

  const filterFormFields = useMemo(() => getFilterField(), []);

  const handleMoveToNextStage = useCallback(
    async (row) => {
      await fetch.post("/movecandidate", {
        row: row,
        jobId: jobData._id,
      });
      setLocalStorageFilter(true, parentSelectedTab, childRef, key);
    },
    [jobData, parentSelectedTab, childRef]
  );

  const formatIndianNumber = (number) => {
    const numberString = number.toString();
    const lastTwo = numberString.slice(-3);
    const otherNumbers = numberString.slice(0, -3);

    let formattedNumber;

    if (otherNumbers !== "") {
      const reversedOtherNumbers = otherNumbers.split("").reverse().join("");
      const formattedReversedOtherNumbers = reversedOtherNumbers
        .replace(/(\d{2})(?=\d)/g, "$1,")
        .split("")
        .reverse()
        .join("");
      formattedNumber = formattedReversedOtherNumbers + "," + lastTwo;
    } else {
      formattedNumber = lastTwo;
    }

    return formattedNumber;
  };

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const getExperienceLabel = (value) => {
    const experience = totalExperience.find((option) => option.value === value.toString());
    return experience ? experience.label : "Unknown";
  };

  const handleBulkDelete = useCallback(async () => {
    if (filterValues) {
      await fetch.post(`bulkAction`, { filterValues, bulkAction, id });
    }
    showToast(`${bulkAction} successfully`);
    setClearSelection(true);
    setLocalStorageFilter(true, parentSelectedTab, childRef, key);
    setShowConfirmation(false);
  }, [filterValues]);

  const handleIconClick = async (row, stage) => {
    const org = await fetch.get(`/organization/${row?.orgId}`);
    let countryCode = org?.data?.country;
    const callingCode = getCountryCallingCode(countryCode);
    const message = `Hello ${row?.fullName},

Thanks for applying for the ${jobData.jobTitle} role at 48 hours! 

We’d like to invite you to complete an assessment as the next step.

Job Details:
Job Title: ${jobData.jobTitle}
Organization: ${org.name}
Location: ${jobData.jobLocation}
Position : ${row.employmentType}
Expire In : 48 hours
Question Types : Video, Text, Number

Note: You can take the assessment on your mobile or laptop no sign-up needed.

Link: ${process.env.APP_URL}/assessment/${stage?.assessmentId}`; // Your pre-filled message

    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${callingCode}${row?.mobile}?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };

  const createRowsData = useCallback(
    (rows, selectedResources) => {
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;
      if (rows?.length <= 0) return [];
      rows?.map((row, index) => {
        row["id"] = row._id;
        if (row?.stages?.length > 0) {
          row.currentStage = row?.stages[row?.stages?.length - 1];
          row.lastStage = row?.stages[row?.stages?.length - 2];
        }
        const currentJobStages = row?.stages?.filter((e) => e?.jobId == jobData?._id);
        if (currentJobStages?.length > 0 && row?.stages?.length > 0) {
          row.inLastStage =
            row?.stages?.[row?.stages?.length - 1]?.["_id"] ===
            jobData?.stages?.[jobData?.stages?.length - 1]?.["_id"];
        }
      });
      return rows?.map((row, index) => {
        return (
          <IndexTable.Row
            id={row._id}
            selected={selectedResources.includes(row._id)}
            key={row._id}
            position={index}
            // onClick={() => {}}
          >
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell key={row._id + 1}>
              <div style={{ width: "250px" }}>
                <BlockStack gap="200">
                  {row?.assessment[0]?.imageUrl && (
                    <Image width={80} source={`${process.env.APP_URL}/uploads/${row?.assessment[0]?.imageUrl}`} />
                  )}
                  <InlineStack gap={100}>
                    <Link onClick={() => handleRowClick(row)} removeUnderline monochrome>
                      <InlineStack gap="200">
                        <Text variant="headingMd" as="h6">
                          {row?.fullName || "N/A"}
                        </Text>
                        {row?.reject && <Badge tone="critical">Rejected</Badge>}
                        {row?.isShortList && <Badge tone="success">Shortlisted</Badge>}
                      </InlineStack>
                    </Link>
                    <StarCandidate candidate={row} width={"20px"}></StarCandidate>
                    {row?.candidateRate && (
                      <ProgressCircle
                        score={row?.candidateRate}
                        fontSize="8px"
                        width="23px"
                        border="2px"
                        progress={true}
                      />
                    )}
                  </InlineStack>
                  <InlineStack gap="200">
                    <Text as="p" tone="subdued">
                      Email :
                    </Text>
                    <Text as="p" fontWeight="medium">
                      {row?.email || "N/A"}
                    </Text>
                  </InlineStack>
                  {row?.mobile && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Mobile :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.mobile}
                      </Text>
                      <a
                        style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
                        onClick={() => handleIconClick(row, row?.currentStage)}
                      >
                        <Image width={15} source={Whatsapp} />
                      </a>
                    </InlineStack>
                  )}
                  {row?.currentLocation && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Location :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.currentLocation}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.candidateFeedback?.length > 0 && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Last Feedback :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.candidateFeedback[row?.candidateFeedback.length - 1]?.feedback}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.resume?.assetUrl && (
                    <InlineStack gap="200" blockAlign="center">
                      {row?.resumeScore?.score >= 0 && (
                        <ProgressCircle
                          score={parseInt(row?.resumeScore?.score)}
                          width="55px"
                          border="5px"
                          fontSize="12px"
                        />
                      )}

                      <ShowResumeScore row={row} childRef={childRef} jobData={jobData} />
                    </InlineStack>
                  )}
                </BlockStack>
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "200px" }}>
                <BlockStack gap="200">
                  {/* <InlineStack gap="200"> */}
                  {row?.totalExperience && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Experience :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {getExperienceLabel(row.totalExperience)}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.applyingAs && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Applying As :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.applyingAs}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.preferWorkMode && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Work Mode :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.preferWorkMode}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.noticePeriod && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Notice Period :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.noticePeriod}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.expectedCTC && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Expected CTC :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.expectedCTC ? formatIndianNumber(row?.expectedCTC) : "-"}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.currentCTC && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Current CTC :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.currentCTC ? formatIndianNumber(row?.currentCTC) : "-"}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.isLocation && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Relocatable :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.isLocation}
                      </Text>
                    </InlineStack>
                  )}
                </BlockStack>
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Created By :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row.isOnline ? "Public Link" : row?.userdetail[0]?.firstName}
                  </Text>
                </InlineStack>
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Created At :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {formatTimeAgo(row?.created)}
                  </Text>
                </InlineStack>
                {row?.platform && (
                  <InlineStack gap="200">
                    <Text as="p" tone="subdued">
                      Platform :
                    </Text>
                    <Text as="p" fontWeight="medium">
                      {row?.platform}
                    </Text>
                  </InlineStack>
                )}
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ width: "300px", padding: "10px 0px" }}>
                <BlockStack gap="200">
                  {row?.stages?.length > 1 && (
                    <Box padding="200" borderColor="border" borderWidth="025" shadow="200" borderRadius="100">
                      <BlockStack gap="200">
                        <Text as="p" tone="subdued">
                          Past Assessment :
                        </Text>
                        <InlineStack gap="200">
                          <BlockStack gap="200">
                            {row?.stages?.slice(0, -1).map((stage) => (
                              <InlineStack gap="200" blockAlign="center" key={stage._id}>
                                <Text as="p" fontWeight="medium">
                                  {stage.stageDetails.stageTitle} {":"}
                                </Text>
                                <AssessmentStatus row={row} stage={stage} jobData={jobData}></AssessmentStatus>
                              </InlineStack>
                            ))}
                          </BlockStack>
                        </InlineStack>
                      </BlockStack>
                    </Box>
                  )}

                  <Box padding="400" borderColor="border" borderWidth="025" shadow="200" borderRadius="100">
                    <BlockStack gap="200">
                      <BlockStack gap="200">
                        <InlineStack gap="200">
                          <Text as="p" tone="subdued">
                            Current Interview Round :
                          </Text>
                          <Text as="p" fontWeight="medium">
                            {row?.currentStage?.["stageDetails"]["stageTitle"]}{" "}
                          </Text>
                        </InlineStack>
                        <InlineStack gap="200" blockAlign="center">
                          <Text as="p" tone="subdued">
                            Status :
                          </Text>
                          <AssessmentStatus
                            row={row}
                            stage={row?.currentStage}
                            jobData={jobData}
                          ></AssessmentStatus>
                        </InlineStack>
                        <InlineStack gap="200">
                          {!row?.reject && (
                            <>
                              {!row?.currentStage?.assessmentStatus && (
                                <InlineStack gap="200">
                                  {!row?.currentStage?.assessmentStatus && jobData.isActive && (
                                    <SendAssessment
                                      candidate={row}
                                      setIsSendingAssessment={setIsSendingAssessment}
                                      isSendingAssessment={isSendingAssessment}
                                      childRef={childRef}
                                      stage={row.currentStage}
                                    ></SendAssessment>
                                  )}
                                </InlineStack>
                              )}
                              {jobData.isActive && (
                                <InlineStack gap="200">
                                  {!row?.inLastStage && row?.currentStage?.assessmentStatus==true && (
                                     <Button primary onClick={() => handleMoveToNextStage(row)}>
                                     Move to next Interview Round
                                   </Button>
                                  ) }
                                </InlineStack>
                              )}
                              {!row?.inLastStage && !row?.currentStage?.assessmentStatus && jobData.isActive && (
                                <InlineStack gap="200">
                                  {!row?.currentStage?.assessmentStatus && (
                                    <Button primary onClick={() => handleMoveToNextStage(row)}>
                                      Skip this Interview Round
                                    </Button>
                                  )}
                                </InlineStack>
                              )}
                            </>
                          )}
                          {jobData.isActive && (
                            <CandidateStatus
                              candidate={row}
                              jobid={jobData._id}
                              parentSelectedTab={parentSelectedTab}
                              childRef={childRef}
                              filter={true}
                            ></CandidateStatus>
                          )}
                        </InlineStack>
                      </BlockStack>
                    </BlockStack>
                  </Box>
                </BlockStack>
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  {jobData.isActive && (
                    <AccessPermission access={["manageJobs", "manageCandidate"]}>
                      <Button
                        plain
                        monochrome
                        icon={EditMinor}
                        onClick={() => {
                          handleEdit(row);
                        }}
                      />
                      <Button
                        plain
                        monochrome
                        icon={DeleteMinor}
                        onClick={() => {
                          handleDelete(row);
                        }}
                      />
                    </AccessPermission>
                  )}
                  {jobData.isActive && (
                    <AccessPermission access={["viewCandidate", "manageCandidate"]}>
                      <CandidateAction
                        candidate={row}
                        jobid={jobData._id}
                        parentSelectedTab={parentSelectedTab}
                        childRef={childRef}
                        filter={true}
                        jobData={jobData}
                      />
                    </AccessPermission>
                  )}
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [userProfileData, childRef, handleSendAssessment, pagination, jobData, parentSelectedTab]
  );

  const handleCandidatesImport = useCallback(
    (e) => {
      if (stepCount === 2) {
        formRef.current.handleSubmit();
      } else {
        e.preventDefault();
        if (file) {
          Papa.parse(file, {
            complete: async (result) => {
              const headers = result.data[0];
              const detailsArray = [
                { value: "none", label: "None" },
                ...Object.keys(headers).map((key) => ({
                  value: key,
                  label: key,
                })),
              ];
              setOptions(detailsArray);
              const candidatesWithJobId = result.data.map((candidate) => ({
                ...candidate,
                jobId: jobData._id,
              }));
              setIsUploadCandidate(candidatesWithJobId);
              setStepCount(stepCount + 1);
            },
            header: true,
            skipEmptyLines: true,
          });
        }
      }
    },
    [parentSelectedTab, childRef, file, stepCount]
  );

  const handleFileUpload = useCallback(
    async (e) => {
      setFile(e.target.files[0]);
      const selectedFile = e.target.files[0];
      setSelectedFileName(selectedFile.name);
    },
    [childRef, jobData]
  );

  const closeAssessmentModal = () => {
    if (!isSendingAssessment) {
      setAssessmentModalOpen(false);
      setTotalQuestions(10);
    }
  };

  const handleDeleteBannerOpen = useCallback((rows, selectedResources, allResourcesSelected, search) => {
    setBulkAction("Delete");
    setShowConfirmation(true);
    setFilterValues({
      selectedResources,
      allResourcesSelected,
      search,
    });
    setClearSelection(false);
  }, []);
  const handleRejectBannerOpen = useCallback((rows, selectedResources, allResourcesSelected, search) => {
    setBulkAction("Reject");
    setShowConfirmation(true);
    setFilterValues({
      selectedResources,
      allResourcesSelected,
      search,
    });
    setClearSelection(false);
  }, []);
  const handleShortListBannerOpen = useCallback((rows, selectedResources, allResourcesSelected, search) => {
    setBulkAction("ShortList");
    setShowConfirmation(true);
    setFilterValues({
      selectedResources,
      allResourcesSelected,
      search,
    });
    setClearSelection(false);
  }, []);

  const handleSendAssessmentBannerOpen = useCallback(
    (rows, selectedResources, allResourcesSelected, search) => {
      setBulkAction("Send Assessment");
      setSendAssessmentModel(true);
      setFilterValues({
        selectedResources,
        allResourcesSelected,
        search,
      });
      setClearSelection(false);
    },
    [bulkoptions]
  );

  if (!jobData) {
    return (
      <div style={{ height: "100px" }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    );
  }

  return (
    <Page fullWidth={true} title={jobData?.jobTitle} backAction={{ content: "Back", onAction: handleBackClick }}>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {selected === 0 && (
          <>
            <Box paddingBlockEnd="300">
              <InlineStack align="end" gap="400">
                <AccessPermission access={["manageJobs", "manageCandidate"]}>
                  <ImportBulkResume jobData={jobData} resumeCount={resumeCount} />
                  {jobData?.stages?.length < 1 && jobData.isActive ? (
                    <Tooltip content="Please add Interview Round first">
                      <Button size="large" onClick={handleButtonClick} disabled={true}>
                        Add Candidate
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip content="Manually add candidate details.">
                      <Button size="large" disabled={!jobData.isActive} onClick={handleButtonClick}>
                        Add Candidate
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip content="Import Candidate Details in Bulk Using CSV Files">
                    <Button size="large" disabled={!jobData.isActive} onClick={handleImportCandidate}>
                      Import Candidates
                    </Button>
                  </Tooltip>
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e)}
                    ref={fileInputRef}
                  />
                  {/* <Button size="large" onClick={handleImportCandidate}>
                    Import Candidates
                  </Button> */}
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e)}
                    ref={fileInputRef}
                  />
                </AccessPermission>
              </InlineStack>
            </Box>
            <BlockStack gap="200">
              <CommonTable
                resourceName={{
                  singular: "Candidate",
                  plural: "Candidates",
                }}
                title="Candidate list"
                queryPlaceholder="Search Candidate by (Email, Full Name, Mobile,Created By)"
                url={`/candidates/${id}`}
                isFilterVisible
                // selectable={true}
                rowsData={createRowsData}
                setParentPagination={setParentPagination}
                setParentSelectedTab={setParentSelectedTab}
                headings={[
                  { title: "No" },
                  { title: "Personal Details" },
                  { title: "Work Details" },
                  { title: "Analytics" },
                  { title: "Assessment Details", width: "200px" },
                  jobData.isActive ? { title: "Action" } : "",
                ]}
                ref={childRef}
                columnContentTypes={["string", "string", "string", "string", "string", "Action"]}
                searchKey={["email", "fullName", "mobile", "userdetail.firstName"]}
                pinnedFilter={[
                  "reject",
                  "totalScore",
                  "assessmentStatus",
                  "isStar",
                  "noticePeriod",
                  "candidateRate",
                  "resumeScore",
                ]}
                isAdd={false}
                showTab={true}
                tabOption={stageTabs}
                filterFormFields={filterFormFields}
                clearSelection={clearSelection}
                localStorageKey={`candidateList`}
                setCount={setResumeCount}
                promotedBulkActions={[
                  {
                    content: "Bulk Delete",
                    onAction: handleDeleteBannerOpen,
                  },
                  {
                    content: "Bulk Reject",
                    onAction: handleRejectBannerOpen,
                  },
                  {
                    content: "Bulk ShortList",
                    onAction: handleShortListBannerOpen,
                  },
                  {
                    content: "Bulk Send Assessment",
                    onAction: handleSendAssessmentBannerOpen,
                  },
                ]}
              />
            </BlockStack>
            <BannerModal
              size={"large"}
              open={isCandidateModelOpen}
              onClose={handleModalClose}
              title={actionType === "update" ? "Edit Candidate" : "Add New Candidate"}
              primaryAction={{
                content: "Submit",
                onAction: saveJob,
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: handleModalClose,
                },
              ]}
              children={
                <CommonForm
                  onSubmit={handleSubmit}
                  formRef={formRef}
                  initialValues={candidateFormValues}
                  formFields={getCandidateDetailFields(jobData)}
                  title="Add New Candidate"
                  isSave={false}
                />
              }
            />
            <BannerModal
              open={isPremiumModelOpen}
              onClose={handlePremiumModelClose}
              title={"Upgrade to Premium"}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: handlePremiumModelClose,
                },
              ]}
              children={<Plan config={pricingConfig} />}
              size={"large"}
            />
            <Modal
              open={isDeleteModalOpen}
              onClose={handleDeleteCanceled}
              title="Confirm Delete"
              primaryAction={{
                content: "Delete",
                onAction: handleDeleteConfirmed,
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: handleDeleteCanceled,
                },
              ]}
            >
              <Modal.Section>
                <Text>Are you sure you want to delete this candidate?</Text>
              </Modal.Section>
            </Modal>
            <BannerModal
              open={isAssessmentModalOpen}
              onClose={closeAssessmentModal}
              title={"Send Assessment"}
              primaryAction={{
                content: "Send",
                onAction: handleSendAssessment,
                disabled: isSendingAssessment,
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: closeAssessmentModal,
                  disabled: isSendingAssessment,
                },
              ]}
              children={
                <BlockStack gap="200">
                  <Text>Send To: {selectedCandidate?.fullName}</Text>
                  <Text>Custom Question: {jobData?.customQuestion?.length}</Text>
                  <Text>AI generated Question: {totalQuestions - jobData?.customQuestion?.length}</Text>
                  <CommonForm
                    formRef={customQuestionsformRef}
                    initialValues={TotalQuestionsInitialValues}
                    formFields={TotalQuestionsFields}
                    title="Total Questions"
                    isSave={false}
                    onFormChange={handleRangeChange}
                  />
                </BlockStack>
              }
            />
            <BannerModal
              open={isImportCandidateModelOpen}
              onClose={handleBulkModalClose}
              title={stepCount == 3 ? "Candiate Upload Summary" : "Bulk Candidate Upload"}
              primaryAction={
                stepCount < 3
                  ? {
                      content: "Next",
                      onAction: handleCandidatesImport,
                    }
                  : ""
              }
              onClick={
                stepCount < 3
                  ? (e) => {
                      handleCandidatesImport(e);
                    }
                  : ""
              }
              secondaryActions={
                stepCount < 3
                  ? [
                      {
                        content: "Cancel",
                        onAction: handleBulkModalClose,
                      },
                    ]
                  : ""
              }
              children={
                <>
                  {stepCount == 1 && (
                    <BlockStack gap="500">
                      <InlineStack gap={200}>
                        <Text align="center" as="p" fontWeight="bold">
                          Sample of Candidate import CSV file{" "}
                        </Text>
                        <Button variant="plain" onClick={downlodCsv}>
                          Download
                        </Button>
                      </InlineStack>
                      <InlineStack align="space-between" blockAlign="center">
                        <Text align="center" as="p" fontWeight="bold">
                          Import CSV File{" "}
                        </Text>
                        <div>
                          <label>{selectedFileName ? selectedFileName : "No file chosen"}</label>
                          <div className="custom-file-input">
                            <input type="file" id="csvFileInput" accept=".csv" onChange={handleFileUpload} />
                            <label htmlFor="csvFileInput" className="file-input-label">
                              Choose CSV File
                            </label>
                          </div>
                        </div>
                      </InlineStack>
                    </BlockStack>
                  )}
                  {stepCount == 2 && (
                    <div className="importCandidate">
                      <CommonForm
                        onSubmit={handleSubmit}
                        formRef={formRef}
                        initialValues={candidateDetailsInitialValues}
                        formFields={getImportCandidateDetailFields(options)}
                        isSave={false}
                      />
                    </div>
                  )}
                  {stepCount == 3 && (
                    <BlockStack gap="500">
                      <InlineStack gap={200}>
                        <Text align="center" as="p">
                          Total Candidates to Upload :
                        </Text>
                        <Text align="center" as="p" fontWeight="bold">
                          {candidateTotalUpload}
                        </Text>
                      </InlineStack>
                      <InlineStack gap={200}>
                        <Text align="center" as="p">
                          Successful Candidates to Upload :
                        </Text>
                        <Text align="center" as="p" fontWeight="bold">
                          {candidateSuccessUpload}
                        </Text>
                      </InlineStack>
                      <InlineStack gap={200}>
                        <Text align="center" as="p">
                          Failed Candidates to Upload :
                        </Text>
                        <Text align="center" as="p" fontWeight="bold">
                          {candidateFailUpload}
                        </Text>
                      </InlineStack>
                      <Text align="center" as="p" fontWeight="bold">
                        Out of {candidateTotalUpload} Candidates , {candidateSuccessUpload} candidates successfully
                        completed the process, while {candidateFailUpload} did not meet the requirements.
                      </Text>
                    </BlockStack>
                  )}
                </>
              }
            />
          </>
        )}
        {selected === 1 && <Stage jobId={jobData._id} />}
        {selected === 2 && <AssessmentReminder status={"joblevel"} id={jobData._id}></AssessmentReminder>}
      </Tabs>
      <Modal
        open={showConfirmation}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkDeleteClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkDelete,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkDeleteClose,
          },
        ]}
        size="small"
      >
        <Modal.Section>
          <Text>Are you sure you want to {bulkAction} Candidates?</Text>
        </Modal.Section>
      </Modal>
      <Modal
        open={sendAssessmentModel}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkSendClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkSendAssessment,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkSendClose,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={bulkSendAssessmentInitialValues}
            formFields={bulkSendAssessmentFormFields(bulkoptions, NextRound, NextSendAssessment, isLastStage)}
            isSave={false}
            onFormChange={handleAssessment}
          />
        </Modal.Section>
      </Modal>
      <Modal
        open={assessmentSendModel}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkSendAssessmentClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkSendAssessmentMassage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkSendAssessmentClose,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={sendAssessmentInitialValues}
            formFields={sendAssessmentFields}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
    </Page>
  );
};
