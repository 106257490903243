import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { BlockStack, Card, InlineStack, Page } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import {
  assessmentPreviewFormFields,
  assessmentPreviewFormitialValues,
} from "../../../Assets/Mocks/Assessment.mock";
import { UserContext } from "../../../Context/UserContext";
import { CommonHeader } from "./Common/CommonHeader";
import { JobPreview } from "./Common/Steps/JobPreview";

export const AssessmentPreview = () => {
  const [assessmentPreview, setAssessmentPreview] = useState(assessmentPreviewFormitialValues);
  const [assessmentPreviews, setAssessmentPreviews] = useState();
  const { showToast } = useContext(ToastContext);
  const { userProfileData } = useContext(UserContext);
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const [jobData, setJobData] = useState();
  const [organization, setOrganization] = useState();

  useEffect(() => {
    fetchData();
  }, [userProfileData]);
  const fetchData = useCallback(async () => {
    const res = await fetch.get(`/organizations`);
    const matchedOrganization = res?.data.find((org) => org._id === userProfileData?.currentOrganization?.orgId);
    setOrganization(matchedOrganization);
    const response = await fetch.post(`/jobs`);
    if (response?.data?.rows?.length == 0) {
      let jobDetails = {
        jobTitle: "React Developer",
        jobDescription:
          "<p>We are seeking an experienced developer with a strong background in both React.JS and JavaScript to join our dynamic team. The successful candidate will be a self-starter with a proven track record of delivering high-quality applications. As a developer, you will be responsible for developing and maintaining web applications, ensuring seamless functionality and an excellent user experience.</p>\n<p><strong>Responsibilities:</strong></p>\n<ul>\n<li>Develop and implement new features and functionality for web applications.</li>\n<li>Write clean, maintainable, and efficient code.</li>\n<li>Collaborate with cross-functional teams to define, design, and ship new features.</li>\n<li>Troubleshoot and debug applications to optimize performance.</li>\n<li>Stay up-to-date with current and emerging technologies and suggest improvements where necessary.</li>\n</ul>\n<p><strong>Requirements:</strong></p>\n<ul>\n<li>Proven work experience as a developer with a focus on React.JS and JavaScript.</li>\n<li>Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model.</li>\n<li>Experience with popular React.JS workflows (such as Flux or Redux).</li>\n<li>Familiarity with newer specifications of ECMAScript.</li>\n<li>Experience with data structure libraries (e.g., Immutable.js).</li>\n<li>Familiarity with RESTful APIs.</li>\n<li>Knowledge of modern authorization mechanisms, such as JSON Web Token.</li>\n<li>Familiarity with modern front-end build pipelines and tools.</li>\n<li>Strong problem-solving skills and a keen attention to detail.</li>\n</ul>\n<p>If you are a proactive and collaborative professional who thrives in a fast-paced environment, we'd love to hear from you. Please apply with your updated resume and a cover letter detailing your relevant experience and why you're interested in this position.</p>",
        keySkills: "React JS, JavaScript, HTML/CSS",
        workExperience: 5,
        employmentType: "Full Time",
        jobLocation: "Ahmedabad",
        workMode: "In office",
      };
      setJobData(jobDetails);
    } else {
      setJobData(response?.data?.rows[0]);
    }
    let currentOrganization = res.data.find((org) => org._id === userProfileData.currentOrganization.orgId);
    setOrganization(currentOrganization);
    if (currentOrganization.assessmentPreview) {
      const url = currentOrganization?.assessmentPreview?.logo?.url;
      const path = new URL(url).pathname;
      currentOrganization.assessmentPreview.logo.url = `${process.env.APP_URL + path}`;
      currentOrganization.assessmentPreview["title"] = currentOrganization.assessmentPreview.title
        ? currentOrganization.assessmentPreview.title
        : currentOrganization?.name;
      setAssessmentPreviews(currentOrganization.assessmentPreview);
      setAssessmentPreview(currentOrganization.assessmentPreview);
    } else {
      currentOrganization["assessmentPreview"] = {};
      currentOrganization["assessmentPreview"]["title"] = currentOrganization?.name;
      currentOrganization["assessmentPreview"]["logo"] = "";
      setAssessmentPreview(currentOrganization.assessmentPreview);
    }
  }, [assessmentPreview, jobData, organization]);

  const handleFormChange = useCallback(
    (e) => {
      setAssessmentPreviews(e);

      // setAssessmentPreviews((previous) => ({ ...previous, ...e }));
      // setAssessmentPreview((previous) => ({ ...previous, ...e }));
    },
    [assessmentPreviews, assessmentPreview]
  );

  const handleSubmit = useCallback(async (values) => {
    await fetch.post("previewAssessment", values);
    showToast("preview save sucessfully");
  }, []);

  return (
    <Page fullWidth>
      <div className="assessment">
        <InlineStack gap="600" align="center">
          <Card>
            <CommonForm
              onSubmit={handleSubmit}
              formFields={assessmentPreviewFormFields}
              initialValues={assessmentPreview}
              formRef={formRef}
              isSave={true}
              onFormChange={handleFormChange}
              enableReinitialize={true}
              noValueChanged={false}
            />
          </Card>
          <div className="assessment-preview" style={{ width: "780px" }}>
            <Card>
              <BlockStack gap={400}>
                <CommonHeader jobData={jobData} assessmentPreviews={assessmentPreviews}></CommonHeader>
                <JobPreview
                  jobData={jobData}
                  organization={organization}
                  assessmentPreviews={assessmentPreviews}
                ></JobPreview>
              </BlockStack>
            </Card>
          </div>
        </InlineStack>
      </div>
    </Page>
  );
};
