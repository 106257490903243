import React, { useCallback, useState } from "react";
import { Modal, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { setLocalStorageFilter } from "@/Utils/AppUtils";

export default function RejectCandidate({
  candidate,
  jobid,
  parentSelectedTab,
  childRef,
  filter,
  isStatus,
  setShowRejectCandidate,
}) {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(candidate?.reject == false ? true : false);
  const [isShortlistModalOpen, setIsSortlistModalOpen] = useState(candidate?.reject == true ? true : false);
  const fetch = useAuthenticatedFetch();

  const handleRejectConfirmed = useCallback(async () => {
    let data = {
      _id: candidate?._id,
      reject: true,
      isShortList: false,
      email: candidate?.email,
      jobId: candidate?.jobId,
    };
    await fetch.post("/candidate", {
      ...data,
    });

    filter ? setLocalStorageFilter(true, parentSelectedTab, childRef, "candidateList") : isStatus(true);
    setShowRejectCandidate(false);
    setIsRejectModalOpen(false);
  }, [parentSelectedTab, childRef]);

  const handleShortlistConfirmed = useCallback(async () => {
    let data = {
      _id: candidate?._id,
      reject: false,
      isShortList: false,
      email: candidate?.email,
      jobId: candidate?.jobId,
    };
    await fetch.post("/candidate", {
      ...data,
    });
    filter ? setLocalStorageFilter(true, parentSelectedTab, childRef, "candidateList") : isStatus(false);
    setShowRejectCandidate(false);
    setIsSortlistModalOpen(false);
  }, [parentSelectedTab, childRef]);

  const handleRejectCanceled = useCallback(() => {
    setIsRejectModalOpen(false);
  }, []);

  const handleShortlistCanceled = useCallback(() => {
    setIsSortlistModalOpen(false);
  }, []);

  return (
    <>
      <Modal
        open={isRejectModalOpen}
        onClose={handleRejectCanceled}
        title="Reject Candidate"
        primaryAction={{
          content: "Confirm",
          onAction: handleRejectConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleRejectCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to Reject this candidate?</Text>
        </Modal.Section>
      </Modal>
      <Modal
        open={isShortlistModalOpen}
        onClose={handleShortlistCanceled}
        title="Shortlist Candidate"
        primaryAction={{
          content: "Confirm",
          onAction: handleShortlistConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleShortlistCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to Revert Reject this candidate?</Text>
        </Modal.Section>
      </Modal>
    </>
  );
}
