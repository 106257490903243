import {
  AppsMajor,
  ClipboardMinor,
  CustomersMajor,
  DesktopMajor,
  FulfillmentFulfilledMajor,
  JobsMajor,
  LocationsMinor,
} from "@shopify/polaris-icons";

export const JobDetailsFields = (options) => [
  {
    nested: "group",
    groupSize: 1,
    section: false,
    subfields: [
      {
        id: "jobTitle",
        name: "jobTitle",
        label: "Job Title",
        validated: true,
        type: "text",
        placeholder: "Add Job Title",
        errormsg: "Job Title is required",
      },
    ],
  },

  {
    nested: "group",
    groupSize: 1,
    section: false,
    subfields: [
      {
        id: "jobDescription",
        name: "jobDescription",
        label: "Job Description",
        scrollHeight: 500,
        validated: true,
        type: "tiny-editor",
        toolbar:
          "undo redo | blocks | " +
          "bold italic underline blockquote forecolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent |" +
          "table removeformat",
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "charmap",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "insertdatetime",
          "table",
          "preview",
        ],
        multiline: 4,
        autoComplete: "off",
        errormsg: "Job Description is required",
      },
    ],
  },

  {
    nested: "group",
    groupSize: 3,
    section: false,
    subfields: [
      {
        id: "employmentType",
        name: "employmentType",
        label: "Employment Type",
        type: "select",
        placeholder: "Select Employment Type",
        options: [
          { value: "Full Time", label: "Full Time" },
          { value: "Part-Time", label: "Part-Time" },
          { value: "Contract", label: "Contract" },
          { value: "Temporary", label: "Temporary" },
        ],
      },
      {
        id: "workMode",
        name: "workMode",
        label: "Work Mode",
        type: "select",
        placeholder: "Select Work Mode",

        options: [
          { value: "In office", label: "Office" },
          { value: "Remote", label: "Remote" },
          { value: "Hybrid", label: "Hybrid" },
          { value: "Flexible", label: "Flexible" },
        ],
      },
      {
        id: "keySkills",
        name: "keySkills",
        label: "Key Skills",
        type: "text",
        placeholder: "Add the crucial skills required for the job",
      },
    ],
  },

  {
    nested: "group",
    groupSize: 3,
    section: false,
    subfields: [
      {
        id: "jobLocation",
        name: "jobLocation",
        label: "Work Location",
        type: "text",
        placeholder: "Add Work Location",
        helpText: "Enter multiple locations, e.g., Mumbai, Delhi.",
      },
      {
        id: "workExperience",
        name: "workExperience",
        label: "Minimum Work Experience (in years)",
        type: "number",
        placeholder: "Add Minimum years of experience",
      },
      {
        id: "MaxWorkExperience",
        name: "MaxWorkExperience",
        label: "Maximum Work Experience (in years)",
        type: "number",
        placeholder: "Add Maximum years of experience",
      },
    ],
  },
  {
    id: "jobAccess",
    name: "jobAccess",
    label: "Job Board Access (Select the user who will have access to this job board)",
    type: "multiSelect",
    placeholder: "Select User",
    options: options ? options : [],
  },

  // {
  //   id: "customQuestion",
  //   name: "customQuestion",
  //   label: "Custom Question",
  //   nested: "array",
  //   groupSize: 2,
  //   subfields: [
  //     {
  //       id: "question",
  //       name: "question",
  //       label: "Question",
  //       validated: true,
  //       type: "text",
  //       errormsg: "Custom Question is required",
  //     },
  //   ],
  // },
];

export const JobDescriptionFields = [
  {
    label: "How do you like to add the job?",
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        radioId: "normal",
        id: "normal",
        name: "jobdescription",
        label: "Standard",
        type: "radio",
      },
      {
        radioId: "aiGenerated",
        id: "aiGenerated",
        name: "jobdescription",
        label: "Generate Job Description",
        type: "radio",
      },
    ],
  },
];
export const JobDescriptionInitialValues = {
  jobdescription: "normal",
};

export const JobDescriptionByAiFields = [
  {
    nested: "group",
    groupSize: 2,
    section: false,
    subfields: [
      {
        id: "jobTitle",
        name: "jobTitle",
        label: "Job Title",
        validated: true,
        type: "text",
        placeholder: "Add Job Title",
        errormsg: "Job Title is required",
      },
      {
        id: "keySkills",
        name: "keySkills",
        label: "Key Skills",
        validated: true,
        type: "text",
        placeholder: "Add skills that are crucial for job",
        errormsg: "Key Skills is required",
      },
      {
        id: "instruction",
        name: "instruction",
        label: "Spacial Instruction",
        type: "text",
        placeholder: "Add Relevent experience",
      },

      {
        id: "workExperience",
        name: "workExperience",
        label: "Work Experience (Years)",
        validated: true,
        type: "number",
        placeholder: "Add Relevent experience",
        errormsg: "Work Experience is required",
      },
    ],
  },
];
export const JobDescriptionByAiInitialValues = {
  jobTitle: "",
  keySkills: "",
  instruction: "",
  workExperience: "",
};
export const JobDetailsInitialValues = {
  jobTitle: "",
  jobDescription: "",
  keySkills: "",
  employmentType: "Full Time",
  workMode: "In office",
  jobAccess: "",
  jobLocation: "",
  workExperience: "",
  customQuestion: [],
};

export const jobPreview = {
  jobDetails: [
    {
      id: "1",
      icon: CustomersMajor,
      title: "Job Title",
      valueKey: "jobTitle",
    },
    {
      id: "2",
      icon: FulfillmentFulfilledMajor,
      title: "Work Experience",
      valueKey: "workExperience",
    },
    {
      id: "3",
      icon: LocationsMinor,
      title: "Job Location",
      valueKey: "jobLocation",
    },
    {
      id: "4",
      icon: DesktopMajor,
      title: "Work Mode",
      valueKey: "workMode",
    },
    {
      id: "5",
      icon: ClipboardMinor,
      title: "Education",
      valueKey: "education",
    },
    {
      id: "6",
      icon: JobsMajor,
      title: "Employment Type",
      valueKey: "employmentType",
    },
  ],
};

export const MergeJobFields = (options) => [
  {
    id: "jobs",
    name: "jobs",
    label: "Jobs",
    options: options ? options : [{ label: "None", value: "none" }],
    type: "searchableSelect",
  },
];

export const MergeJobInitialValues = {
  jobs: "",
};

const commonChoiceField = (field, nestedKey, label, falseLable, trueLable) => {
  const key = nestedKey ? { nestedKey } : {};
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        type: "choiceList",
        choices: [
          { label: trueLable, value: true },
          { label: falseLable, value: false },
        ],
      },
    ],
    initialValue: [],
    filterType: "array",
    dynamicLabel: [
      { label: trueLable, value: true },
      { label: falseLable, value: false },
    ],
    ...key,
  };
};

const workMode = [
  { value: "In office", label: "Office" },
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
];

const employmentType = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part-Time", label: "Part-Time" },
  { value: "Contract", label: "Contract" },
  { value: "Temporary", label: "Temporary" },
];
const makeSelectField = (field, type = "select", label) => {
  return {
    key: field,
    label: label,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        type: "select",
        options: field === "workMode" ? workMode : employmentType,
      },
    ],
  };
};

const filterOperatorChoices = [
  { label: "", value: "" },
  { label: "Greater Than", value: "$gt" },
  { label: "Greater Than and Equals to", value: "$gte" },
  { label: "Equals to", value: "$eq" },
  { label: "Less Than", value: "$lt" },
  { label: "Less Than and Equals to", value: "$lte" },
];

const compareFormField = (field, type, label) => {
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: label,
        nested: "object",
        groupSize: 1,
        section: false,
        subfields: [
          {
            id: "operators",
            name: "operators",
            label: "Operators",
            type: "select",
            options: filterOperatorChoices,
          },
          {
            id: type,
            name: type,
            label: "Value",
            type: type,
            min: 0,
          },
        ],
      },
    ],
    filterType: type,
  };
};

export const getFilterField = () => {
  return [
    compareFormField("workExperience", "number", "Work Experience"),
    commonChoiceField("isStar", "", "Star", "No", "Yes"),
    makeSelectField("employmentType", "select", "Employment Type"),
    makeSelectField("workMode", "select", "Work Mode"),
  ];
};
