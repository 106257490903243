import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BlockStack, Button, Icon, InlineStack, Modal, Tooltip } from "@shopify/polaris";
import { LockMinor } from "@shopify/polaris-icons";
import { navigate } from "@/Components/Common/NavigationMenu";
import Pricing from "@/Pages/Pricing/Pricing";
import { ProfileContext } from "../../Context/ProfileContext";

export function PremiumBadge({ children }) {
  const { profileData } = useContext(ProfileContext);
  const { t } = useTranslation();

  if (profileData?.recurringPlanType !== "Free") {
    return <div>{children}</div>;
  } else {
    return <Badge tone="critical">{t("common.Premium.Premium")}</Badge>;
  }
}

export function PremiumButton({
  children,
  title,
  subTitle,
  unlockTitle,
  isWrappedWithinButton = true,
  redirectToPricing = false,
  type,
  priceConfig,
}) {
  const [activePremiumModal, setActivePremiumModal] = useState(false);
  const { profileData } = useContext(ProfileContext);
  const setNavigate = navigate();
  const { t } = useTranslation();
  const defaultPriceConfig =
    profileData?.recurringPlanType === "Free"
      ? ["Free", "Premium", "Annual"]
      : ["Free", "Pro-Monthly", "Pro-Yearly"];

  const handleUnlockClick = useCallback(() => {
    if (redirectToPricing) setNavigate("/pricing");
    else setActivePremiumModal(!activePremiumModal);
  }, [activePremiumModal]);

  const ButtonContent = () => (
    <React.Fragment>
      {isWrappedWithinButton ? (
        <Button icon={LockMinor} onClick={handleUnlockClick}>
          {t("common.Premium.Unlock")} {unlockTitle}
        </Button>
      ) : (
        <span onClick={handleUnlockClick}>
          <InlineStack blockAlign="center">
            <Icon source={LockMinor} tone="base" />
            &nbsp; {t("common.Premium.Unlock")} {unlockTitle}
          </InlineStack>
        </span>
      )}
    </React.Fragment>
  );

  const renderUnlockComponent = ({ pricingConfig }) => (
    <React.Fragment>
      <Tooltip content={`${priceConfig?.includes("Pro-Yearly") ? "Pro" : "Premium"} Plan`}>
        <ButtonContent />
      </Tooltip>
      <Modal size="large" titleHidden={true} open={activePremiumModal} onClose={handleUnlockClick}>
        <Modal.Section>
          <BlockStack gap="200" inlineAlign="start"></BlockStack>
          <Pricing
            hasBillingButton={true}
            title={title ? title : t("common.Premium.Unlock Premium features!")}
            config={pricingConfig}
          />
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );

  // Improved logic for showing the icon or label
  if (type && profileData?.featureData) {
    if (profileData?.featureData?.[type] === "false" || !profileData?.featureData?.[type]) {
      return renderUnlockComponent({ pricingConfig: { plans: priceConfig || defaultPriceConfig } });
    }
    return <div>{children}</div>;
  } else if (profileData?.recurringPlanType === "Free") {
    return renderUnlockComponent({ pricingConfig: { plans: priceConfig || defaultPriceConfig } });
  } else return <div>{children}</div>;
}
