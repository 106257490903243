import React, { useCallback, useContext, useRef, useState } from "react";
import { BlockStack, Button, Icon, IndexTable, InlineStack, Modal, Page, Text } from "@shopify/polaris";
import { ExternalSmallMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { organizationValuesOption } from "@/Assets/Mocks/Organization.mock";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import TruncatedText from "@/Components/Common/TruncatedText";
import { ToastContext } from "@/Context/ToastContext";
import { getValue } from "@/Utils/Index";
import {
  activeOrganizationPlanformFields,
  activeOrganizationPlaninitialValues,
} from "../../../Assets/Mocks/ActivePlan.mock";
import { moveJobFields, moveJobinitialValues } from "../../../Assets/Mocks/Organization.mock";
import CountryData from "../../../Assets/Mocks/countries.json";

function Organization() {
  const [organizationData, setOrganizationData] = useState();
  const formRef = useRef();
  const [isActivePlanOpen, setIsActivePlanOpen] = useState(false);
  const childRef = useRef();
  const [planFormValues, setPlanFormValues] = useState(activeOrganizationPlaninitialValues);
  const fetch = useAuthenticatedFetch();
  const { showToast } = useContext(ToastContext);
  const [isCancelPlanModalOpen, setIsCancelPlanModalOpen] = useState(false);
  const [isMoveJobOpen, setIsMoveJobOpen] = useState(false);

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      let data = {
        activePlan: e,
        pricing: organizationData,
      };
      if (e.orgId) {
        const res = await fetch.post("/admin/moveJob", e);
        childRef.current.fetchData();
        showToast("Move Job successfully");
        setIsMoveJobOpen(false);
      } else {
        const pricingPlanRes = await fetch.put("/admin/updatePlan", data);
        childRef.current.fetchData();
        showToast("Plan Update successfully");

        setIsActivePlanOpen(false);
      }
    },
    [childRef, organizationData]
  );

  const handleCanceledPlan = useCallback(async () => {
    const pricingPlanRes = await fetch.put("/admin/cancelPlan", organizationData);
    childRef.current.fetchData();
    showToast("Cancel Plan successfully");

    setIsCancelPlanModalOpen(false);
  }, [childRef, organizationData]);

  const handleCancelPlan = useCallback(
    async (row) => {
      setOrganizationData(row);
      setIsCancelPlanModalOpen(true);
    },
    [organizationData]
  );

  const handleActivePlan = useCallback(
    async (row) => {
      setOrganizationData(row);
      setIsActivePlanOpen(true);
    },
    [organizationData]
  );

  const handleMoveJob = useCallback(async () => {
    setIsMoveJobOpen(true);
  }, [organizationData]);

  const handleMoveJobModalClose = () => {
    setIsMoveJobOpen(false);
  };

  const handleModalClose = () => {
    setIsActivePlanOpen(false);
  };

  const handleCancelPlanCanceled = () => {
    setIsCancelPlanModalOpen(false);
  };

  const addPlan = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const moveJob = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleRedirect = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  function getItemNameByCountryCode(countryCode) {
    const country = CountryData[countryCode];
    return country ? country.itemName : null;
  }

  const ArchivedData = useCallback((rows) => {
    if (rows?.length <= 0) return [];
    return rows?.map((row, index) => {
      return (
        <IndexTable.Row id={row._id} key={row._id} position={index}>
          <IndexTable.Cell>{index + 1}</IndexTable.Cell>
          <IndexTable.Cell key={row._id + 1}>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h6">
                {row?.orgId?.name}
              </Text>
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Domain :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.orgId?.domain}
                </Text>
              </InlineStack>
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Country :
                </Text>
                <Text as="p" fontWeight="medium">
                  {getItemNameByCountryCode(row?.orgId?.country)}
                </Text>
              </InlineStack>
              {row?.orgId?.companySize && (
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Company Size :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row?.orgId?.companySize}
                  </Text>
                </InlineStack>
              )}
              {row?.orgId?.website && (
                <div style={{ display: "flex" }}>
                  <Text as="p" tone="subdued">
                    Website :
                  </Text>
                  <TruncatedText text={row?.orgId?.website} maxLength={25} />
                  <Button onClick={() => handleRedirect(row?.orgId?.website)} variant="plain">
                    <Icon source={ExternalSmallMinor}></Icon>
                  </Button>
                </div>
              )}
              {row?.orgId?.linkedInProfile && (
                <div style={{ display: "flex" }}>
                  <Text as="p" tone="subdued">
                    LinkedIn Profile :
                  </Text>
                  <TruncatedText text={row?.orgId?.linkedInProfile} maxLength={25} />
                  <Button onClick={() => handleRedirect(row?.orgId?.linkedInProfile)} variant="plain">
                    <Icon source={ExternalSmallMinor}></Icon>
                  </Button>
                </div>
              )}
              {row?.orgId?.userFoundUs && (
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Found Us :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row?.orgId?.userFoundUs}
                  </Text>
                </InlineStack>
              )}
            </BlockStack>
          </IndexTable.Cell>

          <IndexTable.Cell>
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Plan Name :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.planType ? row?.planType : "Free"}
                </Text>
              </InlineStack>
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Plan Price :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.originalPrice}
                </Text>
              </InlineStack>
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Trial Days :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.isTrial == true ? "Yes" : "No"}
                </Text>
              </InlineStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Job Credit :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.featureLimitation?.jobCredit ? row?.featureLimitation?.jobCredit : "Unlimited"}
                </Text>
              </InlineStack>
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  User Credit :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.featureLimitation?.userCredit ? row?.featureLimitation?.userCredit : "Unlimited"}
                </Text>
              </InlineStack>
              {row?.featureLimitation?.jobExpired && (
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Job Expired :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row?.featureLimitation?.jobExpired}
                  </Text>
                </InlineStack>
              )}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text> {formatTimeAgo(row?.orgId?.created)}</Text>
              </InlineStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              {row?.planId == "Free" ? (
                <Button onClick={() => handleActivePlan(row)}>Active Plan</Button>
              ) : (
                <Button onClick={() => handleCancelPlan(row)}>Cancel Plan</Button>
              )}
              {/* <Button onClick={() => handleMoveJob(row)}>Move Job</Button> */}
            </BlockStack>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  }, []);

  return (
    <Page fullWidth>
      <BlockStack gap="500">
        <InlineStack align="end" gap="200">
          <Button onClick={() => handleMoveJob()}>Move Job</Button>
        </InlineStack>
        <CommonTable
          resourceName={{
            singular: "Organization",
            plural: "Organizations",
          }}
          title="Organizations"
          queryPlaceholder="Search User by (Name)"
          url={`admin/organization`}
          selectable={false}
          rowsData={ArchivedData}
          isFilterVisible
          headings={[
            { title: "No" },
            { title: "Name" },
            { title: "Plan" },
            { title: "Fetures" },
            { title: "Created" },
            { title: "Action" },
          ]}
          searchKey={["name"]}
          ref={childRef}
          isAdd={false}
          verticalAlign="center"
        />
      </BlockStack>
      <BannerModal
        open={isActivePlanOpen}
        onClose={handleModalClose}
        title="Add New Job"
        primaryAction={{
          content: "Submit",
          onAction: addPlan,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalClose,
          },
        ]}
        children={
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={planFormValues}
            formFields={activeOrganizationPlanformFields}
            isSave={false}
          />
        }
        size={"large"}
      />

      <Modal
        open={isCancelPlanModalOpen}
        onClose={handleCancelPlanCanceled}
        title="Confirm Cancel Plan"
        primaryAction={{
          content: "Cancel Plan",
          onAction: handleCanceledPlan,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCancelPlanCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to Cancel this Plan?</Text>
        </Modal.Section>
      </Modal>

      <BannerModal
        open={isMoveJobOpen}
        onClose={handleMoveJobModalClose}
        title="Move Job"
        primaryAction={{
          content: "Submit",
          onAction: moveJob,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleMoveJobModalClose,
          },
        ]}
        children={
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={moveJobinitialValues}
            formFields={moveJobFields}
            isSave={false}
          />
        }
      />
    </Page>
  );
}

export default Organization;
