import React, { createContext, useContext, useState } from "react";

// Create Context
const StopRecordingContext = createContext(false);

// Context Provider
export const StopRecordingProvider = ({ children }) => {
  const [stopRecordingContext, setStopRecordingContext] = useState(false);

  const handleStopRecording = (value) => {
    setStopRecordingContext(value);
  };

  return (
    <StopRecordingContext.Provider value={{ handleStopRecording, stopRecordingContext }}>
      {children}
    </StopRecordingContext.Provider>
  );
};

// Custom hook for using context
export const useStopRecording = () => useContext(StopRecordingContext);
