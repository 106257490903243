export const accessCondition = [
  {
    url: "/jobs",
    requiredRoles: ["manageJobs", "viewJobs"],
  },
  {
    url: "/jobs/id",
    requiredRoles: ["manageCandidate", "viewCandidate"],
  },
  {
    url: "/pricing",
    requiredRoles: ["planAccess"],
  },
  {
    url: "/assessment",
    requiredRoles: ["brandingAccess"],
  },
  {
    url: "/settings",
    requiredRoles: ["settingAccess"],
  },
];
