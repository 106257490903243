import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLocalStorageItem } from "@/Utils/Index";

export const Access = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("token")) {
      setLocalStorageItem("authorization", searchParams.get("token"));
      navigate(`/jobs`);
    }
  }, [searchParams]);

  return <></>;
};
