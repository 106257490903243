import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BlockStack, Image, InlineStack, Page, Text } from "@shopify/polaris";
import { PageLockLogo } from "../../Assets/Index";

export function CandidateAssess({ children, candidateData }) {
  const [isPermission, setIsPermission] = useState(false);
  const [isCandidateData, setCandidateData] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shareParam = urlParams.get("share");
    if (shareParam) {
      shareParam === candidateData?.code ? setIsPermission(true) : setCandidateData(true);
    } else {
      setIsPermission(true);
    }
  }, [candidateData]);
  if (isPermission) {
    return <>{children}</>;
  }
  if (isCandidateData) {
    return (
      <Page fullWidth={true}>
        <div className="lock-logo">
          <InlineStack align="space-between">
            <Image width={350} height={350} source={PageLockLogo}></Image>
          </InlineStack>
          <Text variant="heading2xl" as="h3" alignment="center" fontWeight="regular">
            You don’t have access
          </Text>
          <br />
          <Text variant="headingMd" as="p" alignment="center" tone="subdued" fontWeight="regular">
            Access to this page is restricted, and you do not have the required permissions to view its content.
          </Text>
        </div>
      </Page>
    );
  }
}
