import React, { useCallback, useEffect, useRef, useState } from "react";
import { ActionList, Button, Popover } from "@shopify/polaris";
import RejectCandidate from "../Jobs/RejectCandidate";
import ShortListCndidate from "../Jobs/ShortListCndidate";

export default function CandidateStatus({ candidate, jobid, parentSelectedTab, childRef, filter, isStatus }) {
  const [active, setActive] = useState(false);
  const [showRejectCandidate, setShowRejectCandidate] = useState(false);
  const [showShortListCandidate, setShowShortListCandidate] = useState(false);
  const [options, setOptions] = useState();

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleRejectAction = () => {
    setShowRejectCandidate(true);
  };

  const handleShortlistAction = () => {
    setShowShortListCandidate(true);
  };
  const option = [
    {
      content: "Reject",
      onAction: handleRejectAction,
    },
    {
      content: "Shortlist",
      onAction: handleShortlistAction,
    },
  ];

  useEffect(() => {
    if (candidate?.isShortList) {
      const modifiedOptions = option.filter((opt) => opt.content !== "Shortlist");
      setOptions(modifiedOptions);
    } else if (candidate?.reject) {
      const modifiedOptions = option.filter((opt) => opt.content !== "Reject");
      setOptions(modifiedOptions);
    } else {
      setOptions(option);
    }
  }, [candidate]);

  const activator = (
    <Button onClick={toggleActive} disclosure>
      {candidate?.isShortList ? "Shortlist" : candidate?.reject ? "Reject" : "Status"}
    </Button>
  );

  return (
    <div className="candidate-select" style={{ maxWidth: "170px" }}>
      <Popover active={active} activator={activator} autofocusTarget="first-node" onClose={toggleActive}>
        <ActionList actionRole="menuitem" items={options} />
      </Popover>
      {showRejectCandidate && (
        <RejectCandidate
          candidate={candidate}
          jobid={jobid}
          parentSelectedTab={parentSelectedTab}
          childRef={childRef}
          filter={filter}
          isStatus={isStatus}
          setShowRejectCandidate={setShowRejectCandidate}
        ></RejectCandidate>
      )}
      {showShortListCandidate && (
        <ShortListCndidate
          candidate={candidate}
          jobid={jobid}
          parentSelectedTab={parentSelectedTab}
          childRef={childRef}
          filter={filter}
          isStatus={isStatus}
          setShowShortListCandidate={setShowShortListCandidate}
        ></ShortListCndidate>
      )}
    </div>
  );
}
