import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BlockStack, Card, InlineStack, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { CommonHeader } from "../Common/CommonHeader";
import { DeactivePage } from "../Common/Steps/DeactivePage";
import { AssessmentTest } from "../InviteAssessment/AssessmentTest";
import { InitialInstruction } from "../InviteAssessment/InitialInstruction";

export const StageAssessment = () => {
  const fetch = useAuthenticatedFetch();
  const [jobData, setJobData] = useState();
  const [stage, setStage] = useState();
  const [isStarted, setIsStarted] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [assessmentId, setAssessmentId] = useState();
  const [candidateData, setCandidateData] = useState();
  const [isAssessment, setisAssessment] = useState(false);
  const [fullPage, setIsFullPage] = useState(false);
  const [organizationData, setOrganizationData] = useState();
  const [publicAssessmentData, setAssessmentData] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    const assessmentId = localStorage.getItem("assessmentId");

    const shareParam = urlParams.get("assessmentId");
    if (shareParam) {
      await setAssessmentId(shareParam);
      const res = await fetch.get(`/getStage/${id}?isPublic=true`);
      setStage(res?.data);
      if (res && res?.data?.jobId) {
        const job = await fetch.get(`/getJob/${res.data.jobId}`);
        setJobData(job?.data);
        const organization = await fetch.get(`/organization/${job?.data?.orgId}`);
        setOrganizationData(organization?.data);
      }
      const candiateParam = urlParams.get("candidateId");

      const candidate = await fetch.get(`/candidate/${candiateParam}`);
      setCandidateData(candidate?.data);

      const assessment = await fetch.get(`/getAssessment/${shareParam}`);
      setAssessmentData(assessment?.data);
      if (candidate?.data?.assessmentConformation && assessment?.data?.answers?.length > 0) {
        setIsStarted(true);
      }
    } else {
      let referrel = "default";
      const referrer = document.referrer;
      if (referrer) {
        const url = new URL(referrer);
        referrel = url.hostname.split(".")[1];
      }
      urlParams.set("referrer", referrel);
      navigate(
        {
          pathname: location.pathname,
          search: urlParams.toString(),
        },
        { replace: true }
      );
      const res = await fetch.get(`/getStage/${id}?isPublic=true`);
      setStage(res?.data);
      if (res && res?.data?.jobId) {
        const job = await fetch.get(`/getJob/${res.data.jobId}`);
        setJobData(job?.data);
        const organization = await fetch.get(`/organization/${job?.data?.orgId}`);
        setOrganizationData(organization?.data);
      }
    }
  }, [jobData, isStarted, candidateData, isAssessment]);

  useEffect(() => {
    const candiateParam = urlParams.get("candidateId");
    if (candiateParam) {
      setIsFullPage(false);
    } else if (stage?.publicUrl) {
      setIsFullPage(true);
    }
  }, [urlParams]);

  if (stage == false) {
    return (
      <Page>
        <Card>
          <InlineStack align="center">
            <Text variant="headingMd">The job description could not be found. </Text>
          </InlineStack>
        </Card>
      </Page>
    );
  }

  if (jobData && stage && isStarted) {
    return (
      <div style={{ minHeight: "520px" }}>
        <CommonHeader jobData={jobData} stage={stage} organizationData={organizationData}></CommonHeader>
        <AssessmentTest
          isPublic={true}
          assessmentId={assessmentId}
          publicAssessmentData={publicAssessmentData}
        ></AssessmentTest>
      </div>
    );
  }

  if (jobData && stage && jobData?.isActive === false) {
    return (
      <div className="assessmnet-Page" style={{ minHeight: "520px" }}>
        <InlineStack align="center">
          <Page>
            <Card>
              <BlockStack gap="400">
                <DeactivePage></DeactivePage>
              </BlockStack>
            </Card>
          </Page>
        </InlineStack>
      </div>
    );
  }

  return (
    <>
      {jobData && stage && (
        <div style={{ minHeight: "520px" }}>
          <CommonHeader
            jobData={jobData}
            stage={stage}
            setIsStarted={setIsStarted}
            isStarted={isStarted}
            organizationData={organizationData}
          ></CommonHeader>
          <Page fullWidth={fullPage}>
            <InitialInstruction
              jobData={jobData}
              stage={stage}
              setIsStarted={setIsStarted}
              candidateData={candidateData}
              setisAssessment={setisAssessment}
              organizationData={organizationData}
              assessmentData={publicAssessmentData}
              setAssessmentData={setAssessmentData}
            />
          </Page>
        </div>
      )}
    </>
  );
};
