import React, { useCallback, useRef, useState } from "react";
import { Modal } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { setLocalStorageFilter } from "@/Utils/AppUtils";
import { candidateRateFormFields, candidateRateFormitialValues } from "../../../Assets/Mocks/Candidate.mock";

export default function RateCandidate({
  candidate,
  jobid,
  parentSelectedTab,
  childRef,
  filter,
  isStatus,
  setShowRateCandidate,
}) {
  const [isRateModalOpen, setIsRateModalOpen] = useState(true);
  const fetch = useAuthenticatedFetch();
  const formRef = useRef();
  const [rateFormValues, setRateFormValues] = useState(
    candidate?.candidateRate ? { performance: candidate?.candidateRate } : candidateRateFormitialValues
  );

  const handleSubmit = useCallback(
    async (e) => {
      await fetch.post("/candidate", { candidateRate: e.performance, _id: candidate._id });
      filter ? setLocalStorageFilter(true, parentSelectedTab, childRef, "candidateList") : isStatus(true);
      setShowRateCandidate(false);
      setIsRateModalOpen(false);
    },
    [parentSelectedTab, childRef]
  );

  const handleRateCanceled = useCallback(() => {
    setIsRateModalOpen(false);
  }, []);

  const handleRateConfirmed = useCallback(async () => {
    formRef.current.handleSubmit();
  }, []);

  return (
    <>
      <Modal
        open={isRateModalOpen}
        onClose={handleRateCanceled}
        title="Rate Candidate"
        primaryAction={{
          content: "Save",
          onAction: handleRateConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleRateCanceled,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={rateFormValues}
            formFields={candidateRateFormFields}
            isSave={false}
            noValueChanged={false}
          />
        </Modal.Section>
      </Modal>
    </>
  );
}
