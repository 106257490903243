export const forgotPasswordFormFields = [
  {
    id: "email",
    name: "email",
    label: "Email",
    validated: true,
    type: "email",
    placeholder: "Enter your email to reset your password",
    errormsg: "Email is required",
  },
];

export const forgotPasswordInitialValues = {
  email: "",
};
