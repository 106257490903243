import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BlockStack, Button, Card, InlineStack, Page } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { Instruction } from "../Common/Steps/Instruction";
import { JobPreview } from "../Common/Steps/JobPreview";
import { PublicLinkJobPreview } from "../Common/Steps/PublicLinkJobPreview";
import { TestQuestion } from "../Common/Steps/TestQuestion";
import { CandidateForm } from "../StageAssessment/CandidateForm";

export const InitialInstruction = (props) => {
  const {
    jobData,
    questionsList,
    setIsStarted,
    stage,
    assessmentData,
    candidateData,
    setisAssessment,
    organizationData,
    setAssessmentData,
    stageData,
  } = props;
  const [assessmentPreview, setAssessmentPreview] = useState();
  const [step, setStep] = useState(1);
  const fetch = useAuthenticatedFetch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [image, setImage] = useState();
  const [buttonHide, setButtonHide] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [isStart, setIsStart] = useState(false);
  const [iscomplated, setiscomplated] = useState(false);

  const handleNextStep = () => {
    let videoType;
    if (stageData) {
      videoType =
        stageData?.stageDetails?.questionList && stageData?.stageDetails?.totalQuestions
          ? stageData?.stageDetails?.questionList.filter((question) => question.answerType === "video").length +
            stageData?.stageDetails?.totalQuestions
          : stageData?.stageDetails?.questionList
          ? stageData?.stageDetails?.questionList.filter((question) => question.answerType === "video").length
          : stageData?.stageDetails?.totalQuestions
          ? stageData?.stageDetails?.totalQuestions
          : 0;
    }
    if (videoType == 0) {
      setStep(4);
      setIsStarted(true);
    } else {
      setStep(step + (stage?.stageDetails?.stagePreset === "noQuestion" && step === 1 ? 3 : 1));
    }
  };
  useEffect(() => {
    if (step === 1) {
      fetchData();
      if (stage?.publicUrl) {
        setButtonHide(true);
      }
    } else if (step === 2) {
      if (stage?.publicUrl) {
        getassessmentData();
      }
    } else if (step === 3) {
      if (!stage?.publicUrl && !isStart) {
        assessmentQestion();
      }
    } else if (step > 3) {
      if (iscomplated && !stage?.publicUrl) {
        setIsStarted(true);
      } else if (stage?.publicUrl) {
        setIsStarted(true);
      }
    } else {
      if (stage?.publicUrl) {
        setisAssessment(true);
      }
      setButtonHide(false);
    }
  }, [jobData, step, image, organizationData, iscomplated, isStart]);

  const assessmentQestion = useCallback(async () => {
    setIsStart(true);
    let res = await fetch.post("/generateAssessmentQuestions", { assessmentData: assessmentData }, false);
    setAssessmentData(res?.data);
    setiscomplated(true);
  }, [assessmentPreview, jobData, organizationData]);

  const fetchData = useCallback(async () => {
    if (jobData) {
      setAssessmentPreview(organizationData);
    }
  }, [assessmentPreview, jobData, organizationData]);

  const getassessmentData = useCallback(async () => {
    let videoType =
      stage?.stageDetails?.questionList && stage?.stageDetails?.totalQuestions
        ? stage?.stageDetails?.questionList.filter((question) => question.answerType === "video").length +
          stage?.stageDetails?.totalQuestions
        : stage?.stageDetails?.questionList
        ? stage?.stageDetails?.questionList.filter((question) => question.answerType === "video").length
        : stage?.stageDetails?.totalQuestions
        ? stage?.stageDetails?.totalQuestions
        : 0;

    if (!assessmentData) {
      const assessmentId = localStorage.getItem("assessmentId");
      const assessment = await fetch.get(`/getAssessment/${assessmentId}`);
      setAssessmentData(assessment?.data);
    }
    setButtonHide(false);
    if (videoType == 0) {
      setStep(4);
    }
  }, [urlParams, assessmentData]);

  const content = (
    <>
      {step === 1 && stage?.publicUrl ? (
        <PublicLinkJobPreview
          jobData={jobData}
          organization={assessmentPreview}
          step={step}
          setStep={setStep}
          stage={stage}
          candidateData={candidateData}
          setAssessmentData={setAssessmentData}
        />
      ) : (
        step === 1 && (
          <JobPreview
            jobData={jobData}
            organization={assessmentPreview}
            step={step}
            setStep={setStep}
            stage={stage}
          />
        )
      )}

      {step === 2 &&
        (stage?.stageDetails ? (
          <Instruction stage={stage.stageDetails} setStep={setStep} step={step} />
        ) : (
          <Instruction stageData={stageData.stageDetails} setStep={setStep} step={step} />
        ))}

      {step === 3 && (
        <TestQuestion setStep={setStep} step={step} assessmentData={assessmentData} setImage={setImage} />
      )}

      {step < 4 && jobData?.isActive && step !== 3 && !buttonHide && (
        <InlineStack align="end">
          <Button variant="primary" size="large" disabled={isButtonDisabled} onClick={handleNextStep}>
            {step === 3 ? "Let's Get Started" : "Next"}
          </Button>
        </InlineStack>
      )}
    </>
  );

  return (
    <div className="assessmentTest">
      {step === 1 && stage?.publicUrl ? (
        <BlockStack gap="400">{content}</BlockStack>
      ) : (
        <Card>
          <BlockStack gap="400">{content}</BlockStack>
        </Card>
      )}
    </div>
  );
};
