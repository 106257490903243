export const sumoAppCodeField = [
  {
    id: "sumoCode",
    name: "sumoCode",
    label: "SumoApp Code",
    validated: true,
    type: "text",
    placeholder: "Please enter SumoCode",
    errormsg: "SumoCode is required",
  },
];

export const invitesumoAppCodeValues = {
  sumoCode: "",
};
