import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlockStack, Button, Icon, IndexTable, InlineStack, Modal, Page, Text } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { activePlanformFields, activePlaninitialValues } from "@/Assets/Mocks/ActivePlan.mock";
import { getFilterField } from "@/Assets/Mocks/User.mock";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { getLocalStorageItem } from "@/Utils/Index";

function User() {
  const navigate = useNavigate();
  const childRef = useRef();
  const fetch = useAuthenticatedFetch();
  const activePlanFormRef = useRef();
  const [removePlan, setRemovePlan] = useState(false);
  const [activePlan, setActivePlan] = useState(false);
  const [data, setData] = useState();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
  };

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    const accessToken = getLocalStorageItem("authorizationAdminPanel");
    if (!accessToken) {
      navigate("/admin/login");
    }
  }, []);

  const ActivePlanhandleSubmit = useCallback(
    async (value) => {
      const response = await fetch.put(`admin/addPlan`, { ...value, shopUrl: data.shopUrl });
      setActivePlan(false);
      childRef.current.fetchData();
    },
    [data]
  );

  const handleRemovePlan = useCallback(async () => {
    setData(data);
    await fetch.put(`admin/removePlan`, { ...data });
    setRemovePlan(false);
    childRef.current.fetchData();
  });
  const activePlansubmitForm = useCallback(() => {
    if (activePlanFormRef.current) {
      activePlanFormRef.current.handleSubmit();
    }
  }, []);

  const handleAccess = useCallback(async (row) => {
    const apiResult = await fetch.get(`admin/access_token?id=${row._id}`);
    const url = `${process.env.APP_URL}/access/?token=${apiResult.data.token}`;
    window.open(url, "_blank");
  }, []);

  const handleClose = useCallback(() => setRemovePlan(!removePlan), [removePlan]);
  const handleCloseActivePlan = useCallback(() => setActivePlan(!activePlan), [activePlan]);

  const filterFormFields = useMemo(() => getFilterField(), []);

  const ArchivedData = useCallback((rows) => {
    if (rows?.length <= 0) return [];
    return rows?.map((row, index) => {
      return (
        <IndexTable.Row id={row._id} key={row._id} position={index}>
          <IndexTable.Cell>{index + 1}</IndexTable.Cell>
          <IndexTable.Cell key={row._id + 1}>
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  First Name :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.firstName}
                </Text>
              </InlineStack>
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Last Name :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.lastName}
                </Text>
              </InlineStack>
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Mobile :
                </Text>
                <Text as="p" fontWeight="medium">
                  {row?.mobile}
                </Text>
              </InlineStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text>{row?.email}</Text>
                <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(row.email)}>
                  <Icon source={ClipboardMinor} />
                </a>
              </InlineStack>
              {row.phone && (
                <InlineStack gap="200">
                  <Text>{row.phone}</Text>
                </InlineStack>
              )}
              <InlineStack gap="200">
                <Text as="p" tone="subdued">
                  Created :
                </Text>
                <Text as="p" fontWeight="medium">
                  {formatTimeAgo(row?.createdAt ? row?.createdAt : row?.created)}
                </Text>
              </InlineStack>
              {row?.organizations.some((org) => org?.name) && (
                <InlineStack gap="200">
                  <div style={{ maxWidth: "200px", whiteSpace: "normal", overflowWrap: "break-word" }}>
                    <Text as="p" tone="subdued">
                      Organization Name :
                    </Text>
                    <Text as="p" fontWeight="medium">
                      {row?.organizations
                        .filter((org) => org?.name)
                        .map((org) => org?.name)
                        .join(", ")}
                    </Text>
                  </div>
                </InlineStack>
              )}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text>{row?.organizations?.length}</Text>
              </InlineStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text>{row?.status}</Text>
              </InlineStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <Button onClick={() => handleAccess(row)}>Access</Button>
            </BlockStack>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  }, []);

  return (
    <Page fullWidth>
      <BlockStack gap="500">
        <CommonTable
          resourceName={{
            singular: "User",
            plural: "Users",
          }}
          title="User"
          queryPlaceholder="Search User by (email)"
          url={`admin/user`}
          selectable={false}
          rowsData={ArchivedData}
          isFilterVisible
          headings={[
            { title: "No" },
            { title: "Name" },
            { title: "Email" },
            { title: "Organizations" },
            { title: "status" },
            { title: "Access" },
          ]}
          searchKey={["email"]}
          ref={childRef}
          isAdd={false}
          verticalAlign="center"
          columnContentTypes={["text", "text", "numeric", "numeric", "numeric", "text", "text"]}
          filterFormFields={filterFormFields}
        />
        <Modal
          open={removePlan}
          title="Are you sure you want to remove plan for this user?"
          onClose={handleClose}
          primaryAction={{
            content: "Yes",
            onAction: handleRemovePlan,
          }}
          secondaryActions={[
            {
              content: "No",
              onAction: handleClose,
            },
          ]}
          small
        ></Modal>
        <Modal
          open={activePlan}
          title="Are you sure you want to Active plan for this user?"
          onClose={handleCloseActivePlan}
          primaryAction={{
            content: "Active Plan",
            onAction: activePlansubmitForm,
          }}
          small
        >
          <Modal.Section>
            <CommonForm
              onSubmit={ActivePlanhandleSubmit}
              formRef={activePlanFormRef}
              initialValues={activePlaninitialValues}
              formFields={activePlanformFields}
              title="Active Plan"
              isSave={false}
            />
          </Modal.Section>
        </Modal>
      </BlockStack>
    </Page>
  );
}

export default User;
