import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  DataTable,
  Icon,
  InlineGrid,
  InlineStack,
  LegacyCard,
  Link,
  Modal,
  Page,
  Text,
} from "@shopify/polaris";
import {
  BlogMajor,
  CashRupeeMajor,
  CircleAlertMajor,
  ClockMajor,
  CustomersMajor,
  DesktopMajor,
  EmailMajor,
  FileMinor,
  FulfillmentFulfilledMajor,
  JobsMajor,
  LocationMajor,
  MobileMajor,
  OrganizationMajor,
} from "@shopify/polaris-icons";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BannerModal } from "@/Components/Common/BannerModal";
import ProgressCircle from "@/Components/Common/ProgressCircle";
import StarCandidate from "@/Components/Common/StarCandidate";
import { ToastContext } from "@/Context/ToastContext";
import { AccessPermission } from "../../../Components/Common/AccessPermission";
import { CandidateAssess } from "../../../Components/Common/CandidateAccess";
import { UserContext } from "../../../Context/UserContext";
import { AssessmentResult } from "./AssessmentResult";
import CandidateAction from "./CandidateAction";
import CandidateStatus from "./CandidateStatus";

export const CandidateDetail = ({ isPrivate, isPublic }) => {
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const [candidateData, setCandidateData] = useState();
  const fetch = useAuthenticatedFetch();
  const { id } = useParams();
  const [formModalStatus, setformModalStatus] = useState(false);
  const [sharedUrl, setSharedUrl] = useState("");
  const { showToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [Status, isStatus] = useState(false);
  const [rows, setRows] = useState();

  const handleCloseModel = useCallback(() => setformModalStatus(!formModalStatus), [formModalStatus]);

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    showToast("Link copied to clipboard.");
    setformModalStatus(false);
  };

  const makeLinkPrivate = async () => {
    await fetch.post("/candidate", { isPublic: false, _id: id });
    setSharedUrl("");
  };

  const handleButtonClick = useCallback(async () => {
    let res = await fetch.post("/candidate", { isPublic: true, _id: id });
    const Url = `${process.env.APP_URL + location.pathname.replace("/candidate/", "/candidate/share/")}?share=${
      res.data.code
    }`;
    setSharedUrl(Url);
    setformModalStatus(true);
  }, [formModalStatus, candidateData]);

  const getCandidateData = async () => {
    localStorage.setItem("playbackSpeed", 1);
    const shareParam = urlParams.get("share");
    const res = shareParam
      ? await fetch.get(`/candidate/${id}?share=${shareParam}`)
      : await fetch.get(`/candidate/${id}`);
    return res.data;
  };

  const handleBackClick = useCallback(() => {
    const getUrlParam = urlParams.get("tabindex");
    navigate(`/jobs/${jobId}?tabindex=${getUrlParam}`);
  }, [candidateData]);

  const handleDownloadFile = (url) => {
    const link = document.createElement("a");
    link.href = `${process.env.APP_URL}/uploads/${url}`;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatIndianNumber = (number) => {
    const numberString = number.toString();
    const lastTwo = numberString.slice(-3);
    const otherNumbers = numberString.slice(0, -3);

    let formattedNumber;

    if (otherNumbers !== "") {
      const reversedOtherNumbers = otherNumbers.split("").reverse().join("");
      const formattedReversedOtherNumbers = reversedOtherNumbers
        .replace(/(\d{2})(?=\d)/g, "$1,")
        .split("")
        .reverse()
        .join("");
      formattedNumber = formattedReversedOtherNumbers + "," + lastTwo;
    } else {
      formattedNumber = lastTwo;
    }

    return formattedNumber;
  };

  useEffect(() => {
    getCandidateData()
      .then((data) => {
        if (data?.code) {
          const sharedUrl = `${
            process.env.APP_URL + location.pathname.replace("/candidate/", "/candidate/share/")
          }?share=${data?.code}`;
          setSharedUrl(sharedUrl);
        }
        if (data == null) {
          showToast("you have not access this candidate", true);
          navigate(`/jobs`);
        }
        setCandidateData(data);
        const rowsData = data?.candidateFeedback?.map((item) => [
          item.feedback,
          item.userName,
          formatTimeAgo(item?.FeedbackDate),
        ]);
        setRows(rowsData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id, formModalStatus, sharedUrl, Status]);

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  if (loading) {
    return <></>;
  }

  const { _id, jobId, __v, updated, created, assessmentStatus, ...candidatePersonalDetails } = candidateData || {};

  return (
    <CandidateAssess candidateData={candidateData}>
      <div style={{ minHeight: "520px", width: "100%" }}>
        <Page
          fullWidth={true}
          title={isPrivate ? candidateData?.fullName : ""}
          titleMetadata={
            <InlineStack gap={200} blockAlign="center">
              <StarCandidate candidate={candidateData}></StarCandidate>
              {candidateData?.candidateRate && (
                <ProgressCircle
                  score={candidateData?.candidateRate}
                  fontSize="8px"
                  width="25px"
                  border="2px"
                  progress={true}
                />
              )}
              {candidateData?.reject && <Badge tone="critical">Rejected</Badge>}
              {candidateData?.isShortList && <Badge tone="success">Shortlisted</Badge>}
            </InlineStack>
          }
          secondaryActions={
            isPrivate ? (
              <div>
                <InlineStack align="end" gap="200">
                  {candidateData?.isPublic ? (
                    <InlineStack align="end" gap="200">
                      <AccessPermission access={["manageJobs", "manageCandidate"]}>
                        <Button size="large" onClick={() => copyToClipboard(sharedUrl)}>
                          Copy Link
                        </Button>
                        <Button size="large" onClick={makeLinkPrivate}>
                          Make Link private
                        </Button>
                      </AccessPermission>
                    </InlineStack>
                  ) : (
                    <Button size="large" onClick={handleButtonClick}>
                      Make Link public
                    </Button>
                  )}
                  <CandidateStatus candidate={candidateData} jobid={jobId} isStatus={isStatus} />
                  <CandidateAction candidate={candidateData} jobid={jobId} isStatus={isStatus} />
                </InlineStack>
              </div>
            ) : (
              ""
            )
          }
          backAction={isPrivate ? { content: "Back", onAction: handleBackClick } : ""}
        >
          <BlockStack gap="200">
            <Card padding="600">
              <BlockStack gap="300">
                {candidateData?.candidateFeedback && rows?.length > 0 && (
                  <LegacyCard>
                    <DataTable
                      columnContentTypes={["text", "text", "text"]}
                      headings={[
                        <Text variant="headingSm" as="h6">
                          Feedback
                        </Text>,
                        <Text variant="headingSm" as="h6">
                          Created By
                        </Text>,
                        <Text variant="headingSm" as="h6">
                          Created At
                        </Text>,
                      ]}
                      rows={rows?.map((row) => [
                        <div className="feedback-column">{row[0]}</div>,
                        <div className="username-column">{row[1]}</div>,
                        <div className="feedback-date-column">{row[2]}</div>,
                      ])}
                    />
                  </LegacyCard>
                )}
                <BlockStack gap="300">
                  <InlineGrid gap="200" columns="2">
                    <Box align="start">
                      <Text variant="headingXl">Profile Info</Text>
                    </Box>
                  </InlineGrid>
                  <Card>
                    <InlineGrid columns="4" gap={400}>
                      <BlockStack gap="200" inlineAlign="start">
                        <InlineStack>
                          <Icon color="subdued" source={CustomersMajor} tone="base" />
                          <Text color="subdued">Full Name </Text>
                        </InlineStack>
                        <Text>{candidatePersonalDetails?.fullName}</Text>
                      </BlockStack>
                      {isPrivate && candidatePersonalDetails?.mobile && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={MobileMajor} tone="base" />
                            <Text color="subdued">Mobile </Text>
                          </InlineStack>
                          <Text>{candidatePersonalDetails?.mobile ? candidatePersonalDetails?.mobile : "-"}</Text>
                        </BlockStack>
                      )}
                      {isPrivate && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={EmailMajor} tone="base" />
                            <Text color="subdued">Email </Text>
                          </InlineStack>
                          <Text>{candidatePersonalDetails?.email}</Text>
                        </BlockStack>
                      )}
                      {isPrivate && candidatePersonalDetails?.currentCTC && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={CashRupeeMajor} tone="base" />
                            <Text color="subdued">Current CTC</Text>
                          </InlineStack>
                          <Text>
                            {candidatePersonalDetails?.currentCTC
                              ? formatIndianNumber(candidatePersonalDetails?.currentCTC)
                              : "-"}
                          </Text>
                        </BlockStack>
                      )}
                      {isPrivate && candidatePersonalDetails?.expectedCTC && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={CashRupeeMajor} tone="base" />
                            <Text color="subdued">Expected CTC</Text>
                          </InlineStack>
                          <Text>
                            {candidatePersonalDetails?.expectedCTC
                              ? formatIndianNumber(candidatePersonalDetails?.expectedCTC)
                              : "-"}
                          </Text>
                        </BlockStack>
                      )}
                      <BlockStack gap="200" inlineAlign="start">
                        <InlineStack>
                          <Icon color="subdued" source={JobsMajor} tone="base" />
                          <Text color="subdued">Applying As</Text>
                        </InlineStack>
                        <Text>
                          {candidatePersonalDetails?.applyingAs ? candidatePersonalDetails?.applyingAs : "-"}
                        </Text>
                      </BlockStack>
                      {isPrivate && candidatePersonalDetails?.preferWorkMode && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={DesktopMajor} tone="base" />
                            <Text color="subdued">Prefer Work Mode</Text>
                          </InlineStack>
                          <Text>
                            {candidatePersonalDetails?.preferWorkMode
                              ? candidatePersonalDetails?.preferWorkMode
                              : "-"}
                          </Text>
                        </BlockStack>
                      )}
                      <BlockStack gap="200" inlineAlign="start">
                        <InlineStack>
                          <Icon color="subdued" source={FulfillmentFulfilledMajor} tone="base" />
                          <Text color="subdued">Total Experience (In Years)</Text>
                        </InlineStack>
                        <Text>
                          {candidatePersonalDetails?.totalExperience
                            ? candidatePersonalDetails?.totalExperience
                            : "-"}
                        </Text>
                      </BlockStack>
                      {isPrivate && candidatePersonalDetails?.noticePeriod && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={ClockMajor} tone="base" />
                            <Text color="subdued">Notice Period (In Days)</Text>
                          </InlineStack>
                          <Text>
                            {candidatePersonalDetails?.noticePeriod ? candidatePersonalDetails?.noticePeriod : "-"}{" "}
                          </Text>
                        </BlockStack>
                      )}
                      {isPrivate && candidatePersonalDetails?.currentLocation && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={LocationMajor} tone="base" />
                            <Text color="subdued">Current Location</Text>
                          </InlineStack>
                          <Text>
                            {candidatePersonalDetails?.currentLocation
                              ? candidatePersonalDetails?.currentLocation
                              : "-"}
                          </Text>
                        </BlockStack>
                      )}
                      {isPrivate && candidatePersonalDetails?.platform && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={OrganizationMajor} tone="base" />
                            <Text color="subdued">Platform</Text>
                          </InlineStack>
                          <Text>
                            {candidatePersonalDetails?.platform ? candidatePersonalDetails?.platform : "-"}
                          </Text>
                        </BlockStack>
                      )}
                      {isPrivate && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={OrganizationMajor} tone="base" />
                            <Text color="subdued">Created By</Text>
                          </InlineStack>
                          <Text>
                            {candidatePersonalDetails?.isOnline
                              ? "Public Link"
                              : candidatePersonalDetails?.userFirstName}
                          </Text>
                        </BlockStack>
                      )}

                      {isPrivate && candidatePersonalDetails?.resume?.assetUrl && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={FileMinor} tone="base" />
                            <Text color="subdued">Resume</Text>
                          </InlineStack>
                          <Link
                            removeUnderline
                            onClick={() => handleDownloadFile(candidatePersonalDetails?.resume?.assetUrl)}
                          >
                            <Text fontWeight="bold">Download</Text>
                          </Link>
                        </BlockStack>
                      )}
                      {isPrivate && candidatePersonalDetails?.resumeScore?.score && (
                        <BlockStack gap="200" inlineAlign="start">
                          <InlineStack>
                            <Icon color="subdued" source={BlogMajor} tone="base" />
                            <Text color="subdued">Resume</Text>
                          </InlineStack>

                          <ProgressCircle
                            score={candidatePersonalDetails?.resumeScore?.score}
                            fontSize="8px"
                            width="23px"
                            border="2px"
                          />
                        </BlockStack>
                      )}
                    </InlineGrid>
                  </Card>
                  {candidateData && (
                    <AssessmentResult candidate_id={_id} candidateData={candidateData} isPrivate={isPrivate} />
                  )}
                </BlockStack>
              </BlockStack>
            </Card>
          </BlockStack>
          <br />
        </Page>
      </div>
      <BannerModal
        open={formModalStatus}
        title={"Public Url"}
        onClose={handleCloseModel}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCloseModel,
          },
        ]}
        children={
          <BlockStack gap="200">
            <InlineStack gap="200">
              <Text>{sharedUrl}</Text>
              <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(sharedUrl)}>
                <Icon source={ClipboardMinor} />
              </a>
            </InlineStack>
          </BlockStack>
        }
      ></BannerModal>
    </CandidateAssess>
  );
};
