import React from "react";
import { Modal, Text } from "@shopify/polaris";

export function BannerModal(props) {
  return (
    <Modal {...props}>
      <Modal.Section>
        <Text as="p">{props.subtitle}</Text>
        {props.children}
      </Modal.Section>
    </Modal>
  );
}
