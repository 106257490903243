import CountryData from "./countries.json";

const countries = [
  ...Object.values(CountryData).map(({ countryCode, itemName }) => ({
    label: itemName,
    value: countryCode,
  })),
];
const domainList = [
  { label: "IT", value: "IT" },
  { label: "HealthCare", value: "HealthCare" },
  { label: "Insurance", value: "Insurance" },
  { label: "Banking", value: "Banking" },
  { label: "Finance", value: "Finance" },
  { label: "Education", value: "Education" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Retail", value: "Retail" },
  { label: "Other", value: "Other" },
];

export const organizationFields = [
  {
    id: "name",
    name: "name",
    label: "Organization Name",
    validated: true,
    type: "text",
    placeholder: "Add organization name",
    errormsg: "Name is required",
  },
  {
    id: "companySize",
    name: "companySize",
    label: "Company Size",
    validated: true,
    type: "text",
    errormsg: "Company Size is required",
  },
  {
    id: "domain",
    name: "domain",
    label: "Domain",
    type: "searchableSelect",
    validated: true,
    placeholder: "Select Domain",
    options: domainList,
    errormsg: "Domain is required",
  },
  {
    id: "website",
    name: "website",
    label: "Website",
    type: "text",
  },
  {
    id: "linkedInProfile",
    name: "linkedInProfile",
    label: "LinkedIn Profile",
    type: "text",
  },
  {
    id: "country",
    name: "country",
    label: "Country",
    type: "searchableSelect",
    validated: true,
    placeholder: "Select Country",
    options: countries,
    errormsg: "Country is required",
  },
  {
    id: "companyProfile",
    name: "companyProfile",
    label: "Company Bio",
    validated: false,
    type: "text",
    placeholder: "Provide a brief overview of the company",
    errormsg: "Company Bio is required",
    multiline: 2,
  },
  {
    id: "userFoundUs",
    name: "userFoundUs",
    label: "How did you find us?",
    validated: true,
    type: "select",
    errormsg: "this field is required",
    options: [
      { value: "", label: "" },
      { value: "Social media", label: "Social media" },
      { value: "Search", label: "Search" },
      { value: "Friends", label: "Friends" },
      { value: "Ads", label: "Ads" },
      { value: "Review Website", label: "Review Website" },
    ],
  },
];

export const organizationInitialValues = {
  name: "",
  companySize: "",
  domain: "",
  website: "",
  linkedInProfile: "",
  country: "",
  companyProfile: "",
};

export const moveJobFields = [
  {
    id: "orgId",
    name: "orgId",
    label: "Organization Id",
    type: "text",
    errormsg: "Organization Id is required",
    validated: true,
  },
  {
    id: "jobId",
    name: "jobId",
    label: "Job Id",
    type: "text",
    errormsg: "Job Id is required",
    validated: true,
  },
];
export const moveJobinitialValues = {
  orgId: "",
  jobId: "",
};
export const organizationValuesOption = [
  {
    type: "number key",
  },
  {
    type: "namekey",
  },
  {
    type: "locationkey",
  },
  {
    type: "pricekey",
  },
  {
    type: "feturekey",
  },

  {
    type: "createdkey",
  },
];
